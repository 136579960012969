import React, { useEffect } from "react";
import * as ImageManipulator from "expo-image-manipulator";

import { useAppDispatch } from "../store/hooks";
import { executeSealOcr } from "../store/inspectionSlice";
import Loading from "../components/Loading";
import { PHOTO_FORMATS, RootStackScreenProps } from "../types";
import Toast from "react-native-toast-message";
import i18nDictionary from "../i18n";

const LoadingSeal = ({
  route,
  navigation,
}: RootStackScreenProps<"LoadingSeal">) => {
  const { sealId, uri } = route.params;
  const dispatch = useAppDispatch();

  const processImageWithOcr = async (
    sealId: string,
    manipulatedImage: ManipulatedImage
  ): Promise<void> => {
    try {
      await dispatch(
        executeSealOcr({
          sealId,
          photoFormat: manipulatedImage.format,
          sealPhotoBlob: manipulatedImage.blob,
          photoHeight: manipulatedImage.height,
          photoWidth: manipulatedImage.width,
        })
      )
        .unwrap()
        .then((data: any) => {
          // TODO Typify the data received from the dispatch
          // navigation.pop(2);
          navigation.navigate("CurrentInspection", { type: "seal" });
          if (data?._tag !== "Right") {
            Toast.show({
              type: "error",
              text1: "Error",
              text2: i18nDictionary.screens.Inspections.noSealFoundMessage(),
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const sendDataToOcr = async () => {
      const manipulatedImage = await manipulateImage(uri);
      await processImageWithOcr(sealId, manipulatedImage);
    };

    sendDataToOcr();
  }, []);

  return (
    <>
      <Loading text={i18nDictionary.screens.Inspections.loadingSeal()} />
    </>
  );
};

type ManipulatedImage = {
  blob: Blob;
  format: "png" | "jpg" | "jpeg";
  height: number;
  width: number;
};

const manipulateImage = async (uri: string): Promise<ManipulatedImage> => {
  const manipResult = await ImageManipulator.manipulateAsync(uri, [
    { resize: { height: 1024 } },
  ]);
  const response = await fetch(manipResult.uri);
  const blob = await response.blob();
  return {
    blob,
    format:
      PHOTO_FORMATS.find(
        (format) => format === manipResult.uri.split("/")[1].split(";")[0]
      ) || PHOTO_FORMATS[0],
    height: manipResult.height,
    width: manipResult.width,
  };
};

export default LoadingSeal;
