import React, { useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Camera } from "expo-camera";
import { useSelector } from "react-redux";
import { useIsFocused } from "@react-navigation/native";

import i18nDictionary from "../i18n";
import { Theme } from "../styles";
import { RootStackScreenProps } from "../types";
import { selectCameraRatio } from "../store/cameraSlice";
import { Sizes } from "../../shared/constants/sizes";
import CameraUI from "../../shared/templates/CameraUi";

const CameraSeal = ({
  route,
  navigation,
}: RootStackScreenProps<"CameraSeal">) => {
  const { sealId } = route.params;

  const isFocused = useIsFocused();

  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [isPhotoTaken, setIsPhotoTaken] = useState(true);
  const cameraRatio = useSelector(selectCameraRatio());
  const camRef = useRef<Camera>();
  const [zoom, setZoom] = useState(0);

  const takePicture = async () => {
    if (camRef) {
      setIsPhotoTaken(false);
      try {
        const picture = await camRef.current?.takePictureAsync({
          exif: true,
          skipProcessing: true,
        });
        if (picture !== undefined) {
          navigation.push("LoadingSeal", { sealId, uri: picture.uri });
        }
        setIsPhotoTaken(true);
      } catch (error) {
        setIsPhotoTaken(true);
        console.log(error);
      }
    }
  };

  if (!permission) {
    // Camera permissions are still loading
    return <View />;
  }

  return (
    <>
      {/* We need to unmount the camera because upon leaving this screen. */}
      {isFocused && (
        <Camera
          // @ts-ignore
          ref={camRef}
          ratio={cameraRatio.ratio}
          poster="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBxISEhUSEhIVFRUXFRUVFxcVFRcVFRUVFRUWFxUXFRUYHSggGB0lHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDQ0NDw0NDysZFRkrNy0rKysrLSstNzc3Ny4rKy0tNy0rLTctLSsrKy0rKysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAIBABAQEAAQQCAwAAAAAAAAAAAAERAhIhQWEx8FGRof/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABsRAQACAgMAAAAAAAAAAAAAAAAREgFhAlGB/9oADAMBAAIRAxEAPwDiFMCAqBAFMSAtTCgLKgoJSFAKsqaQC0wp4BFACqlhQEq1JQVMACkgALEIAq6oIVKQAi1IBBb+UoFWwQFKhoGJFlIAFIBhAATVAKFMBKuBoERZSAgYugCUBc9gCnYioIqACNYlawGaBgLaJADFS0kAIVYAkXUAsEalBKIoBi1KBIaRAApoKkF0GusYBq2e2sSqkEVMVMEIsSKAggC1YUEWXEgBoUgFIEAIQiipCEQXTEoCBQAq6gKhaugg1tASriU0AXUBQxIBaVc7koJFqYWAqFICpotgBEpoLiCwGWsQAtSrQDUa9MgoQ8gaikgINb6/oBCphAXEKAUi4AayuFA0CgYEIA1GTALBbEAoJgNRmUoClTFwDUqpgKGIBougHUIAspCLgJYcVQFNSQ0AACReSUAAgAYQDuaACoAAmAoGggtARU1QKi0A0TsLGxTRYgIWALE9EWggICqkpfQFCmAqFAVFiQAtTADFKgLiVYUBAgLiLgCVYlXQNAEWoEFXEhpgLqFWglgUwBaVKAaJAUKTuBoYSgSd0UwDkJQFwhQApEBUUBBSTQhNG+hUlunJgoKwuJqpoKmrIgCLSAUhFoJSGFA1LFlIByDSAkXBMBbCUAA5AIVUBUNAXT4QBrqob91Rq22cWlTRlay1qYCxMWxkFq3ilKBC1QEAoEpoSAE1agIsFBlUgCwqQoKWJigmrTSAlFABBRVKckVNIupKIsqaigvKIGAAtoCaTsSAaQpACmrAL+UtCwEUhARS0oCKgKgtoFRSAg1sF9RDkaUUSKSgki0ggCLQChaAYqQDCFAKLiQA0QDVhSUCxFqAtQUC1FQAAF0ADAwA0gAaUi6DOroAL1MytAkMNAUTUgLYqEAIigmrCgItCAJq6WgUQA1SUwF0ZBZbjPgFF4oCIvBOPwAFKoCLVAY5AAX5XkoDK+AXILfv7BBmLVBcLySKKjCAg1fhIAFWADQAP//Z"
          zoom={zoom}
          style={[styles.camera, { marginVertical: cameraRatio.imagePadding }]}
          onMountError={(error) => {
            console.log(`Camera mount error: ${error}`);
            requestPermission();
            console.log(`permission = ${JSON.stringify(permission)}`);
          }}
        />
      )}
      <CameraUI
        onPress={takePicture}
        cancelPhoto={() => {
          navigation.pop();
        }}
        title={i18nDictionary.screens.Inspections.instructions()}
        titleProps={{
          style: {
            fontSize: Sizes.h3,
            fontWeight: "700",
            color: Theme.colors.title,
            marginBottom: Sizes.marginXS,
          },
        }}
        loading={!isPhotoTaken}
        instructionsText={i18nDictionary.screens.Seal.instructionSealPhoto()}
        instructionsTextProps={{
          style: {
            fontSize: Sizes.body3,
            fontWeight: "400",
            color: Theme.colors.title,
          },
        }}
        buttonProps={{
          style: {
            backgroundColor: Theme.colors.primary,
            width: "60%",
            opacity: 1,
            padding: Sizes.paddingM,
            borderRadius: Sizes.borderRadiusM,
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
          },
        }}
        onZoomChange={setZoom}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    margin: 20,
    width: "100%",
    backgroundColor: "black",
    opacity: 0.5,
  },
  button: {
    flex: 0.1,
    alignSelf: "flex-end",
    alignItems: "center",
  },
  text: {
    fontSize: 18,
    color: "white",
  },
  cameraIcon: {
    backgroundColor: Theme.colors.primary,
    width: "50%",
    opacity: 1,
    padding: Sizes.paddingS,
    borderRadius: Sizes.borderRadiusM,
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
  },
});

export default CameraSeal;
