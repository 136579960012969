import React from 'react'
import Svg, { Path } from 'react-native-svg';
const AngleDown = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 998 582" fill="none" >
      <Path d="M998 83C998 91.6667 994.667 99.3333 988 106L522 572C515.333 578.667 507.667 582 499 582C490.333 582 482.667 578.667 476 572L10 106C3.33333 99.3333 0 91.6667 0 83C0 74.3333 3.33333 66.6667 10 60L60 10C66.6667 3.33333 74.3333 0 83 0C91.6667 0 99.3333 3.33333 106 10L499 403L892 10C898.667 3.33333 906.333 0 915 0C923.667 0 931.333 3.33333 938 10L988 60C994.667 66.6667 998 74.3333 998 83Z" fill={props.color} />
    </Svg>
  )
}

export default AngleDown; 