import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { inspectionSlice } from "./inspectionSlice";
import { ownerCodeSlice } from "./ownerCodeSlice";
import thunk from "redux-thunk";
import { authSlice } from "./authSlice";
import { cameraSlice } from "./cameraSlice";

const rootReducer = combineReducers({
  inspections: inspectionSlice.reducer,
  camera: cameraSlice.reducer,
  ownerCodes: ownerCodeSlice.reducer,
  auth: authSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
