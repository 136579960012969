import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from ".";
//axios api

const initialState = {
  ownerCodesList: [],
  currentRequestId: "",
  loading: "fin",
  error: "",
};

export const fetchOwnerCodesList = createAsyncThunk(
  "ownerCodes/fetchList",
  async (_, { rejectWithValue }) => {
    try {
      const list = await axios.get('https://driver-app-storage-settingsdev-dev.s3.eu-west-1.amazonaws.com/codes.json');
      return list;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ownerCodeSlice = createSlice({
  name: "ownerCodes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOwnerCodesList.pending, (state) => {

    }),
      builder.addCase(fetchOwnerCodesList.fulfilled, (state, action) => {
        const { payload: { data } } = action
        state.ownerCodesList = data
      }),
      builder.addCase(fetchOwnerCodesList.rejected, (state, action) => {

      })
  },
});

export const selectOwnerCodes = (id?: string) => (state: RootState) => state.ownerCodes


// export reducer;