import React, { useState } from "react";
import { SafeAreaView } from "react-native";

// Librarys
import { useSelector } from "react-redux";

// Components
import { Theme } from "../../styles";

// Utils
import configAws from "../../aws-exports";

// Amplify
import {
  ContainerState,
  SEAL_LOCATIONS,
  SealCategory,
  SealState,
  ValidationState,
} from "../../types";
import Forms from "./components/Forms";

// Utils
import {
  selectContainer,
  selectSeals,
  updateContainer,
  updateInspectionThunk as syncCurrentInspection,
  updateSeals,
} from "../../store/inspectionSlice";
import { RootStackScreenProps } from "../../types";
import { Auth } from "aws-amplify";
import { Appbar, FAB, Portal } from "react-native-paper";
import i18nDictionary from "../../i18n";
import { useFocusEffect } from "@react-navigation/native";
import { SEAL_OWNERS } from "../../helpers/constantsValues";
import { useAppDispatch } from "../../store/hooks";

Auth.configure(configAws);

const CurrentInspection = ({
  navigation,
  route,
}: RootStackScreenProps<"CurrentInspection">) => {
  const dispatch = useAppDispatch();

  const { type: inspectionType } = route.params;

  const [menuDialVisible, setMenuDialVisible] = useState(true);

  const containerRedux = useSelector(selectContainer());
  const sealsRedux = useSelector(selectSeals());

  const containerData =
    containerRedux === undefined
      ? { id: "", s3Path: "", imgUrl: "" }
      : containerRedux;
  const setContainerData = (container: ContainerState) => {
    dispatch(updateContainer({ container: container }));
  };

  const seals =
    sealsRedux === undefined
      ? inspectionType === "seal"
        ? [
            {
              timestamp: Date.now().toString(),
              category: SealCategory.ORIGINAL,
              serialId: "",
              s3Path: "",
              imgUrl: "",
              owner: SEAL_OWNERS[0],
              location: SEAL_LOCATIONS[0],
              validation: ValidationState.DEFAULT,
            },
          ]
        : []
      : sealsRedux;

  const setSeals = (seals: SealState[]) => {
    dispatch(updateSeals({ seals }));
  };

  const addNewSeal = () => {
    const newSealId = Date.now().toString();
    navigation.push("CameraSeal", {
      sealId: newSealId,
    });
  };

  const onGoBack = async () => {
    await dispatch(syncCurrentInspection());
    navigation.navigate("Home");
  };

  useFocusEffect(
    React.useCallback(() => {
      setMenuDialVisible(true);
    }, [])
  );

  return (
    <>
      <SafeAreaView style={{ flex: 1, paddingTop: 0 }}>
        <Appbar.Header
          style={{
            height: 65,
            backgroundColor: Theme.colors.primary,
          }}
        >
          <Appbar.BackAction onPress={onGoBack} color={Theme.colors.title} />
          <Appbar.Content
            title={i18nDictionary.screens.Inspections.newInspection()}
            color={Theme.colors.title}
          />
        </Appbar.Header>

        <Forms
          inspectionType={containerRedux ? "container" : inspectionType}
          containerData={containerData}
          setContainerData={setContainerData}
          seals={seals}
          setSeals={setSeals}
          navigation={navigation}
        />
      </SafeAreaView>

      <Portal>
        <FAB
          icon="plus"
          visible={menuDialVisible}
          style={{
            width: 68,
            height: 68,
            borderRadius: 34,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            margin: 16,
            right: 0,
            bottom: 0,
            backgroundColor: Theme.colors.primary_dark,
          }}
          onPress={() => {
            setMenuDialVisible(false);
            addNewSeal();
          }}
        />
      </Portal>
    </>
  );
};

export default CurrentInspection;
