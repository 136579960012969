import React from "react"
import { TouchableOpacity, View } from "react-native"

import { Theme } from "../../styles"
import Text from "../../../shared/atoms/Text"

// libs
import Ellipsis from "../../../shared/svg/ellipsis"
import ArrowBack from "../../../shared/svg/arrow_back"
import Cargo from "../../../shared/svg/cargo"
import { Sizes } from "../../../shared/constants/sizes"

const HeaderBar = (props: { hasLogo: string; goBack: boolean; nextStep?: Function; submit?: Function; options?: Function }) => {
  return (
    <View
      style={{
        height: 65,
        paddingHorizontal: 30,
        width: "100%",
        backgroundColor: Theme.colors.primary,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TouchableOpacity onPress={() => (props.nextStep ? props.nextStep() : null)}>
        {props.hasLogo !== "false" && props.hasLogo !== "true" ? (
          <ArrowBack width={35} height={35} color={Theme.colors.title} />
        ) : (
          <Ellipsis width={30} height={30} color={Theme.colors.title} />
        )}
      </TouchableOpacity>

      <View style={{ alignItems: "center", width: "100%" }}>
        {props.hasLogo === "true" ? (
          <Cargo width={100} height={100} />
        ) : (
          props.hasLogo !== "false" && (
            <Text style={{ fontSize: Sizes.h1, color: Theme.colors.title, fontWeight: "700" }}>{props.hasLogo}</Text>
          )
        )}
      </View>
    </View>
  )
}

export default HeaderBar
