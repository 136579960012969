import { CategoryState, SealCategoryValue } from "../types";
import i18nDictionary from "../i18n";
import { Platform } from "react-native";

const SEAL_OWNERS = [
  "other", // We use it on translation, so it should match the translation key
  "88PMA",
  "@ferch",
  "ACT",
  "ADUANA",
  "ADUANA PE",
  "ANIMAL QUARANTINE",
  "APMT",
  "AREAPIF",
  "ARKAS",
  "AS&A",
  "Australian Government",
  "BODA",
  "BOLUDA LINES",
  "BP",
  "BRASIL S.I.F.",
  "CALUP",
  "CBP",
  "CFM",
  "CMA-CGM",
  "COSCO",
  "CSOL",
  "CTC",
  "CUSTOMS ICD CHAWA",
  "DIAMOND V",
  "Douane Marocaine",
  "Douane Tunisienne",
  "DYW",
  "EMBARO",
  "EST OF N'4115",
  "ETIDOR",
  "EVERGREEN",
  "FELVENZA",
  "GGP - INA",
  "Hapag Lloyd",
  "HMM",
  "HUTCHISONPORTS",
  "i-TEK",
  "IDIARN",
  "IIDD PPG",
  "INDIAN CUSTOMS",
  "intertek",
  "JANNAGAR",
  "JF ASOCIADOS",
  "KORTIMED",
  "logifrans",
  "MAERSK",
  "MANMUM",
  "MS/ADUANA PARAGUAY",
  "MSC",
  "NEW TRANSPORT",
  "NIC",
  "NICOLAS FELIPE",
  "NIL",
  "NOVUS",
  "NSF",
  "NZMPI",
  "OCEAMIC",
  "ONE",
  "OOCL",
  "OSTCO",
  "PIF - C.B.C.S.",
  "PR",
  "precintia",
  "PROTEC",
  "PROTECTED",
  "PSF-PY ",
  "RECORD",
  "REVENUE SERVICE",
  "SAG",
  "SANIDAD ANIMAL",
  "SANIDAD EXTERIOR",
  "Sanster",
  "SEALED",
  "SECURITY SEAL",
  "Selecta",
  "Selecta ",
  "SENACSA",
  "SENASA",
  "Sepio SPPL",
  "TCB",
  "TERCAT",
  "TIMAGO DE MORA",
  "UL",
  "UNISIE ARRIMAGE",
  "UNKNOWN RED TERMINAL",
  "USIONS",
  "UY MVD",
  "W.E.C.",
  "WARNER",
  "Yang Ming",
  "YAZOO",
  "ZIM",
  "ZZC",
  "ZZZ",
];

const CATEGORY_SEAL_OPTIONS: CategoryState<SealCategoryValue> = [
  {
    label: i18nDictionary.screens.Seal.categories.orginal(),
    value: "ORIGINAL",
  },
  { label: i18nDictionary.screens.Seal.categories.vessel(), value: "NAVIERA" },
  {
    label: i18nDictionary.screens.Seal.categories.terminal(),
    value: "TERMINAL",
  },
  {
    label: i18nDictionary.screens.Seal.categories.inspectionTeam(),
    value: "EQ",
  },
  {
    label: i18nDictionary.screens.Seal.categories.inspectionCertificate(),
    value: "INSPECTION",
  },
  { label: i18nDictionary.screens.Seal.categories.other(), value: "OUTRO" },
];

const getCategoryValueToLabel = () => {
  const CategoryValueToLabel = new Map<SealCategoryValue, string>();
  CATEGORY_SEAL_OPTIONS.forEach((c) =>
    CategoryValueToLabel.set(c.value, c.label)
  );

  return CategoryValueToLabel;
};

const MORE_ICON = Platform.OS === "ios" ? "dots-horizontal" : "dots-vertical";

export {
  SEAL_OWNERS,
  getCategoryValueToLabel,
  CATEGORY_SEAL_OPTIONS,
  MORE_ICON,
};
