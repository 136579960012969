import { isNone } from "fp-ts/lib/Option"
import { ContainerIdState } from "../pages/Inspection/components/container/ContainerId"
import { ErrorId } from "../pages/Inspection/components/container/ContainerId/ErrorPanel"
import { checkDigit } from "../utils/bic_code_validator"

const validateContainerId = (inputs: ContainerIdState, listCodes: Set<string>) => {
    const errors = new Set<ErrorId>()
    const { owner, productGroup, serial, digit } = inputs

    const ownerPrefix = [...owner, productGroup].join('')
    const serialString = serial.join('')

    if (serialString.length !== serial.length || ownerPrefix.length !== owner.length + 1 || digit === undefined) {
        errors.add(ErrorId.incompleteInput)
        return errors
    }
    
    if (!listCodes.has(ownerPrefix)) {
        errors.add(ErrorId.ownerPrefixErrorMessage)
    }

    const expectedDigit = checkDigit(ownerPrefix + serialString)
    if (isNone(expectedDigit)) {
        errors.add(ErrorId.checkDigitNoneErrorMessage)
    } else if (expectedDigit.value != digit) {
        errors.add(ErrorId.checkDigitErrorMessage)
    }

    return errors
}

export { validateContainerId }
