export const schema = {
    "models": {
        "Inspection": {
            "name": "Inspection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "container": {
                    "name": "container",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContainerData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "seals": {
                    "name": "seals",
                    "isArray": true,
                    "type": {
                        "nonModel": "InspectionSeal"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startedTime": {
                    "name": "startedTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Inspections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "SealCategory": {
            "name": "SealCategory",
            "values": [
                "ORIGINAL",
                "NAVIERA",
                "TERMINAL",
                "EQ",
                "INSPECTION",
                "OUTRO"
            ]
        },
        "SealLocation": {
            "name": "SealLocation",
            "values": [
                "CAM_SECURE",
                "SAM_ALTERNATIVE",
                "OTHER",
                "LEFT"
            ]
        },
        "SealValidation": {
            "name": "SealValidation",
            "values": [
                "OK",
                "VIOLATED",
                "DEFAULT"
            ]
        }
    },
    "nonModels": {
        "ContainerData": {
            "name": "ContainerData",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "imgUrl": {
                    "name": "imgUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "containerSize": {
                    "name": "containerSize",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "containerType": {
                    "name": "containerType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "InspectionSeal": {
            "name": "InspectionSeal",
            "fields": {
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": {
                        "enum": "SealCategory"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "serialId": {
                    "name": "serialId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "imgUrl": {
                    "name": "imgUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "enum": "SealLocation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "validation": {
                    "name": "validation",
                    "isArray": false,
                    "type": {
                        "enum": "SealValidation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "reason": {
                    "name": "reason",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.3.5",
    "version": "23e8686a9ff000caea6efa91e7337311"
};