import React from "react"
import { View } from "react-native"
import Svg, { G, Line, Path, Polygon } from "react-native-svg"
const ContainerDraw = (props: { width: number; height?: number; }) => {
  const st0 = {
    fill: "#7090CF",
  }
  const st1: any = {
    fill: "#FFCA45",
  }

  const st2: any = {
    fill: "none",
    stroke: "#FFF",
    strokeWidth: 3,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  }

  const st3 = {
    fill: "#AFBEBF",
  }
  return (
    <Svg width={props.width} height={props.height} x="0px" y="0px" viewBox="0 0 2000 2000">
      <Polygon fill={st0.fill} points="651.5,408 385.5,900 375.5,1436 636.5,1586 " />
      <G>
        <Polygon fill={st1.fill} points="651.5,408 1624.5,489 1624.5,1592 636.5,1586 	" />
        <Polygon fill={st0.fill} points="687.5,454 1579.5,526 1591.6,1554.6 670.6,1554.6 	" />
      </G>
      <G>
        <Path
          fill={st1.fill}
          d="M742.9,1148.5c-3.4,1.6-4.1,16.4-1.8,18.8s47.9-0.6,52.4,1.5s9.8,20.5,20.8,20.5c9.5,0,12.5-19.8,20.6-19.8
s27-2.4,31.6,1.8c8.4,7.4,21.7,7.3,25.4,3.5s45.8-7,55.3-7c15.6,0,37.7-0.7,43.3,4.9s1.2,19.9,10.5,19.9s6.8-16.3,14.9-19.9
c8-3.6,24.9-6,28.8-2.1c4.9,4.9,30.3,4.7,30.3,4.7l-0.1-30.7c0,0-22.7,8-31.2,8s-23.2-2.4-28.2-7.3c-4.9-4.9-4.7-12.5-11.7-12.5
s-12.5,14.5-18.3,15.7c-5.8,1.2-24.9,3-35.8,3c-18.3,0-51.4-2.3-54.5-4.3s-6.7-2-10.7-2.5c-13.6-1.6-15.1,8.3-23.7,8
c-38.1-1.1-32.7-23.5-43.8-24.5c-12.5-1.2-14.5,22.8-23,22.8C782.3,1150.8,742.9,1148.5,742.9,1148.5z"
        />
        <Path
          fill={st1.fill}
          d="M1571.1,1148.5c3.4,1.6,4.1,16.4,1.8,18.8s-47.9-0.6-52.4,1.5s-9.8,20.5-20.8,20.5c-9.5,0-12.5-19.8-20.6-19.8
s-27-2.4-31.6,1.8c-8.4,7.4-21.7,7.3-25.4,3.5s-45.8-7-55.3-7c-15.6,0-37.7-0.7-43.3,4.9s-1.2,19.9-10.5,19.9s-6.8-16.3-14.9-19.9
c-8-3.6-24.9-6-28.8-2.1c-4.9,4.9-30.3,4.7-30.3,4.7l0.1-30.7c0,0,22.7,8,31.2,8s23.3-2.4,28.2-7.3c4.9-4.9,4.7-12.5,11.7-12.5
s12.5,14.5,18.3,15.7c5.8,1.2,24.9,3,35.8,3c18.3,0,51.4-2.3,54.5-4.3s6.7-2,10.7-2.5c13.6-1.6,15.1,8.3,23.7,8
c38.1-1.1,32.7-23.5,43.8-24.5c12.5-1.2,14.5,22.8,23,22.8C1531.7,1150.8,1571.1,1148.5,1571.1,1148.5z"
        />
      </G>
      <G>
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="633.9"
          y1="506.3"
          x2="633.9"
          y2="963.6"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="617.9"
          y1="917.4"
          x2="617.9"
          y2="1507"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="590.8"
          y1="1559.8"
          x2="590.8"
          y2="575.3"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="557.8"
          y1="746.3"
          x2="557.8"
          y2="1287.9"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="529.3"
          y1="1432.5"
          x2="529.3"
          y2="680.5"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="502.2"
          y1="846.9"
          x2="502.2"
          y2="1139.4"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="487.3"
          y1="1131.9"
          x2="487.3"
          y2="1471.5"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="466.3"
          y1="1193.4"
          x2="466.3"
          y2="798.9"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="440.8"
          y1="1287.9"
          x2="440.8"
          y2="948.9"
        />
        <Line
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          x1="409.3"
          y1="1455.4"
          x2="409.3"
          y2="902.4"
        />
        <Polygon
          fill={st2.fill}
          stroke={st2.stroke}
          strokeWidth={st2.strokeWidth}
          strokeLinecap={st2.strokeLinecap}
          strokeLinejoin={st2.strokeLinejoin}
          strokeMiterlimit={st2.strokeMiterlimit}
          points="385.5,900 394.4,900 397.4,888.7 404.7,888.8 405.6,876.8 412.3,875.2 411.8,867.2 418.5,865.2 
418.1,855.5 425.8,854.6 424.2,842.6 433.5,841.2 432.2,827.7 441.1,826.9 439.5,812.2 449.6,812.2 449.6,794 460.9,794 
460.9,776.9 470.6,775.8 470.6,757 481.9,757 480.6,735.8 489.9,735.8 489.7,714.2 505.7,710.7 504.2,690.1 517.7,687.6 
517.7,665.5 532.5,665.5 532.5,638.7 546.2,638.7 545.3,610.9 557.9,612.9 563.1,579.3 578.1,582.8 584.4,541.9 598,547.6 
602.1,503.7 620.5,510.8 621.6,465.8 638.3,471.8 636.5,432.5 	"
        />
      </G>
      <G>
        <Path
          fill={st3.fill}
          d="M1190.6,494.6l53.9,4.3c0,0-0.9,52.7-2,63.7c-0.6,5.6-12.9,12.4-12.9,17.4s13.4,857,14.5,864.7
c1.5,10.7,16.9,6.5,16.9,26.5c0,13.5,2.6,83.4,2.6,83.4h-64.4c0,0-2.4-81,1.8-90.8c5.6-13.2,14.5-7,17-18.5s-8.3-860.2-11.5-866.5
s-14.5-7.5-16.3-15.3S1190.6,494.6,1190.6,494.6z"
        />
        <Polygon fill={st3.fill} points="1130.5,489.8 1143.5,1554.6 1168.5,1554.6 1155.1,491.7 	" />
        <Path
          fill={st3.fill}
          d="M877,469.3l53.3,4.3c0,0-0.9,53.9-2,64.8c-0.6,5.6-12.9,12.4-12.9,17.4s-1.6,871-0.5,878.7
c1.5,10.7,16.9,6.5,16.9,26.5c0,13.5,0.1,93.6,0.1,93.6h-63.1c0,0-1.1-91.2,3.1-101.1c5.6-13.2,14.5-7,17-18.5s6.8-874.3,3.5-880.5
s-14.5-7.5-16.3-15.3S877,469.3,877,469.3z"
        />
        <Path
          fill={st3.fill}
          d="M1051.7,481.9l53.3,4.3c0,0-0.9,53.9-2,64.8c-0.6,5.6-12.9,12.4-12.9,17.4s-1.6,871-0.5,878.7
c1.5,10.7,16.9,6.5,16.9,26.5c0,13.5,0.1,81,0.1,81h-63.1c0,0-1.1-78.7,3.1-88.5c5.6-13.2,14.5-7,17-18.5s6.8-874.3,3.5-880.5
s-14.5-7.5-16.3-15.3S1051.7,481.9,1051.7,481.9z"
        />
        <Path
          fill={st3.fill}
          d="M1360.6,508.6l53.9,4.3c0,0-0.9,52.7-2,63.7c-0.6,5.6-12.9,12.4-12.9,17.4s13.4,835,14.5,842.7
c1.5,10.7,16.9,6.5,16.9,26.5c0,13.5,2.6,91.4,2.6,91.4h-64.4c0,0-2.4-89,1.8-98.8c5.6-13.2,14.5-7,17-18.5s-8.3-838.2-11.5-844.5
s-14.5-7.5-16.3-15.3S1360.6,508.6,1360.6,508.6z"
        />
      </G>
    </Svg>
  )
}

export default ContainerDraw
