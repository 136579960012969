import React from 'react'
import Svg, { Path } from 'react-native-svg';
const Lock = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 1152 1408" fill="none" >
      <Path d="M320 640H832V448C832 377.333 807 317 757 267C707 217 646.667 192 576 192C505.333 192 445 217 395 267C345 317 320 377.333 320 448V640ZM1152 736V1312C1152 1338.67 1142.67 1361.33 1124 1380C1105.33 1398.67 1082.67 1408 1056 1408H96C69.3333 1408 46.6667 1398.67 28 1380C9.33333 1361.33 0 1338.67 0 1312V736C0 709.333 9.33333 686.667 28 668C46.6667 649.333 69.3333 640 96 640H128V448C128 325.333 172 220 260 132C348 44 453.333 0 576 0C698.667 0 804 44 892 132C980 220 1024 325.333 1024 448V640H1056C1082.67 640 1105.33 649.333 1124 668C1142.67 686.667 1152 709.333 1152 736Z" fill={props.color} />
    </Svg>
  )
}

export default Lock; 