import React from "react";
import { View } from "react-native";
import { Ionicons } from "@expo/vector-icons";

import Text from "../atoms/Text";

export default function InputWarningMessage({
  warningMsg,
}: {
  warningMsg: string;
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: 4,
        gap: 4,
      }}
    >
      <Ionicons name="ios-warning-outline" size={14} color="red" />
      <Text style={{ color: "red", fontSize: 14 }}>{warningMsg}</Text>
    </View>
  );
}
