import React, { useState } from "react";
import { View, StyleSheet } from "react-native";

// Assets
import AccordionTitle from "./AccordionTitle";
import AccordionContent from "./AccordionContent";
import { Theme } from "../../styles";
import { IconAccordionState } from "../../types";

interface AccordionProps {
  children: any;
  title: string;
  icon: IconAccordionState;
  open: boolean;
  onPress: () => void;
}

const Accordion = ({
  children,
  title,
  icon,
  open,
  onPress,
}: AccordionProps) => {
  const styles = StyleSheet.create({
    container: {
      padding: 0,
      borderRadius: 5,
      height: "auto",
      justifyContent: "center",
    },
  });

  return (
    <View style={styles.container}>
      <AccordionTitle
        icon={icon}
        title={title}
        onPress={onPress}
        color={Theme.colors.title}
        open={open}
      />
      {open && <AccordionContent children={children} />}
    </View>
  );
};

export default Accordion;
