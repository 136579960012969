import {
  ContainerSize,
  CONTAINER_SIZES_SET,
  CONTAINER_TYPES_SET,
  DEFAULT_CONTAINER_SIZE,
  DEFAULT_CONTAINER_TYPE,
  ContainerType,
  ContainerState,
  SealState,
  ValidationState,
  PhotoMetadata,
  SealCategory,
  SEAL_LOCATIONS,
} from "../types"
import "react-native-get-random-values"
import Storage, { storageUri } from "../aws/storage"
import axios from "axios"
import { Either, isLeft, left, right } from "fp-ts/lib/Either"
import { OWNER_TO_CATEGORY } from "../types"
import awsmobile from "../aws-exports"

const OCR_SERVICE =
  awsmobile.aws_user_files_s3_bucket.split("-")[1] === "staging"
    ? "https://ocr.service.staging.goclever.io"
    : "https://ocr.service.goclever.io"
// const OCR_SERVICE = "https://ocr.service.staging.goclever.io"

const CONTAINER_PATH = "container"
const SEAL_PATH = "seal"

interface ContainerOcr {
  containerSize: string
  containerType: string
  croppedImageUrl: string
  digit: string
  imageOriginal: string
  ownerPrefix: string
  serialNumber: string
}

interface SealOcr {
  croppedImageUrl: string
  imageOriginal: string
  imageOriginalRotation: number,
  owners: Record<string, number>,
  sealId: string,
}

interface AllSealsOcr {
  seals: SealOcr[]
}

const toContainer = ({
  containerSize,
  containerType,
  croppedImageUrl,
  digit,
  imageOriginal,
  ownerPrefix,
  serialNumber,
}: ContainerOcr): ContainerState => {
  const size: ContainerSize = CONTAINER_SIZES_SET.has(containerSize) ? (containerSize as ContainerSize) : DEFAULT_CONTAINER_SIZE
  const type: ContainerType = CONTAINER_TYPES_SET.has(containerType) ? (containerType as ContainerType) : DEFAULT_CONTAINER_TYPE

  return {
    id: ownerPrefix + serialNumber + digit,
    imgUrl: croppedImageUrl,
    s3Path: imageOriginal,
    containerSize: size,
    containerType: type,
  }
}

const ownerToCategory = (owner: string): string => {
  return OWNER_TO_CATEGORY.get(owner.trim()) || "ORIGINAL"
}

function findMaxKey(obj: Record<string, number>): string {
  const entries = Object.entries(obj)
  if (entries.length === 0) {
    return ""
  }
  return entries.reduce((maxEntry, currentEntry) => {
    return currentEntry[1] > maxEntry[1] ? currentEntry : maxEntry
  }, entries[0])[0]
}

const toSeal = (detectedSeals: AllSealsOcr, s3Path: string, sealPhotoId?: string): SealState[] => {
  let seals: SealState[] = []

  detectedSeals.seals.forEach((element, index) => {
    const id = element.sealId ? element.sealId : ""
    const seal: SealState = {
      timestamp: sealPhotoId == undefined ? "" : sealPhotoId,
      s3Path: s3Path,
      imgUrl: element.croppedImageUrl,
      serialId: id,
      owner: findMaxKey(element.owners),
      location: SEAL_LOCATIONS[0],
      validation: ValidationState.DEFAULT,
      category: ownerToCategory(findMaxKey(element.owners)) as SealCategory
    }
    seals.push(seal)
  })
  return seals
}

const ocrService = axios.create({
  baseURL: OCR_SERVICE,
})

const getContainerId = (imgUrl: string) =>
  ocrService.get<ContainerOcr>(CONTAINER_PATH, { params: { image_url: imgUrl } }).then((r) => toContainer(r.data))
const getSealId = (imgUrl: string, sealPhotoId?: string) =>
  ocrService.get<AllSealsOcr>(SEAL_PATH, { params: { image_url: imgUrl } }).then((r) => toSeal(r.data, imgUrl, sealPhotoId))

type OcrGet<T> = (imgUrl: string) => Promise<T>

export const saveImage = async (form: string, blob: Blob, id: string, metadata: PhotoMetadata): Promise<Either<ErrorId, string>> => {
  const fn = `${form}-id-${id}.${metadata.photoFormat}`

  try {
    const data = await Storage.put(fn, blob, {
      contentType: `image/${metadata.photoFormat.replace(".", "")}`,
      metadata: {
        timestamp: Date.now().toString(),
        id,
        photoHeight: metadata.photoHeight.toString(),
        photoWidth: metadata.photoWidth.toString(),
      },
    })
    const imgUrl = storageUri(data.key)
    return right(imgUrl)
  } catch (e) {
    return left(ErrorId.StorageServiceUploadFailed)
  }
}

export enum ErrorId {
  StorageServiceUploadFailed,
  InvalidUrlMessage,
  UrlSourceNotImageMessage,
  ImageDownloadFailedS3,
  DetectTextImageFailedAccess,
  AccessFailedMessage,
  NoBoundingBox,
  SagemakerEndpointServiceUnavailable,
  ImageBiggerThen5Mb,
  ImageSmallerThen100Kb,
  ImageMediaTypeInvalid,
}

const ocrId =
  <T>(getOcr: OcrGet<T>) =>
  async (form: string, blob: Blob, id: string, metadata: PhotoMetadata): Promise<Either<ErrorId, T>> => {
    const errorOrImgUrl = await saveImage(form, blob, id, metadata)
    if (isLeft(errorOrImgUrl)) {
      return errorOrImgUrl
    }
    const imageUrl = errorOrImgUrl.right
    try {
      const ocr = await getOcr(imageUrl)
      return right(ocr)
    } catch (e: any) {
      console.log(e)
      const status = parseInt(e?.response?.status)
      const errorId = e?.response?.data?.errorId
      switch (status) {
        case 404:
          return left(ErrorId.NoBoundingBox)
        case 400:
          if (errorId === "InvalidUri") {
            return left(ErrorId.InvalidUrlMessage)
          } else {
            return left(ErrorId.UrlSourceNotImageMessage)
          }
        case 413:
          return left(ErrorId.ImageBiggerThen5Mb)
        case 415:
          return left(ErrorId.ImageMediaTypeInvalid)
        case 422:
          return left(ErrorId.ImageSmallerThen100Kb)
        case 503:
          if (errorId === "StorageServiceUnavailable") {
            return left(ErrorId.ImageDownloadFailedS3)
          } else if (errorId === "DetectTextServiceUnavailable") {
            return left(ErrorId.DetectTextImageFailedAccess)
          } else {
            return left(ErrorId.SagemakerEndpointServiceUnavailable)
          }
      }

      return left(ErrorId.AccessFailedMessage)
    }
  }
export const ocrContainerId = ocrId(getContainerId)
export const ocrSealId = ocrId(getSealId)
