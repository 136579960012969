import React from "react"
import { View, StyleSheet, Dimensions } from "react-native"
import { useDispatch } from "react-redux"

// Components
import { Theme } from "../../../../styles"
import ImagePicker from "../../../../components/ImagePicker"
import ContainerId, { ContainerIdState } from "./ContainerId"

// i18n
import dict from "../../../../i18n"

// Constants
import {
  ContainerState,
  ContainerSize,
  CategoryState,
  CONTAINER_SIZES,
  ContainerType,
  CONTAINER_TYPES_SET,
  CONTAINER_SIZES_SET,
  DEFAULT_CONTAINER_SIZE,
  DEFAULT_CONTAINER_TYPE,
  ContainerProductGroup,
  ContainerSerial,
  NUM,
  ContainerOwner,
} from "../../../../types"

// Utils
import Dropdown from "../../../../components/Dropdown"
import { ErrorId } from "./ContainerId/ErrorPanel"
import { useSelector } from "react-redux"
import { selectSealsCount } from "../../../../store/inspectionSlice"
import { StackNavigationProp } from "@react-navigation/stack"
import { RootStackParamList } from "../../../../types"
import { Sizes } from "../../../../../shared/constants/sizes"

const CONTAINER_SIZE_CATEGORIES: CategoryState<string> = CONTAINER_SIZES.map((s) => ({ label: s, value: s }))
const CONTAINER_TYPE_CATEGORIES: CategoryState<string> = [
  { label: dict.screens.Container.types.G1(), value: "G1" },
  { label: dict.screens.Container.types.R1(), value: "R1" },
  { label: dict.screens.Container.types.U1(), value: "U1" },
  { label: dict.screens.Container.types.P1(), value: "P1" },
  { label: dict.screens.Container.types.T1(), value: "T1" },
]

interface ContainerProps {
  owner: ContainerOwner
  productGroup: ContainerProductGroup | undefined
  serial: ContainerSerial
  digit: NUM | undefined
  rawContainerSize: string
  rawContainerType: string
  imgUrl: string
  s3Path: string
  onChangeContainerData: (data: ContainerState) => void
  containerIdErrors: Set<ErrorId>
  sealsCount: number
  navigation: StackNavigationProp<RootStackParamList, "CurrentInspection", undefined>
}

// FIXME VAS: we need a prop variable to control focus
const ContainerForm = ({
  owner,
  productGroup,
  serial,
  digit,
  rawContainerSize,
  rawContainerType,
  imgUrl,
  s3Path,
  onChangeContainerData,
  containerIdErrors,
  sealsCount,
  navigation,
}: ContainerProps) => {
  const containerSize: ContainerSize = CONTAINER_SIZES_SET.has(rawContainerSize)
    ? (rawContainerSize as ContainerSize)
    : DEFAULT_CONTAINER_SIZE
  const containerType: ContainerType = CONTAINER_TYPES_SET.has(rawContainerType)
    ? (rawContainerType as ContainerType)
    : DEFAULT_CONTAINER_TYPE

  const containerIdState: ContainerIdState = { owner, productGroup, serial, digit }

  const onContainerIdChange = ({ owner, productGroup, serial, digit }: ContainerIdState) => {
    const containerId: string = [
      [...owner, productGroup === undefined ? " " : productGroup.toString()].join(""),
      serial.join(""),
      digit,
    ].join("")
    onChangeContainerData({ id: containerId, imgUrl, s3Path })
  }

  const onSizeChange = (value: string) => {
    if (CONTAINER_SIZES_SET.has(value)) {
      const containerSize = value as ContainerSize
      const containerId: string = [[...owner, productGroup?.toString()].join(""), serial.join(""), digit].join("")
      onChangeContainerData({ id: containerId, imgUrl, s3Path, containerSize })
    }
  }

  const onTypeChange = (value: string) => {
    if (CONTAINER_TYPES_SET.has(value)) {
      const containerType = value as ContainerType
      const containerId: string = [[...owner, productGroup?.toString()].join(""), serial.join(""), digit].join("")
      onChangeContainerData({ id: containerId, imgUrl, s3Path, containerType })
    }
  }

  return (
    <View style={styles.contentBox}>
      <ImagePicker
        onPress={() => null}
        imageUrl={imgUrl === "To be done" ? s3Path : imgUrl}
        type="CONTAINER"
      />

      <ContainerId containerIdState={containerIdState} errors={containerIdErrors} onChangeHandler={onContainerIdChange} />

      <View style={styles.sizeType}>
        <View style={{ ...styles.dropdown, marginRight: Sizes.marginM }}>
          <Dropdown
            label={dict.screens.Container.labelContainerSize()}
            selectedValue={containerSize}
            values={CONTAINER_SIZE_CATEGORIES}
            onValueChange={onSizeChange}
          />
        </View>

        <View style={styles.dropdown}>
          <Dropdown
            label={dict.screens.Container.labelContainerType()}
            selectedValue={containerType}
            values={CONTAINER_TYPE_CATEGORIES}
            onValueChange={onTypeChange}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dropdown: {
    width: "50%",
    marginTop: Sizes.marginL,
    borderWidth: 1,
    borderColor: Theme.colors.border,
    borderRadius: Sizes.borderRadiusS,
    height: Sizes.heightM,
    padding: 0,
  },
  contentBox: {
    backgroundColor: Theme.colors.title,
    padding: Sizes.paddingM,
    borderBottomEndRadius: 15,
    borderBottomLeftRadius: 15,
    borderWidth: 1,
    borderColor: Theme.colors.primary,
  },
  sizeType: {
    width: "70%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
})

export default ContainerForm
