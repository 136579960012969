import React from 'react'
import Svg, { Path } from 'react-native-svg';
const NotFound404 = (props: {width: number, height: number}) => {

    return (
        <Svg width={props.width} height={props.height} viewBox="0 0 692 569" fill="none">
            <Path d="M484.463 59.8138C484.802 62.1591 486.042 64.2797 487.92 65.7243C489.799 67.1689 492.167 67.8236 494.521 67.549C494.998 67.4856 495.47 67.3828 495.93 67.2416L514.89 92.6917L531.189 87.6144L501.447 55.5592C500.714 53.6947 499.384 52.1253 497.665 51.0959C495.946 50.0666 493.935 49.6353 491.945 49.8692C490.814 50.0132 489.723 50.3785 488.733 50.9443C487.743 51.5102 486.874 52.2654 486.177 53.1669C485.479 54.0684 484.965 55.0986 484.665 56.1986C484.366 57.2985 484.285 58.4468 484.429 59.5777C484.439 59.6565 484.451 59.7353 484.463 59.8138Z" fill="#A0616A" />
            <Path d="M618.347 173.48C618.347 173.48 547.55 140.439 529.641 119.335L497.55 79.4907L513.825 66.1765L543.989 100.92C543.714 108.221 579.367 115.705 598.533 126.346L618.347 173.48Z" fill="#0066FF" />
            <Path d="M635.752 552.039L621.866 552.038L615.26 498.474L635.755 498.475L635.752 552.039Z" fill="#A0616A" />
            <Path d="M636.242 566.525L593.538 566.523V565.983C593.539 561.575 595.29 557.348 598.407 554.231C601.524 551.114 605.752 549.362 610.16 549.362H610.161L617.961 543.444L632.515 549.363L636.242 549.363L636.242 566.525Z" fill="#2F2E41" />
            <Path d="M602.646 539.787L589.123 542.941L570.519 492.28L590.478 487.624L602.646 539.787Z" fill="#A0616A" />
            <Path d="M606.415 553.783L564.828 563.485L564.705 562.959C563.704 558.666 564.449 554.151 566.776 550.408C569.103 546.664 572.822 543.998 577.115 542.996L577.116 542.996L583.368 535.46L598.886 537.917L602.516 537.07L606.415 553.783Z" fill="#2F2E41" />
            <Path d="M2.00008 90.155V11.2798C2.00425 8.81821 2.98467 6.45873 4.72627 4.71904C6.46787 2.97935 8.82841 2.00152 11.2901 2.00006H113.5V6.10352e-05H11.2901C8.29814 0.00205151 5.4292 1.19076 3.31264 3.3054C1.19608 5.42005 0.00476109 8.28794 6.10352e-05 11.2798V90.1551L2.00008 90.155Z" fill="#3F3D56" />
            <Path d="M9.09479 280.048C7.06868 279.562 5.26603 278.405 3.97852 276.767C2.69101 275.129 1.99395 273.104 2.00007 271.02V192.155H4.99443e-05V271.02C-0.00749265 273.552 0.839478 276.013 2.4039 278.003C3.96833 279.994 6.15871 281.399 8.62067 281.991C9.44555 282.193 10.2909 282.3 11.1402 282.31H113.5V280.31H11.1524C10.4588 280.301 9.7685 280.213 9.09479 280.048Z" fill="#3F3D56" />
            <Path d="M467 192.155V271.02C466.997 273.483 466.017 275.844 464.276 277.586C462.534 279.327 460.173 280.307 457.71 280.31H355.5V282.31H457.71C460.703 282.307 463.573 281.116 465.69 279C467.806 276.883 468.997 274.013 469 271.02V192.155H467Z" fill="#3F3D56" />
            <Path d="M464.991 3.04833C465.516 3.3207 466.012 3.64491 466.472 4.01611C467.575 4.87805 468.466 5.98057 469.077 7.23945C469.689 8.49833 470.004 9.88028 470 11.2798V90.155H472V11.2798C472.006 9.57914 471.623 7.89963 470.88 6.36948C470.138 4.83934 469.056 3.499 467.717 2.45072C467.156 1.99923 466.552 1.60484 465.913 1.27346C464.308 0.432337 462.522 -0.00475624 460.71 3.9034e-05H358.5V2.00004H460.71C462.201 1.99615 463.671 2.35597 464.991 3.04833Z" fill="#3F3D56" />
            <Path d="M647.785 291.96L561.383 296.291L555.383 325.291V351.121L551.701 447.298L577.383 522.291H597.974L595.883 512.791C597.025 511.193 597.667 509.293 597.728 507.331C597.789 505.368 597.265 503.432 596.224 501.767L594.572 499.126L591.883 487.791L585.883 445.791L611.58 351.121L614.883 523.791H637.252L640.533 520.043C642.355 517.962 643.44 515.339 643.619 512.578C643.799 509.818 643.062 507.076 641.525 504.777V500.59L638.883 485.791L663.536 302.082L647.785 291.96Z" fill="#2F2E41" />
            <Path d="M610.453 111.934L582.268 113.377L579.066 126.736L558.599 142.4C553.971 145.941 550.426 150.706 548.364 156.156C546.302 161.606 545.805 167.524 546.929 173.241L558.274 230.958L557.536 267.082L559.173 281.874L559.049 284.114L556.543 286.165C554.838 287.561 553.659 289.495 553.2 291.65C552.74 293.804 553.028 296.051 554.016 298.021C554.016 298.021 541.665 324.724 559.536 326.082C577.407 327.44 588.461 308.857 605.536 316.082C622.611 323.306 637.855 324.153 642.536 316.082C647.217 308.011 661.458 309.892 663.171 302.841C664.883 295.79 656.213 287.809 655.048 277.3C653.883 266.79 650.883 217.79 650.883 217.79L659.273 169.683C660.461 162.873 659.402 155.86 656.255 149.704C653.108 143.548 648.045 138.582 641.829 135.556L614.968 122.479L610.453 111.934Z" fill="#0066FF" />
            <Path d="M463.668 262.554C464.801 260.108 466.845 258.201 469.363 257.238C471.881 256.275 474.676 256.333 477.152 257.399C477.652 257.624 478.133 257.89 478.589 258.194L507.55 236.628L523.663 247.473L480.796 272.726C479.389 274.52 477.428 275.798 475.219 276.36C473.01 276.922 470.677 276.737 468.584 275.833C467.398 275.308 466.327 274.554 465.433 273.615C464.538 272.675 463.837 271.569 463.37 270.359C462.903 269.149 462.679 267.858 462.711 266.562C462.742 265.265 463.029 263.987 463.555 262.801C463.591 262.718 463.629 262.636 463.668 262.554Z" fill="#A0616A" />
            <Path d="M553.652 148.822C553.652 148.822 516.111 217.181 501.572 237.287L490.536 245.907L491.536 274.082L516.198 260.473C516.198 260.473 565.963 222.777 564.536 207.082C563.109 191.387 553.652 148.822 553.652 148.822Z" fill="#0066FF" />
            <Path d="M593.969 109.677C608.677 109.677 620.601 97.7534 620.601 83.0452C620.601 68.3369 608.677 56.4134 593.969 56.4134C579.261 56.4134 567.337 68.3369 567.337 83.0452C567.337 97.7534 579.261 109.677 593.969 109.677Z" fill="#A0616A" />
            <Path d="M604.259 107.494C596.617 107.243 595.923 105.225 588.281 104.974C585.847 104.894 582.976 104.561 581.799 102.429C580.961 100.911 581.347 98.9988 582.084 97.4294C582.956 95.9316 583.742 94.3853 584.439 92.7982C584.858 91.5535 584.968 90.2253 584.759 88.9286C584.55 87.6318 584.029 86.4055 583.24 85.3555C582.45 84.3055 581.417 83.4634 580.23 82.9021C579.043 82.3408 577.736 82.0771 576.424 82.1338C574.098 82.2343 571.914 83.328 569.595 83.5278C566.414 83.802 559.381 73.3001 560.845 70.4634C562.271 67.702 565.139 65.6187 565.59 62.544C565.885 60.533 565.055 58.5348 564.942 56.5055C564.773 53.4966 572.401 60.2811 571.004 57.6107C567.577 51.0602 599.867 42.9392 605.183 51.1444C607.17 54.211 610.711 54.0018 613.867 55.8435C615.935 57.0506 621.158 61.2149 622.345 63.8104C623.436 66.4588 623.952 69.309 623.858 72.1718C623.906 85.4861 621.877 97.7331 611.138 105.603" fill="#2F2E41" />
            <Path d="M690.736 567.983L411.986 568.29C411.671 568.289 411.369 568.163 411.147 567.94C410.924 567.717 410.799 567.414 410.799 567.099C410.799 566.784 410.924 566.482 411.147 566.259C411.369 566.035 411.671 565.909 411.986 565.909L690.736 565.601C690.893 565.601 691.048 565.631 691.193 565.691C691.338 565.751 691.47 565.838 691.581 565.949C691.691 566.059 691.779 566.191 691.839 566.336C691.9 566.48 691.93 566.635 691.93 566.792C691.93 566.949 691.9 567.104 691.839 567.248C691.779 567.393 691.691 567.525 691.581 567.635C691.47 567.746 691.338 567.833 691.193 567.893C691.048 567.953 690.893 567.983 690.736 567.983Z" fill="#CACACA" />
            <Path d="M152.468 197.988V169.428H98.2376V156.848C102.998 148.688 107.304 140.471 111.158 132.198C115.124 123.924 118.638 115.424 121.698 106.698C124.871 97.9711 127.478 88.8477 129.518 79.3277H145.498C144.024 85.5611 142.211 91.9644 140.058 98.5377C137.904 104.998 135.524 111.288 132.918 117.408C130.311 123.528 127.704 129.251 125.098 134.578C122.604 139.904 120.224 144.494 117.958 148.348C115.804 152.201 114.048 155.034 112.688 156.848H152.468V119.788C153.148 117.974 153.828 116.104 154.508 114.178C155.301 112.138 156.038 110.098 156.718 108.058C157.398 105.904 158.021 103.921 158.588 102.108C159.154 100.181 159.608 98.3677 159.948 96.6677H167.428V156.848H182.898V169.428H167.428V197.988H152.468ZM344.216 197.988V169.428H289.986V156.848C294.746 148.688 299.052 140.471 302.906 132.198C306.872 123.924 310.386 115.424 313.446 106.698C316.619 97.9711 319.226 88.8477 321.266 79.3277H337.246C335.772 85.5611 333.959 91.9644 331.806 98.5377C329.652 104.998 327.272 111.288 324.666 117.408C322.059 123.528 319.452 129.251 316.846 134.578C314.352 139.904 311.972 144.494 309.706 148.348C307.552 152.201 305.796 155.034 304.436 156.848H344.216V119.788C344.896 117.974 345.576 116.104 346.256 114.178C347.049 112.138 347.786 110.098 348.466 108.058C349.146 105.904 349.769 103.921 350.336 102.108C350.902 100.181 351.356 98.3677 351.696 96.6677H359.176V156.848H374.646V169.428H359.176V197.988H344.216Z" fill="black" />
            <Path d="M236.564 200.028C230.218 200.028 224.551 199.121 219.564 197.308C214.578 195.381 210.328 192.151 206.814 187.618C203.301 182.971 200.638 176.794 198.824 169.088C197.011 161.268 196.104 151.464 196.104 139.678C196.104 127.891 197.011 118.144 198.824 110.438C200.638 102.618 203.301 96.4411 206.814 91.9077C210.328 87.2611 214.578 84.0311 219.564 82.2177C224.551 80.2911 230.218 79.3277 236.564 79.3277C242.911 79.3277 248.521 80.2911 253.394 82.2177C258.381 84.0311 262.631 87.2611 266.144 91.9077C269.658 96.4411 272.321 102.618 274.134 110.438C275.948 118.144 276.854 127.891 276.854 139.678C276.854 151.464 275.948 161.268 274.134 169.088C272.321 176.794 269.658 182.971 266.144 187.618C262.631 192.151 258.381 195.381 253.394 197.308C248.521 199.121 242.911 200.028 236.564 200.028ZM236.564 187.448C243.024 187.448 248.011 185.804 251.524 182.518C255.151 179.231 257.644 174.358 259.004 167.898C260.478 161.438 261.214 153.391 261.214 143.758V135.938C261.214 126.191 260.478 118.088 259.004 111.628C257.644 105.054 255.151 100.124 251.524 96.8377C248.011 93.5511 243.024 91.9077 236.564 91.9077C230.104 91.9077 225.061 93.5511 221.434 96.8377C217.808 100.124 215.258 104.998 213.784 111.458C212.424 117.918 211.744 126.021 211.744 135.768V143.418C211.744 153.051 212.424 161.154 213.784 167.728C215.258 174.301 217.808 179.231 221.434 182.518C225.061 185.804 230.104 187.448 236.564 187.448Z" fill="#0066FF" />
        </Svg>
    )
}

export default NotFound404; 