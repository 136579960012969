import React from "react"
import { View } from "react-native"
import { Theme } from "../../../../../styles"

// Components
import Text from '../../../../../../shared/atoms/Text'

// i18n
import dict from "../../../../../i18n"
import { Sizes } from "../../../../../../shared/constants/sizes"

export enum ErrorId {
    ownerPrefixErrorMessage,
    incompleteInput,
    checkDigitNoneErrorMessage,
    checkDigitErrorMessage,
}

const ERRORS = new Map<ErrorId, string>()
ERRORS.set(ErrorId.ownerPrefixErrorMessage, dict.screens.Container.ownerPrefixErrorMessage())
ERRORS.set(ErrorId.incompleteInput, dict.screens.Container.incompleteInput())
ERRORS.set(ErrorId.checkDigitNoneErrorMessage, dict.screens.Container.checkDigitNoneErrorMessage())
ERRORS.set(ErrorId.checkDigitErrorMessage, dict.screens.Container.checkDigitErrorMessage())

interface ErrorPanelProps {
    errors: Set<ErrorId>
}

const ErrorPanel = ({ errors }: ErrorPanelProps) => {
    return (
        <>
            {Array.from(ERRORS).map(([errorId, error]) => {
                if (errors.has(errorId)) {
                    return (
                        <View key={`container-error-${errorId}`} style={{ width: "100%", paddingHorizontal: Sizes.paddingL }}>

                            <Text style={{ fontSize: Sizes.body6, marginTop: 5, color: Theme.colors.error }}>
                                - {error}
                            </Text>
                        </View>
                    )
                }
            })}
        </>
    )
}

export default ErrorPanel
