import React from 'react'
import Svg, { Path } from 'react-native-svg';
const Camera = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 20 18" fill="none" >
      <Path d="M10 7C8.374 7 7 8.374 7 10C7 11.626 8.374 13 10 13C11.626 13 13 11.626 13 10C13 8.374 11.626 7 10 7Z" fill={props.color} />
      <Path d="M18 3H15.414L12.707 0.293001C12.6143 0.199958 12.5041 0.126171 12.3828 0.0758854C12.2614 0.0256001 12.1313 -0.000189449 12 1.04767e-06H8C7.86866 -0.000189449 7.73857 0.0256001 7.61724 0.0758854C7.4959 0.126171 7.38571 0.199958 7.293 0.293001L4.586 3H2C0.897 3 0 3.897 0 5V16C0 17.103 0.897 18 2 18H18C19.103 18 20 17.103 20 16V5C20 3.897 19.103 3 18 3ZM10 15C7.29 15 5 12.71 5 10C5 7.29 7.29 5 10 5C12.71 5 15 7.29 15 10C15 12.71 12.71 15 10 15Z" fill={props.color} />
    </Svg>
  )
}

export default Camera; 