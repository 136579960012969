import { none, some, Option } from "fp-ts/Option"

export type CheckDigit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

const ALPHABET = new Map<string,number>(Object.entries({
  A: 10,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 23,
  M: 24,
  N: 25,
  O: 26,
  P: 27,
  Q: 28,
  R: 29,
  S: 30,
  T: 31,
  U: 32,
  V: 34,
  W: 35,
  X: 36,
  Y: 37,
  Z: 38,
}))

export const checkDigit: (code: string) => Option<CheckDigit> = (code: string) => {
  const matchedCode = code.toUpperCase().match(/^([A-Za-z]{3}[UJZ]{1})(\d{6})$/)
  if (!matchedCode) {
    return none
  }
  const prefix = matchedCode[1].split('').map((c) => ALPHABET.get(c)) as number[]
  const serial = matchedCode[2].split('').map((c) => parseInt(c))

  const sum = [...prefix, ...serial].map((n, i) => n*(2**i))
    .reduce((sum, n) => sum + n)
  
  const digit = sum % 11 % 10 as CheckDigit
  return some(digit)
}