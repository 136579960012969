import React, { useState } from 'react';
import { ActivityIndicator, Keyboard, StyleSheet, ScrollView, TouchableOpacity, View } from 'react-native';
import { Theme } from '../styles';
import { Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
import i18nDictionary from '../i18n';
// import * as Sentry from 'sentry-expo';
import Toast from 'react-native-toast-message'
import { HeaderComponent } from '../components';
import Input from '../../shared/molecules/Input';
import { DrawerActions } from '@react-navigation/native';
import Cargo from '../../shared/svg/cargo';
import { RootStackScreenProps } from '../types';
import { Sizes } from '../../shared/constants/sizes';
import Text from '../../shared/atoms/Text'

Auth.configure(awsconfig);

const ChangePassword = ({ navigation }: RootStackScreenProps<'ChangePassword'>) => {
    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [loadingButton, setLoadingButton] = useState(false)

    const resetFields = () => {
        setNewPassword("")
        setOldPassword("")
        setNewPasswordConfirm("")
        setLoadingButton(false)
    }

    const sendPwdChange = async () => {
        setLoadingButton(true)
        if (newPasswordConfirm !== newPassword) {
            setLoadingButton(false)
            Toast.show({
                type: 'error',
                text1: 'Ops..',
                text2: i18nDictionary.cognito.ErrorMessageDifferentPasswords()
            })
        } else if (newPasswordConfirm === "" || newPassword === "" || oldPassword === "") {
            setLoadingButton(false)
            Toast.show({
                type: 'error',
                text1: 'Ops..',
                text2: i18nDictionary.cognito.ErrorMessageDifferentPasswords()
            })
        } else {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    return Auth.changePassword(user, oldPassword, newPassword);
                })
                .then((data) => {
                    navigation.navigate("Home")
                    Toast.show({
                        type: 'success',
                        text1: 'Hey',
                        text2: i18nDictionary.cognito.SuccessMessageChangePassword()
                    })
                    resetFields()
                    setLoadingButton(false)
                })
                .catch((err) => {
                    // Platform.OS === "web" ? Sentry.Browser.captureException(err) : Sentry.Native.captureException(err)
                    resetFields()
                    Toast.show({
                        type: 'error',
                        // @ts-ignore
                        text1: i18nDictionary.cognito[err.name + "Title"] !== undefined ? i18nDictionary.cognito[err.name + "Title"]() : "Error",
                        // @ts-ignore
                        text2: i18nDictionary.cognito[err.name] !== undefined ? i18nDictionary.cognito[err.name]() : i18nDictionary.cognito.ErrorGeneralMessage()
                    })
                    setLoadingButton(false)
                });
        }
    }

    const cancelFlow = () => {
        resetFields()
        navigation.navigate("Home")
    }

    return (
        <>
            <HeaderComponent hasLogo={"false"} goBack={false} nextStep={() => navigation.dispatch(DrawerActions.openDrawer())} />
            <View style={styles.safeArea}>
                <ScrollView contentContainerStyle={styles.container}>

                    <Cargo width={300} height={200} color={Theme.colors.primary} />

                    <Text style={{ fontSize: Sizes.body1, color: Theme.colors.primary }}>{i18nDictionary.cognito.ChangePassword()}</Text>

                    <View style={{ width: "100%" }}>
                        <Input
                            onChange={setOldPassword}
                            value={oldPassword}
                            label={i18nDictionary.cognito.OldPassword()}
                            secureTextEntry={true}
                            error={false}
                            editable={true}
                        />
                        <Input
                            onChange={setNewPassword}
                            value={newPassword}
                            label={i18nDictionary.cognito.NewPassword()}
                            secureTextEntry={true}
                            error={false}
                            editable={true}
                        />
                        <Input
                            onChange={setNewPasswordConfirm}
                            value={newPasswordConfirm}
                            label={i18nDictionary.cognito.ConfirmPassword()}
                            secureTextEntry={true}
                            error={false}
                            editable={true}
                            onSubmitEditing={() => {
                                Keyboard.dismiss()
                                sendPwdChange()
                            }}
                        />
                    </View>

                    <View style={{ marginVertical: Sizes.marginL, flexDirection: 'row', width: "75%", justifyContent: 'space-around' }}>
                        <TouchableOpacity
                            onPress={() => sendPwdChange()}
                            style={styles.confirmButton}>
                            {loadingButton ? (
                                <ActivityIndicator color={Theme.colors.title} size={25} />
                            ) : (
                                <Text style={{ fontSize: Sizes.body3, color: Theme.colors.title }}>{i18nDictionary.cognito.Submit()}</Text>
                            )}
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={() => cancelFlow()}
                            style={styles.cancelButton}
                        >
                            <Text style={{ fontSize: Sizes.body3, color: Theme.colors.title }}>{i18nDictionary.cognito.Cancel()}</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    safeArea: {
        height: "100%",
        alignItems: 'center',
        backgroundColor: Theme.colors.title,
        flex: 1
    },
    container: {
        width: "100%",
        justifyContent: 'center',
        alignItems: 'center',
        padding: Sizes.paddingM
    },
    confirmButton: {
        width: "45%",
        borderRadius: Sizes.borderRadiusM,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.colors.success,
        height: Sizes.heightS,
        padding: Sizes.paddingS,
        borderColor: "#fff",
        borderWidth: 1
    },
    cancelButton: {
        width: "45%",
        borderRadius: Sizes.borderRadiusM,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.colors.error,
        height: Sizes.heightS,
        padding: Sizes.paddingS,
    }
});

export default ChangePassword;