import React, { useState } from "react";
import { StyleSheet, View, ScrollView } from "react-native";

// Librarys
import { useSelector } from "react-redux";
import { StackNavigationProp } from "@react-navigation/stack";

// Components
import { Accordion } from "../../../components";
import { Theme } from "../../../styles";
import SealForm from "./seal/SealForm";
import {
  ContainerOwner,
  ContainerSerial,
  ContainerState,
  IconAccordionState,
  SealState,
} from "../../../types";
import ContainerForm from "./container/ContainerForm";
import { getSealTitle, isSealValid } from "../../../helpers/seal";
import { validateContainerId } from "../../../helpers/container";
import { selectOwnerCodes } from "../../../store/ownerCodeSlice";
import { RootStackParamList } from "../../../types";

// Utils
import dict from "../../../i18n";
import {
  toAlphaChar,
  toNumDigit,
  toProductGroupChar,
} from "../../../helpers/converters";
import { useFocusEffect } from "@react-navigation/native";

// Amplify

interface FormsProps {
  inspectionType: "container" | "seal";
  containerData: ContainerState;
  setContainerData: (container: ContainerState) => void;
  seals: SealState[];
  setSeals: (seals: SealState[]) => void;
  navigation: StackNavigationProp<
    RootStackParamList,
    "CurrentInspection",
    undefined
  >;
}

const Forms = ({
  inspectionType,
  containerData,
  setContainerData,
  seals,
  setSeals,
  navigation,
}: FormsProps) => {
  const listCodes = useSelector(selectOwnerCodes());
  const listCodesSet = new Set(listCodes.ownerCodesList);

  // Keep track of closed accordions. Use `closed` instead of `open` because new
  // seals will be automatically open.
  const [closedSeals, setClosedSeals] = useState<Set<string>>(new Set());

  const [containerOpen, setContainerOpen] = useState(true);

  const viewRef = React.createRef<ScrollView>();

  const countSeals = seals.length;

  const { id, imgUrl, s3Path, containerSize, containerType } = containerData;

  const containerId: (string | undefined)[] = [...id];
  while (containerId.length < 11) {
    containerId.push(undefined);
  }

  const owner: ContainerOwner = [
    toAlphaChar(containerId[0]),
    toAlphaChar(containerId[1]),
    toAlphaChar(containerId[2]),
  ];

  const productGroup =
    id !== undefined ? toProductGroupChar(containerId[3]) : undefined;

  const serial: ContainerSerial = [
    toNumDigit(containerId[4]),
    toNumDigit(containerId[5]),
    toNumDigit(containerId[6]),
    toNumDigit(containerId[7]),
    toNumDigit(containerId[8]),
    toNumDigit(containerId[9]),
  ];

  const digit =
    id !== undefined && id.length > 10
      ? toNumDigit(containerId[10])
      : undefined;

  const containerIdErrors = validateContainerId(
    { owner, productGroup, serial, digit },
    listCodesSet
  );
  const hasErrors = containerIdErrors.size > 0;
  const title = hasErrors
    ? dict.screens.Container.header()
    : `${[...owner, productGroup].join("")}/${serial.join("")}-${digit}`;

  const checkIfDataFilled = () => {
    const checkSeal = seals.every((seal) => isSealValid(seal));

    const checkContainer =
      validateContainerId({ owner, productGroup, serial, digit }, listCodesSet)
        .size === 0 || inspectionType === "seal";
    return checkSeal && checkContainer;
  };

  const setSeal = (index: number) => (seal: SealState) => {
    const newSeals = seals.map((s, i) => {
      if (i == index) return seal;
      return s;
    });
    setSeals(newSeals);
  };

  const onSealAccordionPress = (sealImgUrl: string) => {
    // Use set because if seals are added / removes, we'll keep the right state
    setClosedSeals((prevClosedSeals) => {
      const newClosedSeals = new Set(prevClosedSeals);
      const isSealClosed = newClosedSeals.has(sealImgUrl);
      if (isSealClosed) {
        newClosedSeals.delete(sealImgUrl);
      } else {
        // If the seal is not valid, keep it open
        const seal = seals.find((s) => s.imgUrl === sealImgUrl);
        if (seal && !isSealValid(seal)) return newClosedSeals;

        newClosedSeals.add(sealImgUrl);
      }
      return newClosedSeals;
    });
  };

  useFocusEffect(
    React.useCallback(() => {
      if (viewRef.current) {
        viewRef.current.scrollToEnd({ animated: true });
      }
    }, [])
  );

  return (
    <>
      <ScrollView
        contentContainerStyle={styles.container}
        showsHorizontalScrollIndicator={false}
        ref={viewRef}
      >
        {containerData && inspectionType === "container" && (
          <View style={styles.content}>
            <Accordion
              title={title}
              icon={IconAccordionState.CONTAINER}
              open={containerOpen}
              onPress={() => setContainerOpen(!containerOpen)}
            >
              <ContainerForm
                owner={owner}
                productGroup={productGroup}
                serial={serial}
                digit={digit}
                rawContainerSize={
                  containerSize === undefined ? "" : containerSize
                }
                rawContainerType={
                  containerType === undefined ? "" : containerType
                }
                imgUrl={imgUrl}
                s3Path={s3Path}
                onChangeContainerData={setContainerData}
                containerIdErrors={containerIdErrors}
                sealsCount={countSeals}
                navigation={navigation}
              />
            </Accordion>
          </View>
        )}

        {seals.length > 0 &&
          seals.map((item: SealState, index: number) => (
            <View style={styles.content} key={index}>
              <Accordion
                title={getSealTitle(item).substring(0, 30)}
                icon={IconAccordionState.SEAL}
                open={!closedSeals.has(item.imgUrl)}
                onPress={() => {
                  onSealAccordionPress(item.imgUrl);
                }}
              >
                <SealForm
                  seal={item}
                  onChangeSeal={setSeal(index)}
                  sealIndex={index}
                  key={index}
                  navigation={navigation}
                />
              </Accordion>
            </View>
          ))}
        {checkIfDataFilled() && <View style={{ height: 15 }}></View>}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    backgroundColor: Theme.colors.background,
    marginBottom: 5,
  },
  content: {
    width: "90%",
    marginTop: 15,
  },
});

export default Forms;
