import React, { useState } from 'react';
import { ActivityIndicator, StyleSheet, Keyboard, TouchableOpacity, View } from 'react-native';
import Text from '../../../shared/atoms/Text';
import i18nDictionary from '../../i18n';
import { Theme } from '../../styles';
import Clever from '../../assets/svg/clever-favico-profile.svg';
import Cargo from '../../../shared/svg/cargo';
import { Sizes } from '../../../shared/constants/sizes';
import Input from '../../../shared/molecules/Input';

interface FirstAccessProps {
    submitNewPassword: (password: string, newPassword: string) => void
    loadingButton: boolean
}

const FirstAccess = ({ submitNewPassword, loadingButton }: FirstAccessProps) => {
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    return (
        <View style={styles.container}>
            <View>
                {/* <Clever /> */}
                <Cargo width={200} height={200} />
            </View>

            <Text style={{ fontSize: Sizes.h1, fontWeight: '700', color: Theme.colors.title, marginBottom: 15 }}>{i18nDictionary.cognito.FirstAccess()}</Text>

            <Text style={{ fontSize: Sizes.body2, color: Theme.colors.title, marginBottom: 15 }}>{i18nDictionary.cognito.FirstAccessMessage1()}</Text>
            <Text style={{ fontSize: Sizes.body2, color: Theme.colors.title, marginBottom: 15 }}>{i18nDictionary.cognito.FirstAccessMessage2()}</Text>

            <View style={{ width: "100%" }}>
                <Input
                    onChange={setPassword}
                    value={password}
                    error={false}
                    label={i18nDictionary.cognito.NewPassword()}
                    secureTextEntry={true}
                />
                <Input
                    onChange={setNewPassword}
                    value={newPassword}
                    error={false}
                    label={i18nDictionary.cognito.ConfirmPassword()}
                    secureTextEntry={true}
                    onSubmitEditing={() => {
                        Keyboard.dismiss
                        submitNewPassword(password, newPassword)
                    }}
                />
            </View>

            <View style={{ marginVertical: 10 }}>
                <TouchableOpacity
                    onPress={() => submitNewPassword(password, newPassword)}
                    style={styles.loginButton}>
                    {loadingButton ? (
                        <ActivityIndicator color={Theme.colors.primary} size={25} />
                    ) : (
                        <Text style={{ fontSize: Sizes.h3, fontWeight: '700', color: Theme.colors.primary }}>{i18nDictionary.cognito.Submit()}</Text>
                    )}
                </TouchableOpacity>
            </View>
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        width: "100%",
        justifyContent: 'center',
        alignItems: 'center',
        padding: Sizes.paddingM
    },
    input: {
        borderColor: "gray",
        backgroundColor: "#FFF",
        width: "100%",
        borderWidth: 1,
        borderRadius: 10,
        padding: 10,
    },
    loginButton: {
        borderRadius: Sizes.borderRadiusM,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.colors.title,
        padding: Sizes.paddingM,
        margin: 10,
    }
});

export default FirstAccess;