import React, { useEffect } from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";
import * as ImageManipulator from "expo-image-manipulator";

import { useAppDispatch } from "../store/hooks";
import {
  createInspection,
  executeContainerOcr,
  executeContainerUpdateOcr,
  selectError,
} from "../store/inspectionSlice";

import Loading from "../components/Loading";
import { InspectionState, PHOTO_FORMATS, RootStackScreenProps } from "../types";
import i18nDictionary from "../i18n";
import { Auth, DataStore } from "aws-amplify";
import configAws from "../aws-exports.js";
// import { Inspection } from '../models'
// import { InspectionState, InspectionType } from '../types'
import { saveImage } from "../services/ocr";
import { isLeft } from "fp-ts/lib/These";
import Toast from "react-native-toast-message";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "sentry-expo";
import { Inspection } from "../models";
import { getS3PhotoUrl } from "../aws/storage";
// import * as Sentry from 'sentry-expo'

Auth.configure(configAws);

const LoadingContainer = ({
  route,
  navigation,
}: RootStackScreenProps<"LoadingContainer">) => {
  const { uri, update } = route.params;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const sendDataToOcr = async () => {
      try {
        const manipResult = await ImageManipulator.manipulateAsync(uri, [
          { resize: { height: 1024 } },
        ]);
        const response = await fetch(manipResult.uri);
        const blob = await response.blob();

        if (update) {
          // dispatch(
          //   executeContainerUpdateOcr({
          //     containerUuid: uuidv4(),
          //     photoFormat: PHOTO_FORMATS.find((format) => format === manipResult.uri.split("/")[1].split(";")[0]) || PHOTO_FORMATS[0],
          //     containerPhotoBlob: blob,
          //     photoHeight: manipResult.height,
          //     photoWidth: manipResult.width,
          //   })
          // )
          //   .unwrap()
          //   .then((data: any) => {
          //     if (data?._tag === "Right") {
          //       navigation.pop(2)
          //       navigation.navigate("CurrentInspection")
          //     } else {
          //       navigation.pop(2)
          //       navigation.navigate("CurrentInspection")
          //       Toast.show({
          //         type: "warning",
          //         text1: i18nDictionary.scr eens.Alerts.noUpdatesTitle(),
          //         text2: i18nDictionary.screens.Inspections.noContainerFoundMessage(),
          //       })
          //     }
          //   })
        } else {
          dispatch(
            executeContainerOcr({
              containerUuid: uuidv4(),
              photoFormat:
                PHOTO_FORMATS.find(
                  (format) =>
                    format === manipResult.uri.split("/")[1].split(";")[0]
                ) || PHOTO_FORMATS[0],
              containerPhotoBlob: blob,
              photoHeight: manipResult.height,
              photoWidth: manipResult.width,
            })
          )
            .unwrap()
            .then((data: any) => {
              navigation.pop(2);
              navigation.navigate("CurrentInspection", { type: "container" });
            });
        }
      } catch (e) {
        console.log(e);
      }
    };

    sendDataToOcr();
  }, []);

  return (
    <>
      <Loading text={i18nDictionary.screens.Inspections.loadingContainer()} />
    </>
  );
};

export default LoadingContainer;
