import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import AsyncStorage from "@react-native-async-storage/async-storage";
import { RootState } from "."
import { CognitoUser } from 'amazon-cognito-identity-js';

// FIXME update to chage any type in user

const initialState: { user: any, cognitoUser: any, loading: boolean, firstLogin: boolean, timestamp: number, error: string } = {
    user: null,
    cognitoUser: null,
    loading: false,
    firstLogin: false,
    timestamp: 0,
    error: ""
}

export const fetchUserAuthData = createAsyncThunk(
    "authData/fetch",
    async (_, { rejectWithValue }) => {
        try {
            const authData = await AsyncStorage.getItem('@GOCLEVER_VOLUME/AUTH')
            if (authData === null) {
                return authData
            } else {
                return JSON.parse(authData)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const setUserAuthData = createAsyncThunk(
    "authData/save",
    async (data: { firstLogin?: boolean, authData?: any, cognito?: any, type: string }, { rejectWithValue }) => {
        try {
            //await AsyncStorage.setItem('@GOCLEVER_VOLUME/AUTH', JSON.stringify(data.))
            return data
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const removeUserAuthData = createAsyncThunk(
    "authData/remove",
    async (_, { rejectWithValue }) => {
        try {
            await AsyncStorage.removeItem('@GOCLEVER_VOLUME/AUTH')
            return
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const authSlice = createSlice({
    name: "authData",
    initialState,
    reducers: {
        userLogin: (state, action: PayloadAction<{ firstLogin?: boolean, authData?: any, cognito?: any, type: string }>) => {
            const { payload: { firstLogin, authData, cognito, type } } = action;
            switch (type) {
                case "pending":
                    state.loading = true
                    break

                case "success":
                    if (authData !== null) {
                        state.loading = false
                        state.user = authData
                        state.cognitoUser = cognito
                        state.firstLogin = firstLogin !== undefined ? firstLogin : false
                        state.timestamp = Date.now()
                    }
                    break

                case "reject":
                    state.loading = false
                    state.user = null
                    state.cognitoUser = null
                    state.firstLogin = false
                    state.timestamp = 0
                    break

                default:
                    state.loading = false
                    state.user = null
                    state.cognitoUser = null
                    state.firstLogin = false
                    state.timestamp = 0
                    break
            }

        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserAuthData.fulfilled, (state, action) => {
            const { payload: { firstLogin, authData, cognito, type } } = action
            if (authData !== null) {
                state.loading = false
                state.user = authData
                state.cognitoUser = cognito
                state.firstLogin = firstLogin !== undefined ? firstLogin : false
                state.timestamp = Date.now()
            }
        }),
            builder.addCase(fetchUserAuthData.rejected, (state, action) => {
                state.user = null
                state.timestamp = 0
            }),
            builder.addCase(setUserAuthData.pending, (state, action) => {
                state.loading = true
            }),
            builder.addCase(setUserAuthData.fulfilled, (state, action) => {
                const { payload: { firstLogin, authData, cognito, type } } = action
                if (authData !== null) {
                    state.loading = false
                    state.user = authData
                    state.cognitoUser = cognito
                    state.firstLogin = firstLogin !== undefined ? firstLogin : false
                    state.timestamp = Date.now()
                }
            }),
            builder.addCase(setUserAuthData.rejected, (state, action) => {
                state.loading = false
                state.user = null
                state.timestamp = 0
            }),
            builder.addCase(removeUserAuthData.fulfilled, (state, action) => {
                state.user = null
                state.timestamp = 0
            }),
            builder.addCase(removeUserAuthData.rejected, (state, action) => {
                state.user = null
                state.timestamp = 0
            })
    },
})

export const { userLogin } = authSlice.actions

export const selectAuth = (id?: string) => (state: RootState) => state.auth
export const selectUser = (id?: string) => (state: RootState) => state.auth.user
export const selectCognitoUser = (id?: string) => (state: RootState) => state.auth.cognitoUser
export const selectLoading = (id?: string) => (state: RootState) => state.auth.loading
export const selectFirstLogin = (id?: string) => (state: RootState) => state.auth.firstLogin