import { Alert, Platform } from "react-native";
import i18nDictionary from "../i18n";
import * as Updates from "expo-updates";
import { UpdateCheckResult } from "expo-updates/build/Updates.types";
// import * as Sentry from 'sentry-expo'

export const checkUpdates = async (fallback?: () => void) => {
  if (Platform.OS === "web") return;
  const checkUpdate: UpdateCheckResult = await Updates.checkForUpdateAsync();
  if (checkUpdate.isAvailable) {
    Alert.alert(
      i18nDictionary.screens.Alerts.checkUpdateTitle(),
      i18nDictionary.screens.Alerts.checkUpdateText(),
      [
        {
          text: i18nDictionary.screens.Alerts.checkUpdateButton(),
          onPress: async () => {
            try {
              const result = await Updates.fetchUpdateAsync();
              if (result.isNew) await Updates.reloadAsync();
            } catch (error) {
              // Platform.OS === "web" ? Sentry.Browser.captureException(error) : Sentry.Native.captureException(error)
              Alert.alert(
                i18nDictionary.screens.Alerts.errorAtReloadTitle(),
                i18nDictionary.screens.Alerts.errorAtReloadText(),
                [
                  {
                    text: i18nDictionary.screens.Alerts.errorAtReloadButton(),
                    onPress: async () => {},
                  },
                ],
                {
                  cancelable: true,
                }
              );
            }
          },
        },
      ],
      {
        cancelable: false,
      }
    );
  } else if (fallback !== undefined) {
    Alert.alert(
      i18nDictionary.screens.Alerts.noUpdatesTitle(),
      i18nDictionary.screens.Alerts.noUpdatesText(),
      [
        {
          text: i18nDictionary.screens.Alerts.noUpdatesButton(),
          onPress: () => {
            fallback();
          },
        },
      ],
      {
        cancelable: true,
      }
    );
  }
};
