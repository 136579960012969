import { Dictionary } from "../index";

const ca: Dictionary = {
  screens: {
    NotFound: {
      message: "Pàgina no trobada",
    },
    Main: {
      welcome: "Benvingut a l'aplicació d'inspecció!",
      newInspection: "Començar nova inspecció",
      inspections: "Inspeccions",
      checkNewUpdates: "Comprovació d'actualitzacions",
      pastInspections: "Inspeccions anteriors",
      version: "Versió",
      ongoing: "En progrés",
      scheduled: "Programat",
      noScheduledInspections:
        "No hi ha inspeccions programades o reconegudes en aquest moment",
      noOngoingInspections: "No hi ha inspeccions en progrés en aquest moment",
      createdAt: "Finalitzat",
    },
    Alerts: {
      checkUpdateTitle: "Nova actualització!",
      checkUpdateText:
        "Hi ha una nova actualització disponible. Actualitza ara per veure-la!",
      checkUpdateButton: "Actualitzar",
      noUpdatesTitle: "Informació",
      noUpdatesText: "No hi ha actualitzacions noves",
      noUpdatesButton: "Tancar",
      errorAtReloadTitle: "Error",
      errorAtReloadText:
        "No s'ha pogut tornar a carregar l'aplicació, torna-ho a provar més tard",
      errorAtReloadButton: "Tancar",
      deleteSealTitle: "Confirmació",
      deleteSealText: "Esteu segur que voleu suprimir aquest element?",
      deleteSealButton: "Confirmeu",
      Confirm: "Confirmar",
      Cancel: "Cancel·lar",
    },
    Inspections: {
      hideDetails: "Ocultar detalls",
      showDetails: "Mostrar detalls",
      scale: "Escala",
      category: "Categoria",
      container: "Foto",
      device: "Dispositiu",
      tare: "Tara",
      delete: "Suprimir",
      ongoing: "En progrés",
      completed: "Completat",
      incomplete: "incompleta",
      submit: "Enviar",
      dashboard: "Panell de control",
      newInspection: "Nou inspecció",
      successMessageInspectionSaved: "Inspecció guardada amb èxit",
      errorMessageSubmit:
        "Error en enviar les dades de mesura sol·licitades, torna-ho a provar",
      errorMessageUnknownDevice: "Dispositiu desconegut",
      errorMessageUnknownContainer: "Contenidor desconegut",
      errorMessageUnboundTimeout: "Temps d'espera caducat",
      errorMessageUnauthorizedRequest: "No autoritzat",
      errorMessageDeviceNotReady:
        "El servidor no està llest per iniciar un nou mesurament de volum",
      errorMessageVolumeComputation: "Error de càlcul de volum",
      errorMessageUnexpected: "Un problema al servidor",
      errorMessageUnknownUsecase: "Cas d'ús desconegut",
      errorMessageUnboundDelay: "Retard a la resposta del servidor",
      errorMessageInvalidContentType: "Format de contingut no vàlid",
      newSeal: "Nou precinte",
      newContainer: "Nou contenidor",
      newPhoto: "Nova foto",
      instructions: "Instruccions",
      takePictureAgain: "Realitzar novament",
      containerIncomplete: "Dades incompletes",
      incompleteSealTitle: "Precinte incomplet",
      incompleteSealText: "Confirmes que el contenidor no té precintes?",
      finishInspectionTitle: "Finalitzar la inspecció",
      finishInspectionText:
        "Estàs segur que vols finalitzar aquesta inspecció?",
      deleteInspectionTitle: "Finalitza la inspecció",
      deleteInspectionText:
        "Estàs segur que vols finalitzar aquesta inspecció?",
      finishedInspectionTitle: "Èxit",
      finishedInspectionText: "Inspecció finalitzada amb èxit!",
      cancelInspectionTitle: "Cancel·lar inspecció",
      cancelInspectionText: "Confirmes que vols cancel·lar la seva inspecció?",
      incompleteDataTitle: "Dades incompletes",
      incompleteDataText:
        "Per finalitzar la inspecció, heu de completar tots els camps requerits",
      gpsPermissionsTitle: "Alerta",
      gpsPermissionsMessage:
        "Per continuar el registre és necessari confirmar el permís de GPS, en cas contrari, el procés serà cancel·lat",
      gpsPermissionsCancel: "No acceptar i tornar",
      gpsPermissionsConfirm: "Permetre",
      gpsNoPermissionAlertTime: "Permís no concedit",
      gpsNoPermissionAlertMessage:
        "Per continuar el registre és necessari confirmar el permís de GPS, en cas contrari, el procés serà cancel·lat",
      gpsNoPermissionAlertCancel: "Denegar",
      gpsNoPermissionAlertRequest: "Sol·licitar",
      error: "Error",
      close: "Tancar",
      tryAgain: "Fer de nou",
      noContainerFoundMessage:
        "No s'ha trobat la identificació del contenidor, completa la identificació del contenidor manualment",
      noSealFoundMessage:
        "No s'ha trobat l'identificador del precinte. Vols intentar-ho una altra vegada?",
      loading: "Carregant",
      loadingContainer: "Estem digitalitzant el contenidor",
      loadingSeal: "Estem llegint el precinte",
      instructionContainerPhoto: "Realitza una foto al contenidor",
      containerTypes: {
        container: "Posicionat",
        opening: "Obertura",
        hallway: "Passadís",
        extracted_merch: "Mercaderia extreta",
        label_eq: "Etiqueta EQ",
        closing: "Tancament",
        others: "Altres",
      },
    },
    Seal: {
      header: "Precinte",
      labelSealId: "ID de precinte",
      labelCategory: "Categoria",
      labelPosition: "El precinte és íntegre?",
      labelLocation: "Localització",
      labelOwner: "Propietari",
      labelSealViolationDetails: "Observacions de la violació",
      defaultViolatedReason: "El precinte té una violació",
      newPhoto: "Afegir foto",
      deletePhotoTitle: "Substituir imatge",
      deletePhotoMessage: "Segur que vols modificar la fotografia realitzada?",
      cancel: "Cancel·la",
      confirm: "Confirma",
      sealOnLeftDoor: "Precinte a la porta esquerra",
      instructionSealPhoto: "Realitza una foto al precinte",
      missingSealSerialIdWarning:
        "Introduïu l'identificador de sèrie del segell",
      owners: {
        other: "Altre",
      },
      categories: {
        empty: "",
        orginal: "Original",
        vessel: "Naviera",
        terminal: "Terminal",
        inspectionTeam: "EQ",
        inspectionCertificate: "Inspecció",
        other: "Altre",
      },
      sizes: {
        empty: "",
        "22": "22",
        "25": "25",
        "42": "42",
        "45": "45",
        L2: "L2",
        L5: "L5",
        M2: "M2",
        M5: "M5",
      },
    },
    Container: {
      ownerPrefixErrorMessage: "El prefix ingressat no és vàlid",
      checkDigitNoneErrorMessage:
        "El dígit no existeix per a les dades ingressades",
      emptyOwnerPrefixErrorMessage: "El camp del prefix és obligatori",
      emptySerialNumberErrorMessage:
        "El camp del número de sèrie és obligatori",
      emptyDigitErrorMessage: "El camp del dígit és obligatori",
      incompleteInput: "Les dades del contenidor estan incomplets",
      checkDigitErrorMessage: "Dígit incompatible amb les dades introduïdes",
      header: "Contenidor",
      labelOwnerPrefix: "Prefix",
      labelSerialNumber: "Matrícula",
      labelDigit: "Dígit",
      labelContainerId: "ID del contenidor",
      labelContainerSize: "Mida",
      labelContainerType: "Tipus",
      newPhoto: "Afegir foto",
      deletePhotoTitle: "Substituir imatge",
      deletePhotoMessage: "Segur que vols modificar la fotografia realitzada?",
      deletePhotoCancel: "Cancel·la",
      deletePhotoConfirm: "Confirma",
      instructionContainerPhoto: "Realiza una foto al contenedor",
      types: {
        empty: "",
        G1: "G1",
        R1: "R1",
        U1: "U1",
        P1: "P1",
        T1: "T1",
      },
    },
    LastInspections: {
      photo: "Foto",
      container: "Contenidor",
      service: "Servei",
      petitioner: "Peticionari",
      level: "Nivell",
      dock: "Moll",
      expedient: "Expedient",
    },
  },
  cognito: {
    SignIn: "Inicia sessió",
    SignUp: "Registra't!",
    SignUpText: "No tens un compte?",
    SignUpTitle: "Creeu un compte per fer les vostres inspeccions!",
    SendCode: "Envia el codi de nou.",
    SignOut: "Desconnectar",
    Username: "Email",
    Password: "Contrasenya",
    Email: "Email",
    Name: "Nom",
    PhoneNumber: "Telèfon",
    Confirm: "Confirma",
    Cancel: "Cancel·la",
    Submit: "Enviar",
    Skip: "Ometre",
    Verify: "Verificar",
    Code: "Codi",
    Main: "Realitzar inspecció",
    Operated: "Operat per:",
    Anonymous: "Accés anònim",
    IncorrectLogin: "Nom d'usuari o contrasenya incorrecte",
    ChangePassword: "Canvi de contrasenya",
    OldPassword: "Contrasenya anterior",
    NewPassword: "Nova contrasenya",
    ConfirmPassword: "Repeteix la contrasenya",
    ForgotPassword: "He oblidat la contrasenya",
    EmailConfirmCode: "Canvi de contrasenya",
    ConfirmationCode: "Codi de confirmació",
    FirstAccess: "Primer accés",
    FirstAccessMessage1: "Si us plau, actualitza la contrasenya",
    FirstAccessMessage2: "Introdueix una contrasenya de 8 caràcters",
    SuccessMessageChangePassword: "Contrasenya modificada amb èxit",
    SuccessMessageSubmit: "Mesura enviada amb èxit!",
    SuccessMessageTara: "Tara enviada amb èxit!",
    SuccessMessageLogin: "Sessió iniciada amb èxit!",
    ErrorMessageDifferentPasswords:
      "La confirmació de la contrasenya no coincideix",
    ErrorGeneralMessage: "Ha hagut un error, si us plau intenta-ho de nou",
    ErrorFieldsEmpty: "Heu d'omplir els camps obligatoris",
    ForcePwdChangeException:
      "Necessites fer el primer accés. Si t'has oblidat la primera contrasenya, posat en contacte amb l'àrea PIF",
    NotAuthorizedException: "Contrasenya incorrecta, torna-ho a provar",
    UserNotFoundException:
      "No s'ha trobat la combinació de número d'usuari/ID d'usuari",
    CodeMismatchException:
      "S'ha proporcionat un codi de verificació no vàlid, torneu-ho a provar",
    InvalidPasswordException:
      "La contrasenya no compleix la política: la contrasenya no és prou llarga",
    InvalidParameterException: "Camp de nom d'usuari i/o contrasenya buit",
    PasswordResetRequiredException:
      "Restabliment de contrasenya requerit per l'usuari",
    NotAuthorizedExceptionTitle: "Error: No es permet",
    UserNotFoundExceptionTitle: "Error: Usuari/ID no trobat",
    CodeMismatchExceptionTitle: "Error: Codi de verificació",
    InvalidPasswordExceptionTitle: "Error: Contrasenya",
    InvalidParameterExceptionTitle: "Error: Camp invàlid",
    PasswordResetRequiredExceptionTitle: "Error: Restabliment de contrasenya",
    CodeDeliveryFailureException:
      "El codi de verificació no s'ha pogut enviar amb èxit.",
    ForbiddenException:
      "Sol·licitud no permesa, contacti amb el suport per obtenir més informació.",
    InternalErrorException:
      "S'ha produït un error intern, contacti amb el suport per obtenir més informació.",
    InvalidLambdaResponseException:
      "S'ha produït un error intern, contacti amb el suport per obtenir més informació.",
    InvalidSmsRoleAccessPolicyException:
      "S'ha produït un error intern, contacti amb el suport per obtenir més informació.",
    InvalidSmsRoleTrustRelationshipException:
      "S'ha produït un error intern, contacti amb el suport per obtenir més informació.",
    ResourceNotFoundException:
      "El nostre servei no pot trobar el recurs sol·licitat",
    TooManyRequestsException:
      "Massa peticions, esperi un temps i torni a fer la sol·licitud",
    UnexpectedLambdaException:
      "S'ha produït un error intern, contacti amb el suport per obtenir més informació.",
    UserLambdaValidationException:
      "S'ha produït un error intern, contacti amb el suport per obtenir més informació.",
    UsernameExistsException:
      "L'adreça de correu electrònic ja existeix en el nostre pool d'usuaris, escolli una altra!",
    EmptyFieldEmail: "Introdueixi la seva adreça de correu electrònic",
    EmptyFieldName: "Introdueixi el seu nom",
    EmptyFieldPassword: "Introdueixi una contrasenya amb almenys 8 caràcters",
    EmptyFieldPasswordConfirm:
      "Introdueixi la contrasenya igual que en el camp anterior",
  },
};

export default ca;
