import {
  ALPHA,
  ALPHABET_SET,
  ContainerProductGroup,
  ContainerSize,
  ContainerType,
  CONTAINER_PRODUCT_GROUP_SET,
  CONTAINER_SIZES_SET,
  CONTAINER_TYPES_SET,
  NUM,
  NUMBER_SET,
  SealLocation,
  SEAL_LOCATIONS_SET,
} from "../types"

export const isString = (v: any): boolean => Object.prototype.toString.call(v) === "[object String]"

export const convert = <T>(set: Set<any>, rawValue?: string): T | undefined => {
  if (rawValue === undefined || !isString(rawValue)) return undefined
  const value = rawValue.toUpperCase()
  return set.has(value) ? (value as unknown as T) : undefined
}

export const convertInt = <T>(set: Set<any>, rawValue?: string | number): T | undefined => {
  if (rawValue === undefined || (!isString(rawValue) && !Number.isInteger(rawValue))) return undefined

  const value = rawValue == 0 ? 0 : parseInt(rawValue.toString())
  return set.has(value) ? (value as unknown as T) : undefined
}

export const toAlphaChar = (v?: string) => convert<ALPHA>(ALPHABET_SET, v)
export const toProductGroupChar = (v?: string) => convert<ContainerProductGroup>(CONTAINER_PRODUCT_GROUP_SET, v)
export const toNumDigit = (v?: string | number) => convertInt<NUM>(NUMBER_SET, v)
export const toSizeString = (v?: string) => convert<ContainerSize>(CONTAINER_SIZES_SET, v)
export const toTypeString = (v?: string) => convert<ContainerType>(CONTAINER_TYPES_SET, v)
export const toLocationString = (v?: string) => convert<SealLocation>(SEAL_LOCATIONS_SET, v)
