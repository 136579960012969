import React from 'react'
import Svg, { Path } from 'react-native-svg';
const Times = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 10 10" fill="none" >
      <Path d="M2.17201 9.24299C1.98437 9.43063 1.72988 9.53604 1.46451 9.53604C1.19915 9.53604 0.944655 9.43063 0.757015 9.24299C0.569374 9.05535 0.463959 8.80085 0.463959 8.53549C0.463959 8.40409 0.489839 8.27398 0.540122 8.15259C0.590404 8.0312 0.664105 7.9209 0.757015 7.82799L7.82801 0.757985C8.01552 0.570345 8.26989 0.464877 8.53516 0.464783C8.80043 0.464689 9.05487 0.569977 9.24252 0.757485C9.43016 0.944994 9.53562 1.19936 9.53572 1.46463C9.53581 1.7299 9.43052 1.98434 9.24302 2.17199L2.17201 9.24199V9.24299Z" fill={props.color} />
      <Path d="M0.757015 2.17198C0.664105 2.07907 0.590404 1.96877 0.540122 1.84738C0.489839 1.72599 0.463959 1.59588 0.463959 1.46448C0.463959 1.33309 0.489839 1.20298 0.540122 1.08159C0.590404 0.960195 0.664105 0.849894 0.757015 0.756984C0.849925 0.664074 0.960225 0.590374 1.08162 0.540091C1.20301 0.489808 1.33312 0.463928 1.46451 0.463928C1.59591 0.463928 1.72602 0.489808 1.84741 0.540091C1.9688 0.590374 2.0791 0.664074 2.17201 0.756984L9.24201 7.82798C9.42965 8.01549 9.53512 8.26986 9.53522 8.53513C9.53531 8.8004 9.43002 9.05484 9.24252 9.24249C9.05501 9.43013 8.80064 9.53559 8.53537 9.53569C8.2701 9.53578 8.01566 9.43049 7.82801 9.24298L0.758015 2.17198H0.757015Z" fill={props.color} />
    </Svg>
  )
}

export default Times; 