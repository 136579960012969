import React, { useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { Camera } from "expo-camera";

import i18nDictionary from "../i18n";
import { Theme } from "../styles";
import { RootStackScreenProps } from "../types";
import CameraUI from "../../shared/templates/CameraUi";
import { Sizes } from "../../shared/constants/sizes";
import { useFocusEffect } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { selectCameraRatio } from "../store/cameraSlice";

const CameraContainer = ({
  navigation,
  route,
}: RootStackScreenProps<"CameraContainer">) => {
  const { update } = route.params;
  useFocusEffect(() => {
    setShouldHide(false);
    return () => {
      setShouldHide(true);
    };
  });

  const [shouldHide, setShouldHide] = useState(false);
  const [isPhotoTaken, setIsPhotoTaken] = useState(true);

  const cameraRatio = useSelector(selectCameraRatio());

  const camRef = useRef<Camera | any>();

  const takePicture = async () => {
    if (camRef) {
      setIsPhotoTaken(false);
      try {
        const picture = await camRef.current?.takePictureAsync({
          exif: true,
          skipProcessing: true,
        });
        if (picture !== undefined) {
          navigation.navigate("LoadingContainer", { uri: picture.uri, update });
        }
        setIsPhotoTaken(true);
      } catch (error) {
        setIsPhotoTaken(true);
        console.log(error);
      }
    }
  };

  return (
    <>
      {shouldHide ? null : (
        <Camera
          ref={camRef}
          ratio={cameraRatio.ratio}
          poster="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBxISEhUSEhIVFRUXFRUVFxcVFRcVFRUVFRUWFxUXFRUYHSggGB0lHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDQ0NDw0NDysZFRkrNy0rKysrLSstNzc3Ny4rKy0tNy0rLTctLSsrKy0rKysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAIBABAQEAAQQCAwAAAAAAAAAAAAERAhIhQWEx8FGRof/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABsRAQACAgMAAAAAAAAAAAAAAAAREgFhAlGB/9oADAMBAAIRAxEAPwDiFMCAqBAFMSAtTCgLKgoJSFAKsqaQC0wp4BFACqlhQEq1JQVMACkgALEIAq6oIVKQAi1IBBb+UoFWwQFKhoGJFlIAFIBhAATVAKFMBKuBoERZSAgYugCUBc9gCnYioIqACNYlawGaBgLaJADFS0kAIVYAkXUAsEalBKIoBi1KBIaRAApoKkF0GusYBq2e2sSqkEVMVMEIsSKAggC1YUEWXEgBoUgFIEAIQiipCEQXTEoCBQAq6gKhaugg1tASriU0AXUBQxIBaVc7koJFqYWAqFICpotgBEpoLiCwGWsQAtSrQDUa9MgoQ8gaikgINb6/oBCphAXEKAUi4AayuFA0CgYEIA1GTALBbEAoJgNRmUoClTFwDUqpgKGIBougHUIAspCLgJYcVQFNSQ0AACReSUAAgAYQDuaACoAAmAoGggtARU1QKi0A0TsLGxTRYgIWALE9EWggICqkpfQFCmAqFAVFiQAtTADFKgLiVYUBAgLiLgCVYlXQNAEWoEFXEhpgLqFWglgUwBaVKAaJAUKTuBoYSgSd0UwDkJQFwhQApEBUUBBSTQhNG+hUlunJgoKwuJqpoKmrIgCLSAUhFoJSGFA1LFlIByDSAkXBMBbCUAA5AIVUBUNAXT4QBrqob91Rq22cWlTRlay1qYCxMWxkFq3ilKBC1QEAoEpoSAE1agIsFBlUgCwqQoKWJigmrTSAlFABBRVKckVNIupKIsqaigvKIGAAtoCaTsSAaQpACmrAL+UtCwEUhARS0oCKgKgtoFRSAg1sF9RDkaUUSKSgki0ggCLQChaAYqQDCFAKLiQA0QDVhSUCxFqAtQUC1FQAAF0ADAwA0gAaUi6DOroAL1MytAkMNAUTUgLYqEAIigmrCgItCAJq6WgUQA1SUwF0ZBZbjPgFF4oCIvBOPwAFKoCLVAY5AAX5XkoDK+AXILfv7BBmLVBcLySKKjCAg1fhIAFWADQAP//Z"
          style={[styles.camera, { marginVertical: cameraRatio.imagePadding }]}
        />
      )}
      <CameraUI
        onPress={takePicture}
        cancelPhoto={() => {
          navigation.pop();
        }}
        title={i18nDictionary.screens.Inspections.instructions()}
        titleProps={{
          style: {
            fontSize: Sizes.h3,
            fontWeight: "700",
            color: Theme.colors.title,
            marginBottom: Sizes.marginXS,
          },
        }}
        loading={!isPhotoTaken}
        instructionsText={i18nDictionary.screens.Inspections.instructionContainerPhoto()}
        instructionsTextProps={{
          style: {
            fontSize: Sizes.body3,
            fontWeight: "400",
            color: Theme.colors.title,
          },
        }}
        buttonProps={{
          style: {
            backgroundColor: Theme.colors.primary,
            width: "60%",
            opacity: 1,
            padding: Sizes.paddingM,
            borderRadius: Sizes.borderRadiusM,
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
          },
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    margin: 20,
    width: "100%",
    backgroundColor: "black",
    opacity: 0.5,
  },
  button: {
    flex: 0.1,
    alignSelf: "flex-end",
    alignItems: "center",
  },
  text: {
    fontSize: 18,
    color: "white",
  },
  cameraIcon: {
    backgroundColor: Theme.colors.primary,
    width: "50%",
    opacity: 1,
    padding: Sizes.paddingS,
    borderRadius: Sizes.borderRadiusM,
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
  },
  zoomNotSelected: {
    margin: 5,
    padding: 15,
    width: "100%",
    alignItems: "center",
    borderColor: "#fff",
    borderWidth: 1,
    borderRadius: 50,
  },
  zoomSelected: {
    margin: 5,
    padding: 15,
    width: "100%",
    alignItems: "center",
    borderColor: "#fff",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderRadius: 50,
  },
});

export default CameraContainer;
