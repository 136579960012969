export const Theme = {
  colors: {
    primary: "#06f",
    primary_lighter: "#7EC8E3",
    primary_light: "#4D94FF",
    primary_darker: "#050A30",
    primary_dark: "#003380",

    secondary: "#767676",
    secondary_light: "#408cff",

    success: "#12A455",
    success_light: "#75efac",

    error: "#E83F5b",
    error_light: "#ee768a",

    warning: "#F5A000",

    border: "#B5B5B5",

    title: "#fff",
    text: "#e7e3dee4",
    text_dark: "#2B2B2B",
    text_black: "#000",
    background: "#F1F1F1",
    backgroundLight: "#F7F7F7",
  },
};
