import React, { useEffect } from "react"
import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View, Platform } from "react-native"

import {
  NavigationContainer,
  createNavigationContainerRef,
  NavigationAction,
  CommonActions,
  DrawerNavigationState,
  ParamListBase,
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { createDrawerNavigator, DrawerItemList } from "@react-navigation/drawer"

import { Auth, AuthModeStrategyType, DataStore } from "aws-amplify"
import { Amplify } from "@aws-amplify/core"
import awsconfig from "./aws-exports.js"
import { userLogin } from "./store/authSlice"
import * as Updates from "expo-updates"
import * as Clipboard from "expo-clipboard"

import Main from "./pages/Main"
import AuthFlow from "./pages/Auth"
import NotFound from "./pages/404"
import CurrentInspection from "./pages/Inspection"
import CameraContainer from "./pages/CameraContainer"
import LoadingContainer from "./pages/LoadingContainer"
import CameraSeal from "./pages/CameraSeal"
import LoadingSeal from "./pages/LoadingSeal"
// import CancelInspection from './components/Modals/CancelInspection'
// import FinishInspection from './components/Modals/FinishInspection'
// import FinishWithoutSeals from './components/Modals/FinishWithoutSeals'
// import OcrContainerError from './components/Modals/OcrContainerError'
// import OcrSealError from './components/Modals/OcrSealError'
import { navigationRef } from "./navigation/RootNavigation"
import { RootDrawerParamList, RootStackParamList } from "./types"

import i18nDictionary from "./i18n"
import SignOut from "../shared/svg/signout"
import { Theme } from "./styles"
import { useAppDispatch } from "./store/hooks"
import { checkUpdates } from "./services/updates"
import Clip from "../shared/svg/clip"
import CheckUpdate from "../shared/svg/check_update"
// import LoadingNewInspection from './pages/Loading/LoadingNewInspection';
import { fetchCameraRatio } from "./store/cameraSlice"
import { DrawerItemProps } from "react-native-paper"
import { DrawerNavigationHelpers, DrawerDescriptorMap } from "@react-navigation/drawer/lib/typescript/src/types"
import ChangePassword from "./pages/ChangePassword"
import ForgotPassword from "./pages/ForgotPassword"
import SignUp from "./pages/SignUp"
import { Sizes } from "../shared/constants/sizes"
import { fetchOwnerCodesList } from "./store/ownerCodeSlice"
// import PastInspections from './pages/PastInspections';

const Stack = createNativeStackNavigator<RootStackParamList>()
const Drawer = createDrawerNavigator<RootDrawerParamList>()
Auth.configure(awsconfig)

// const linking = {
//   prefixes: [],
//   config: {
//     screens: {
//       NotFound: "*",
//       Main: "main",
//       Auth: "auth",
//       Inspection: 'inspection/:id'
//     }
//   },
// };
// FIXME Linking is returning error when refresh page with 403 code, commenting it for search solutions

export default function App() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCameraRatio())
    dispatch(fetchOwnerCodesList())
  }, [])

  const DrawerWithLogoutButton = (
    props: JSX.IntrinsicAttributes & {
      state: DrawerNavigationState<ParamListBase>
      navigation: DrawerNavigationHelpers
      descriptors: DrawerDescriptorMap
    }
  ) => (
    <ScrollView contentContainerStyle={{ flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
      <SafeAreaView>
        <DrawerItemList {...props} />
      </SafeAreaView>
      <View style={styles.bottomDrawer}>
        <TouchableOpacity
          onPress={async () => {
            await Clipboard.setStringAsync("version: " + Updates.runtimeVersion + ", uuid:" + Updates.updateId)
          }}
          style={styles.item}
        >
          <View style={styles.iconContainer}>
            <Clip width={25} height={25} color={Theme.colors.primary} />
          </View>
          <View>
            <View style={styles.item}>
              <Text style={styles.labelVersion}>{i18nDictionary.screens.Main.version()}: </Text>
              <Text style={styles.labelVersionValue}>{Updates.runtimeVersion}</Text>
            </View>
            <Text style={styles.labeluuidValue}>{Updates.updateId}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            Platform.OS !== "web" && checkUpdates(props.navigation.closeDrawer)
          }}
          style={styles.item}
        >
          <View style={styles.iconContainer}>
            <CheckUpdate width={25} height={25} color={Theme.colors.primary} />
          </View>
          <Text style={styles.label}>{i18nDictionary.screens.Main.checkNewUpdates()}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={async () => {
            try {
              Auth.signOut()
              props.navigation.closeDrawer()
              dispatch(userLogin({ type: "pending" }))
              setTimeout(() => {
                props.navigation.navigate("Auth")
                Platform.OS !== "web" && checkUpdates()
              }, 1000)
            } catch (e) {
              props.navigation.closeDrawer()
              dispatch(userLogin({ type: "reject" }))
            }
          }}
        >
          <View style={styles.item}>
            <View style={styles.iconContainer}>
              <SignOut width={25} height={25} color={Theme.colors.primary} />
            </View>
            <Text style={styles.label}>{i18nDictionary.cognito.SignOut()}</Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  )

  function Home() {
    return (
      <Drawer.Navigator initialRouteName="Main" drawerContent={(props) => <DrawerWithLogoutButton {...props} />}>
        <Drawer.Screen name="Main" component={Main} options={{ headerShown: false, title: i18nDictionary.cognito.Main() }} />
        <Drawer.Screen
          name="ChangePassword"
          component={ChangePassword}
          options={{ headerShown: false, title: i18nDictionary.cognito.ChangePassword() }}
        />
        {/* <Drawer.Screen name="PastInspections" component={LastInspections} options={{ headerShown: false, title: i18nDictionary.screens.Main.pastInspections()}} /> */}
      </Drawer.Navigator>
    )
  }

  return (
    // <NavigationContainer ref={navigationRef} linking={linking}>
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator initialRouteName="Auth">
        <Stack.Screen
          name="Auth"
          options={{
            headerShown: false,
          }}
          component={AuthFlow}
        />

        <Stack.Screen
          name="SignUp"
          options={{
            headerShown: false,
          }}
          component={SignUp}
        />

        <Stack.Screen
          name="ForgotPassword"
          options={{
            headerShown: false,
          }}
          component={ForgotPassword}
        />

        <Stack.Screen
          name="Home"
          options={{
            headerShown: false,
          }}
          component={Home}
        />

        <Stack.Screen
          name="NotFound"
          options={{
            headerShown: false,
          }}
          component={NotFound}
        />
        <Stack.Screen
          name="CameraContainer"
          options={{
            headerShown: false,
          }}
          component={CameraContainer}
        />

        <Stack.Screen
          name="CameraSeal"
          options={{
            headerShown: false,
          }}
          component={CameraSeal}
        />

        <Stack.Screen
          name="CurrentInspection"
          options={{
            headerShown: false,
          }}
          component={CurrentInspection}
          initialParams={{ type: "container" }}
        />
        <Stack.Group screenOptions={{ presentation: "modal" }}>
          <Stack.Screen
            name="LoadingContainer"
            options={{
              headerShown: false,
            }}
            component={LoadingContainer}
          />
          <Stack.Screen
            name="LoadingSeal"
            options={{
              headerShown: false,
            }}
            component={LoadingSeal}
          />
        </Stack.Group>
        {/* <Stack.Group screenOptions={{ presentation: 'modal' }}>
          <Stack.Screen
            name="CancelCurrentInspection"
            options={{
              headerShown: false,
            }}
            component={CancelCurrentInspection} />
          <Stack.Screen
            name="FinishCurrentInspection"
            options={{
              headerShown: false,
            }}
            component={FinishCurrentInspection} />
          <Stack.Screen
            name="FinishWithoutSeals"
            options={{
              headerShown: false,
            }}
            component={FinishWithoutSeals} />
          <Stack.Screen
            name="OcrContainerError"
            options={{
              headerShown: false,
            }}
            component={OcrContainerError} />
          <Stack.Screen
            name="OcrSealError"
            options={{
              headerShown: false,
            }}
            component={OcrSealError} />
        </Stack.Group> */}
      </Stack.Navigator>
    </NavigationContainer>
  )
}

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
  },
  version: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: Sizes.marginS,
  },
  bottomDrawer: {
    flexDirection: "column",
  },
  label: {
    margin: 16,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, .87)",
  },
  labelVersion: {
    marginLeft: 4,
    fontSize: 11,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, .87)",
  },
  labelVersionValue: {
    marginHorizontal: 4,
    fontSize: 11,
    color: "rgba(0, 0, 0, .87)",
  },
  labeluuidValue: {
    marginHorizontal: 4,
    fontSize: 11,
    color: "rgba(0, 0, 0, .87)",
  },
  iconContainer: {
    marginLeft: 16,
    marginRight: 5,
    width: 24,
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
  },
})
