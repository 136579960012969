import { I18n } from "i18n-js";
import * as Localization from "expo-localization";
import en from "./locales/en";
import pt from "./locales/pt";
import es from "./locales/es";
import ca from "./locales/ca";

const i18nJs = new I18n();

interface MetaDictionary<T> {
  screens: {
    NotFound: {
      message: T;
    };
    Main: {
      welcome: T;
      newInspection: T;
      inspections: T;
      checkNewUpdates: T;
      pastInspections: T;
      version: T;
      ongoing: T;
      scheduled: T;
      noScheduledInspections: T;
      noOngoingInspections: T;
      createdAt: T;
    };
    Alerts: {
      checkUpdateTitle: T;
      checkUpdateText: T;
      checkUpdateButton: T;
      noUpdatesTitle: T;
      noUpdatesText: T;
      noUpdatesButton: T;
      errorAtReloadTitle: T;
      errorAtReloadText: T;
      errorAtReloadButton: T;
      deleteSealTitle: T;
      deleteSealText: T;
      deleteSealButton: T;
      Confirm: T;
      Cancel: T;
    };
    Inspections: {
      showDetails: T;
      hideDetails: T;
      scale: T;
      category: T;
      container: T;
      device: T;
      tare: T;
      submit: T;
      dashboard: T;
      delete: T;
      ongoing: T;
      completed: T;
      incomplete: T;
      successMessageInspectionSaved: T;
      errorMessageSubmit: T;
      errorMessageUnknownDevice: T;
      errorMessageUnknownContainer: T;
      errorMessageUnboundTimeout: T;
      errorMessageUnauthorizedRequest: T;
      errorMessageDeviceNotReady: T;
      errorMessageVolumeComputation: T;
      errorMessageUnexpected: T;
      errorMessageUnknownUsecase: T;
      errorMessageUnboundDelay: T;
      errorMessageInvalidContentType: T;
      newInspection: T;
      newSeal: T;
      newContainer: T;
      newPhoto: T;
      instructions: T;
      takePictureAgain: T;
      containerIncomplete: T;
      incompleteSealTitle: T;
      incompleteSealText: T;
      finishInspectionTitle: T;
      finishInspectionText: T;
      deleteInspectionTitle: T;
      deleteInspectionText: T;
      finishedInspectionTitle: T;
      finishedInspectionText: T;
      cancelInspectionTitle: T;
      cancelInspectionText: T;
      incompleteDataTitle: T;
      incompleteDataText: T;
      gpsPermissionsTitle: T;
      gpsPermissionsMessage: T;
      gpsPermissionsCancel: T;
      gpsPermissionsConfirm: T;
      gpsNoPermissionAlertTime: T;
      gpsNoPermissionAlertMessage: T;
      gpsNoPermissionAlertCancel: T;
      gpsNoPermissionAlertRequest: T;
      error: T;
      close: T;
      tryAgain: T;
      noContainerFoundMessage: T;
      noSealFoundMessage: T;
      loading: T;
      loadingContainer: T;
      loadingSeal: T;
      instructionContainerPhoto: T;
      containerTypes: {
        container: T;
        opening: T;
        hallway: T;
        extracted_merch: T;
        label_eq: T;
        closing: T;
        others: T;
      };
    };
    Seal: {
      header: T;
      labelSealId: T;
      labelCategory: T;
      labelPosition: T;
      labelLocation: T;
      labelOwner: T;
      newPhoto: T;
      deletePhotoTitle: T;
      deletePhotoMessage: T;
      cancel: T;
      confirm: T;
      labelSealViolationDetails: T;
      defaultViolatedReason: T;
      sealOnLeftDoor: T;
      instructionSealPhoto: T;
      missingSealSerialIdWarning: T;
      owners: {
        other: T;
      };
      categories: {
        empty: T;
        orginal: T;
        vessel: T;
        terminal: T;
        inspectionTeam: T;
        inspectionCertificate: T;
        other: T;
      };
      sizes: {
        empty: T;
        "22": T;
        "25": T;
        "42": T;
        "45": T;
        L2: T;
        L5: T;
        M2: T;
        M5: T;
      };
    };
    Container: {
      checkDigitErrorMessage: T;
      checkDigitNoneErrorMessage: T;
      emptyOwnerPrefixErrorMessage: T;
      emptySerialNumberErrorMessage: T;
      emptyDigitErrorMessage: T;
      ownerPrefixErrorMessage: T;
      incompleteInput: T;
      header: T;
      labelOwnerPrefix: T;
      labelSerialNumber: T;
      labelDigit: T;
      labelContainerId: T;
      labelContainerSize: T;
      labelContainerType: T;
      newPhoto: T;
      deletePhotoTitle: T;
      deletePhotoMessage: T;
      deletePhotoCancel: T;
      deletePhotoConfirm: T;
      instructionContainerPhoto: T;
      types: {
        empty: T;
        G1: T;
        R1: T;
        U1: T;
        P1: T;
        T1: T;
      };
    };
    LastInspections: {
      photo: T;
      container: T;
      service: T;
      petitioner: T;
      level: T;
      expedient: T;
      dock: T;
    };
  };
  cognito: {
    SignIn: T;
    SignUp: T;
    SignUpText: T;
    SignUpTitle: T;
    SendCode: T;
    SignOut: T;
    Username: T;
    Password: T;
    Email: T;
    Name: T;
    PhoneNumber: T;
    Confirm: T;
    Cancel: T;
    Submit: T;
    Skip: T;
    Verify: T;
    Code: T;
    Main: T;
    Operated: T;
    Anonymous: T;
    IncorrectLogin: T;
    ChangePassword: T;
    OldPassword: T;
    NewPassword: T;
    ConfirmPassword: T;
    ForgotPassword: T;
    EmailConfirmCode: T;
    ConfirmationCode: T;
    FirstAccess: T;
    FirstAccessMessage1: T;
    FirstAccessMessage2: T;
    SuccessMessageChangePassword: T;
    SuccessMessageSubmit: T;
    SuccessMessageTara: T;
    SuccessMessageLogin: T;
    ErrorMessageDifferentPasswords: T;
    ErrorGeneralMessage: T;
    ErrorFieldsEmpty: T;
    ForcePwdChangeException: T;
    NotAuthorizedException: T;
    UserNotFoundException: T;
    CodeMismatchException: T;
    InvalidPasswordException: T;
    InvalidParameterException: T;
    PasswordResetRequiredException: T;
    NotAuthorizedExceptionTitle: T;
    UserNotFoundExceptionTitle: T;
    CodeMismatchExceptionTitle: T;
    InvalidPasswordExceptionTitle: T;
    InvalidParameterExceptionTitle: T;
    PasswordResetRequiredExceptionTitle: T;
    CodeDeliveryFailureException: T;
    ForbiddenException: T;
    InternalErrorException: T;
    InvalidLambdaResponseException: T;
    InvalidSmsRoleAccessPolicyException: T;
    InvalidSmsRoleTrustRelationshipException: T;
    ResourceNotFoundException: T;
    TooManyRequestsException: T;
    UnexpectedLambdaException: T;
    UserLambdaValidationException: T;
    UsernameExistsException: T;
    EmptyFieldEmail: T;
    EmptyFieldName: T;
    EmptyFieldPassword: T;
    EmptyFieldPasswordConfirm: T;
  };
}

export type Dictionary = MetaDictionary<string>;

const t = (key: string | string[]) => () => i18nJs.t(key);

const i18nDictionary: MetaDictionary<() => string> = {
  screens: {
    NotFound: {
      message: t("screens.NotFound.message"),
    },
    Main: {
      welcome: t("screens.Main.welcome"),
      newInspection: t("screens.Main.newInspection"),
      inspections: t("screens.Main.inspections"),
      checkNewUpdates: t("screens.Main.checkNewUpdates"),
      pastInspections: t("screens.Main.pastInspections"),
      version: t("screens.Main.version"),
      ongoing: t("screens.Main.ongoing"),
      scheduled: t("screens.Main.scheduled"),
      noScheduledInspections: t("screens.Main.noScheduledInspections"),
      noOngoingInspections: t("screens.Main.noOngoingInspections"),
      createdAt: t("screens.Main.createdAt"),
    },
    Alerts: {
      checkUpdateTitle: t("screens.Alerts.checkUpdateTitle"),
      checkUpdateText: t("screens.Alerts.checkUpdateText"),
      checkUpdateButton: t("screens.Alerts.checkUpdateButton"),
      noUpdatesTitle: t("screens.Alerts.noUpdatesTitle"),
      noUpdatesText: t("screens.Alerts.noUpdatesText"),
      noUpdatesButton: t("screens.Alerts.noUpdatesButton"),
      errorAtReloadTitle: t("screens.Alerts.errorAtReloadTitle"),
      errorAtReloadText: t("screens.Alerts.errorAtReloadText"),
      errorAtReloadButton: t("screens.Alerts.errorAtReloadButton"),
      deleteSealTitle: t("screens.Alerts.deleteSealTitle"),
      deleteSealText: t("screens.Alerts.deleteSealText"),
      deleteSealButton: t("screens.Alerts.deleteSealButton"),
      Confirm: t("screens.Alerts.Confirm"),
      Cancel: t("screens.Alerts.Cancel"),
    },
    Inspections: {
      showDetails: t("screens.Inspections.showDetails"),
      hideDetails: t("screens.Inspections.hideDetails"),
      scale: t("screens.Inspections.scale"),
      category: t("screens.Inspections.category"),
      container: t("screens.Inspections.container"),
      device: t("screens.Inspections.device"),
      tare: t("screens.Inspections.tare"),
      delete: t("screens.Inspections.delete"),
      ongoing: t("screens.Inspections.ongoing"),
      completed: t("screens.Inspections.completed"),
      incomplete: t("screens.Inspections.incomplete"),
      submit: t("screens.Inspections.submit"),
      dashboard: t("screens.Inspections.dashboard"),
      newInspection: t("screens.Inspections.newInspection"),
      successMessageInspectionSaved: t(
        "screens.Inspections.successMessageInspectionSaved"
      ),
      errorMessageSubmit: t("screens.Inspections.errorMessageSubmit"),
      errorMessageUnknownDevice: t(
        "screens.Inspections.errorMessageUnknownDevice"
      ),
      errorMessageUnknownContainer: t(
        "screens.Inspections.errorMessageUnknownContainer"
      ),
      errorMessageUnboundTimeout: t(
        "screens.Inspections.errorMessageUnboundTimeout"
      ),
      errorMessageUnauthorizedRequest: t(
        "screens.Inspections.errorMessageUnauthorizedRequest"
      ),
      errorMessageDeviceNotReady: t(
        "screens.Inspections.errorMessageDeviceNotReady"
      ),
      errorMessageVolumeComputation: t(
        "screens.Inspections.errorMessageVolumeComputation"
      ),
      errorMessageUnexpected: t("screens.Inspections.errorMessageUnexpected"),
      errorMessageUnknownUsecase: t(
        "screens.Inspections.errorMessageUnknownUsecase"
      ),
      errorMessageUnboundDelay: t(
        "screens.Inspections.errorMessageUnboundDelay"
      ),
      errorMessageInvalidContentType: t(
        "screens.Inspections.errorMessageInvalidContentType"
      ),
      newSeal: t("screens.Inspections.newSeal"),
      newContainer: t("screens.Inspections.newContainer"),
      newPhoto: t("screens.Inspections.newPhoto"),
      instructions: t("screens.Inspections.instructions"),
      takePictureAgain: t("screens.Inspections.takePictureAgain"),
      containerIncomplete: t("screens.Inspections.containerIncomplete"),
      incompleteSealTitle: t("screens.Inspections.incompleteSealTitle"),
      incompleteSealText: t("screens.Inspections.incompleteSealText"),
      finishInspectionTitle: t("screens.Inspections.finishInspectionTitle"),
      finishInspectionText: t("screens.Inspections.finishInspectionText"),
      deleteInspectionTitle: t("screens.Inspections.deleteInspectionTitle"),
      deleteInspectionText: t("screens.Inspections.deleteInspectionText"),
      finishedInspectionTitle: t("screens.Inspections.finishedInspectionTitle"),
      finishedInspectionText: t("screens.Inspections.finishedInspectionText"),
      cancelInspectionTitle: t("screens.Inspections.cancelInspectionTitle"),
      cancelInspectionText: t("screens.Inspections.cancelInspectionText"),
      incompleteDataTitle: t("screens.Inspections.incompleteDataTitle"),
      incompleteDataText: t("screens.Inspections.incompleteDataText"),
      gpsPermissionsTitle: t("screens.Inspections.gpsPermissionsTitle"),
      gpsPermissionsMessage: t("screens.Inspections.gpsPermissionsMessage"),
      gpsPermissionsCancel: t("screens.Inspections.gpsPermissionsCancel"),
      gpsPermissionsConfirm: t("screens.Inspections.gpsPermissionsConfirm"),
      gpsNoPermissionAlertTime: t(
        "screens.Inspections.gpsNoPermissionAlertTime"
      ),
      gpsNoPermissionAlertMessage: t(
        "screens.Inspections.gpsNoPermissionAlertMessage"
      ),
      gpsNoPermissionAlertCancel: t(
        "screens.Inspections.gpsNoPermissionAlertCancel"
      ),
      gpsNoPermissionAlertRequest: t(
        "screens.Inspections.gpsNoPermissionAlertRequest"
      ),
      error: t("screens.Inspections.error"),
      close: t("screens.Inspections.close"),
      tryAgain: t("screens.Inspections.tryAgain"),
      noContainerFoundMessage: t("screens.Inspections.noContainerFoundMessage"),
      noSealFoundMessage: t("screens.Inspections.noSealFoundMessage"),
      loading: t("screens.Inspections.loading"),
      loadingContainer: t("screens.Inspections.loadingContainer"),
      loadingSeal: t("screens.Inspections.loadingSeal"),
      instructionContainerPhoto: t(
        "screens.Inspections.instructionContainerPhoto"
      ),
      containerTypes: {
        container: t("screens.Inspections.containerTypes.container"),
        opening: t("screens.Inspections.containerTypes.opening"),
        hallway: t("screens.Inspections.containerTypes.hallway"),
        extracted_merch: t(
          "screens.Inspections.containerTypes.extracted_merch"
        ),
        label_eq: t("screens.Inspections.containerTypes.label_eq"),
        closing: t("screens.Inspections.containerTypes.closing"),
        others: t("screens.Inspections.containerTypes.others"),
      },
    },
    Seal: {
      header: t("screens.Seal.header"),
      labelSealId: t("screens.Seal.labelSealId"),
      labelCategory: t("screens.Seal.labelCategory"),
      labelLocation: t("screens.Seal.labelLocation"),
      labelPosition: t("screens.Seal.labelPosition"),
      labelOwner: t("screens.Seal.labelOwner"),
      labelSealViolationDetails: t("screens.Seal.labelSealViolationDetails"),
      defaultViolatedReason: t("screens.Seal.defaultViolatedReason"),
      newPhoto: t("screens.Seal.newPhoto"),
      deletePhotoTitle: t("screens.Seal.deletePhotoTitle"),
      deletePhotoMessage: t("screens.Seal.deletePhotoMessage"),
      cancel: t("screens.Seal.cancel"),
      confirm: t("screens.Seal.confirm"),
      sealOnLeftDoor: t("screens.Seal.sealOnLeftDoor"),
      instructionSealPhoto: t("screens.Seal.instructionSealPhoto"),
      missingSealSerialIdWarning: t("screens.Seal.missingSealSerialIdWarning"),
      owners: {
        other: t("screens.Seal.owners.other"),
      },
      categories: {
        empty: t("screens.Seal.categories.empty"),
        orginal: t("screens.Seal.categories.orginal"),
        vessel: t("screens.Seal.categories.vessel"),
        terminal: t("screens.Seal.categories.terminal"),
        inspectionTeam: t("screens.Seal.categories.inspectionTeam"),
        inspectionCertificate: t(
          "screens.Seal.categories.inspectionCertificate"
        ),
        other: t("screens.Seal.categories.other"),
      },
      sizes: {
        empty: t("screens.Seal.sizes.empty"),
        "22": t("screens.Seal.sizes.22"),
        "25": t("screens.Seal.sizes.25"),
        "42": t("screens.Seal.sizes.42"),
        "45": t("screens.Seal.sizes.45"),
        L2: t("screens.Seal.sizes.L2"),
        L5: t("screens.Seal.sizes.L5"),
        M2: t("screens.Seal.sizes.M2"),
        M5: t("screens.Seal.sizes.M5"),
      },
    },
    Container: {
      ownerPrefixErrorMessage: t("screens.Container.ownerPrefixErrorMessage"),
      checkDigitNoneErrorMessage: t(
        "screens.Container.checkDigitNoneErrorMessage"
      ),
      emptyOwnerPrefixErrorMessage: t(
        "screens.Container.emptyOwnerPrefixErrorMessage"
      ),
      emptySerialNumberErrorMessage: t(
        "screens.Container.emptySerialNumberErrorMessage"
      ),
      emptyDigitErrorMessage: t("screens.Container.emptyDigitErrorMessage"),
      incompleteInput: t("screens.Container.incompleteInput"),
      checkDigitErrorMessage: t("screens.Container.checkDigitErrorMessage"),
      header: t("screens.Container.header"),
      labelOwnerPrefix: t("screens.Container.labelOwnerPrefix"),
      labelSerialNumber: t("screens.Container.labelSerialNumber"),
      labelDigit: t("screens.Container.labelDigit"),
      labelContainerId: t("screens.Container.labelContainerId"),
      labelContainerSize: t("screens.Container.labelContainerSize"),
      labelContainerType: t("screens.Container.labelContainerType"),
      newPhoto: t("screens.Container.newPhoto"),
      deletePhotoTitle: t("screens.Seal.deletePhotoTitle"),
      deletePhotoMessage: t("screens.Seal.deletePhotoMessage"),
      deletePhotoCancel: t("screens.Seal.cancel"),
      deletePhotoConfirm: t("screens.Seal.confirm"),
      instructionContainerPhoto: t(
        "screens.Container.instructionContainerPhoto"
      ),
      types: {
        empty: t("screens.Container.types.empty"),
        G1: t("screens.Container.types.G1"),
        R1: t("screens.Container.types.R1"),
        U1: t("screens.Container.types.U1"),
        P1: t("screens.Container.types.P1"),
        T1: t("screens.Container.types.T1"),
      },
    },
    LastInspections: {
      photo: t("screens.LastInspections.photo"),
      container: t("screens.LastInspections.container"),
      service: t("screens.LastInspections.service"),
      petitioner: t("screens.LastInspections.petitioner"),
      level: t("screens.LastInspections.level"),
      expedient: t("screens.LastInspections.expedient"),
      dock: t("screens.LastInspections.dock"),
    },
  },
  cognito: {
    SignIn: t(["cognito", "SignIn"]),
    SignUp: t(["cognito", "SignUp"]),
    SignUpText: t(["cognito", "SignUpText"]),
    SignUpTitle: t(["cognito", "SignUpTitle"]),
    SendCode: t(["cognito", "SendCode"]),
    SignOut: t(["cognito", "SignOut"]),
    Username: t(["cognito", "Username"]),
    Password: t(["cognito", "Password"]),
    Email: t(["cognito", "Email"]),
    Name: t(["cognito", "Name"]),
    PhoneNumber: t(["cognito", "PhoneNumber"]),
    Confirm: t(["cognito", "Confirm"]),
    Cancel: t(["cognito", "Cancel"]),
    Submit: t(["cognito", "Submit"]),
    Skip: t(["cognito", "Skip"]),
    Verify: t(["cognito", "Verify"]),
    Code: t(["cognito", "Code"]),
    Main: t(["cognito", "Main"]),
    Operated: t(["cognito", "Operated"]),
    Anonymous: t(["cognito", "Anonymous"]),
    IncorrectLogin: t(["cognito", "IncorrectLogin"]),
    ChangePassword: t(["cognito", "ChangePassword"]),
    OldPassword: t(["cognito", "OldPassword"]),
    NewPassword: t(["cognito", "NewPassword"]),
    ConfirmPassword: t(["cognito", "ConfirmPassword"]),
    ForgotPassword: t(["cognito", "ForgotPassword"]),
    EmailConfirmCode: t(["cognito", "EmailConfirmCode"]),
    ConfirmationCode: t(["cognito", "ConfirmationCode"]),
    FirstAccess: t(["cognito", "FirstAccess"]),
    FirstAccessMessage1: t(["cognito", "FirstAccessMessage1"]),
    FirstAccessMessage2: t(["cognito", "FirstAccessMessage2"]),
    SuccessMessageChangePassword: t([
      "cognito",
      "SuccessMessageChangePassword",
    ]),
    SuccessMessageSubmit: t(["cognito", "SuccessMessageSubmit"]),
    SuccessMessageTara: t(["cognito", "SuccessMessageTara"]),
    SuccessMessageLogin: t(["cognito", "SuccessMessageLogin"]),
    ErrorMessageDifferentPasswords: t([
      "cognito",
      "ErrorMessageDifferentPasswords",
    ]),
    ErrorGeneralMessage: t(["cognito", "ErrorGeneralMessage"]),
    ErrorFieldsEmpty: t(["cognito", "ErrorFieldsEmpty"]),
    ForcePwdChangeException: t(["cognito", "ForcePwdChangeException"]),
    NotAuthorizedException: t(["cognito", "NotAuthorizedException"]),
    UserNotFoundException: t(["cognito", "UserNotFoundException"]),
    CodeMismatchException: t(["cognito", "CodeMismatchException"]),
    InvalidPasswordException: t(["cognito", "InvalidPasswordException"]),
    InvalidParameterException: t(["cognito", "InvalidParameterException"]),
    PasswordResetRequiredException: t([
      "cognito",
      "PasswordResetRequiredException",
    ]),
    NotAuthorizedExceptionTitle: t(["cognito", "NotAuthorizedExceptionTitle"]),
    UserNotFoundExceptionTitle: t(["cognito", "UserNotFoundExceptionTitle"]),
    CodeMismatchExceptionTitle: t(["cognito", "CodeMismatchExceptionTitle"]),
    InvalidPasswordExceptionTitle: t([
      "cognito",
      "InvalidPasswordExceptionTitle",
    ]),
    InvalidParameterExceptionTitle: t([
      "cognito",
      "InvalidParameterExceptionTitle",
    ]),
    PasswordResetRequiredExceptionTitle: t([
      "cognito",
      "PasswordResetRequiredExceptionTitle",
    ]),
    CodeDeliveryFailureException: t([
      "cognito",
      "CodeDeliveryFailureException",
    ]),
    ForbiddenException: t(["cognito", "ForbiddenException"]),
    InternalErrorException: t(["cognito", "InternalErrorException"]),
    InvalidLambdaResponseException: t([
      "cognito",
      "InvalidLambdaResponseException",
    ]),
    InvalidSmsRoleAccessPolicyException: t([
      "cognito",
      "InvalidSmsRoleAccessPolicyException",
    ]),
    InvalidSmsRoleTrustRelationshipException: t([
      "cognito",
      "InvalidSmsRoleTrustRelationshipException",
    ]),
    ResourceNotFoundException: t(["cognito", "ResourceNotFoundException"]),
    TooManyRequestsException: t(["cognito", "TooManyRequestsException"]),
    UnexpectedLambdaException: t(["cognito", "UnexpectedLambdaException"]),
    UserLambdaValidationException: t([
      "cognito",
      "UserLambdaValidationException",
    ]),
    UsernameExistsException: t(["cognito", "UsernameExistsException"]),
    EmptyFieldEmail: t(["cognito", "EmptyFieldEmail"]),
    EmptyFieldName: t(["cognito", "EmptyFieldName"]),
    EmptyFieldPassword: t(["cognito", "EmptyFieldPassword"]),
    EmptyFieldPasswordConfirm: t(["cognito", "EmptyFieldPasswordConfirm"]),
  },
};

i18nJs.enableFallback = true;
i18nJs.translations = { en, pt, es, ca };
i18nJs.locale = Localization.locale;

export const i18n = i18nJs;
export default i18nDictionary;
