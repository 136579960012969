import type { StackScreenProps } from "@react-navigation/stack";
import type { DrawerScreenProps } from "@react-navigation/drawer";
import { SEAL_OWNERS } from "../helpers/constantsValues";
import i18nDictionary from "../i18n";

export type RootStackParamList = {
  Auth: undefined;
  Home: undefined;
  Main: undefined;
  NotFound: undefined;
  ForgotPassword: undefined;
  ChangePassword: undefined;
  SignUp: undefined;
  CurrentInspection: { type: "container" | "seal" };
  CameraContainer: { update: boolean };
  LoadingContainer: { uri: string; update: boolean };
  CameraSeal: { sealId: string };
  LoadingSeal: { sealId: string; uri: string };
  CancelInspection: undefined;
  FinishInspection: undefined;
  FinishWithoutSeals: undefined;
  OcrContainerError: { id: string };
  OcrSealError: { sealId: string };
};

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  StackScreenProps<RootStackParamList, T>;

export type RootDrawerParamList = {
  Main: undefined;
  ChangePassword: undefined;
};

export type RootDrawerScreenProps<T extends keyof RootDrawerParamList> =
  DrawerScreenProps<RootDrawerParamList, T>;

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type PhotoMetadata = {
  photoHeight: number;
  photoWidth: number;
  photoFormat: PhotoFormat;
};

export type InspectionState = {
  id: string;
  createdAt?: string;
  container?: ContainerState;
  seals?: SealState[];
  errorPanel?: number;
};

export type ContainerState = {
  id: string;
  containerSize?: ContainerSize;
  containerType?: ContainerType;
  s3Path: string;
  imgUrl: string;
};

export interface ViolateReduxState {
  validation: ValidationState.VIOLATED;
  reason: string;
}

export type ContainerSerial = (NUM | undefined)[];
export type ContainerOwner = (ALPHA | undefined)[];

export interface ViolateState {
  validation: ValidationState.VIOLATED;
  reason: string;
}

export interface InviolateState {
  validation: Exclude<ValidationState, ValidationState.VIOLATED>;
}

export type SealState = {
  timestamp: string;
  s3Path: string;
  category: SealCategory;
  serialId: string;
  imgUrl: string;
  owner: string;
  location: SealLocation;
  validation: ValidationState;
  reason?: string;
};

export enum SealCategory {
  ORIGINAL = "ORIGINAL",
  NAVIERA = "NAVIERA",
  TERMINAL = "TERMINAL",
  EQ = "EQ",
  INSPECTION = "INSPECTION",
  OUTRO = "OUTRO",
}

export enum ValidationState {
  OK = "OK",
  VIOLATED = "VIOLATED",
  DEFAULT = "DEFAULT",
}

export enum IconAccordionState {
  SEAL,
  CONTAINER,
}

export enum AlertState {
  closed,
  incompleteSeal,
  finishInspection,
  cancelInspection,
  error,
  incompleteData,
  noContainerFoundOcr,
  noSealFoundOcr,
  changeContainerPhoto,
  changeSealPhoto,
}

export interface CodeListType {
  data: Array<string>;
}

export interface AlertDataState {
  title?: string;
  message?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  showConfirm?: boolean;
  showCancel?: boolean;
  onCancelPress?: () => void;
  onConfirmPress?: () => void;
}

export enum CameraState {
  closed,
  sealEdit,
  sealCreate,
  containerEdit,
}

export interface CameraDataState {
  instruction: string;
  nextStep: () => void;
}

export interface Option<T> {
  label: string;
  value: T;
}
export type OwnerState<T> = Option<T>[];
export type CategoryState<T> = Option<T>[];

type OwnersType = {
  [key: string]: () => string;
};
export const ownerValueToLabel = (owner: string) => {
  const ownerHasTranslation =
    owner in (i18nDictionary.screens.Seal.owners as OwnersType);
  const ownerLabel = ownerHasTranslation
    ? i18nDictionary.screens.Seal.owners[
        owner as keyof typeof i18nDictionary.screens.Seal.owners
      ]()
    : owner;
  return ownerLabel;
};
export const OWNER_SEAL_OPTIONS: OwnerState<SealOwnerValue> = SEAL_OWNERS.map(
  (owner) => ({ label: ownerValueToLabel(owner), value: owner })
);
export const SEAL_OWNERS_SET = new Set<string>(SEAL_OWNERS);
export type SealOwnerValue = (typeof SEAL_OWNERS)[number];

export const SEAL_CATEGORIES = [
  "ORIGINAL",
  "NAVIERA",
  "TERMINAL",
  "EQ",
  "INSPECTION",
  "OUTRO",
];
export const SEAL_CATEGORIES_SET = new Set<string>(SEAL_CATEGORIES);
export const DEFAULT_SEAL_CATEGORIES = SEAL_CATEGORIES[0];
export type SealCategoryValue = (typeof SEAL_CATEGORIES)[number];

export const CONTAINER_SIZES = [
  "45",
  "10",
  "12",
  "14",
  "15",
  "16",
  "18",
  "19",
  "1C",
  "1D",
  "1E",
  "1F",
  "1L",
  "1M",
  "1N",
  "1P",
  "20",
  "22",
  "24",
  "25",
  "26",
  "28",
  "29",
  "2C",
  "2D",
  "2E",
  "2F",
  "2L",
  "2M",
  "2N",
  "2P",
  "30",
  "32",
  "34",
  "35",
  "36",
  "38",
  "39",
  "3C",
  "3D",
  "3E",
  "3F",
  "3L",
  "3M",
  "3N",
  "3P",
  "40",
  "42",
  "44",
  "46",
  "48",
  "49",
  "4C",
  "4D",
  "4E",
  "4F",
  "4L",
  "4M",
  "4N",
  "4P",
  "A0",
  "A2",
  "A4",
  "A5",
  "A6",
  "A8",
  "A9",
  "AC",
  "AD",
  "AE",
  "AF",
  "AL",
  "AM",
  "AN",
  "AP",
  "B0",
  "B2",
  "B4",
  "B5",
  "B6",
  "B8",
  "B9",
  "BC",
  "BD",
  "BE",
  "BF",
  "BL",
  "BM",
  "BN",
  "BP",
  "C0",
  "C2",
  "C4",
  "C5",
  "C6",
  "C8",
  "C9",
  "CC",
  "CD",
  "CE",
  "CF",
  "CL",
  "CM",
  "CN",
  "CP",
  "D0",
  "D2",
  "D4",
  "D5",
  "D6",
  "D8",
  "D9",
  "DC",
  "DD",
  "DE",
  "DF",
  "DL",
  "DM",
  "DN",
  "DP",
  "E0",
  "E2",
  "E4",
  "E5",
  "E6",
  "E8",
  "E9",
  "EC",
  "ED",
  "EE",
  "EF",
  "EL",
  "EM",
  "EN",
  "EP",
  "F0",
  "F2",
  "F4",
  "F5",
  "F6",
  "F8",
  "F9",
  "FC",
  "FD",
  "FE",
  "FF",
  "FL",
  "FM",
  "FN",
  "FP",
  "L0",
  "L2",
  "L4",
  "L5",
  "L6",
  "L8",
  "L9",
  "LC",
  "LD",
  "LE",
  "LF",
  "LL",
  "LM",
  "LN",
  "LP",
  "M0",
  "M2",
  "M4",
  "M5",
  "M6",
  "M8",
  "M9",
  "MC",
  "MD",
  "ME",
  "MF",
  "ML",
  "MM",
  "MN",
  "MP",
  "N0",
  "N2",
  "N4",
  "N5",
  "N6",
  "N8",
  "N9",
  "NC",
  "ND",
  "NE",
  "NF",
  "NL",
  "NM",
  "NN",
  "NP",
  "P0",
  "P2",
  "P4",
  "P5",
  "P6",
  "P8",
  "P9",
  "PC",
  "PD",
  "PE",
  "PF",
  "PL",
  "PM",
  "PN",
  "PP",
] as const;
export const CONTAINER_SIZES_SET = new Set<string>(CONTAINER_SIZES);
export const DEFAULT_CONTAINER_SIZE = CONTAINER_SIZES[0];
export type ContainerSize = (typeof CONTAINER_SIZES)[number];

export const CONTAINER_TYPES = ["G1", "R1", "U1", "P1", "T1"];
export const CONTAINER_TYPES_SET = new Set<string>(CONTAINER_TYPES);
export const DEFAULT_CONTAINER_TYPE = CONTAINER_TYPES[0];
export type ContainerType = (typeof CONTAINER_TYPES)[number];

export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
] as const;
export type ALPHA = (typeof ALPHABET)[number];
export const ALPHABET_SET = new Set(ALPHABET);

export const NUMBER = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] as const;
export type NUM = (typeof NUMBER)[number];
export const NUMBER_SET = new Set(NUMBER);

export const CONTAINER_PRODUCT_GROUP = ["U", "J", "Z"] as const;
export type ContainerProductGroup = (typeof CONTAINER_PRODUCT_GROUP)[number];
export const CONTAINER_PRODUCT_GROUP_SET = new Set(CONTAINER_PRODUCT_GROUP);

export const PHOTO_FORMATS = ["png", "jpeg", "jpg"] as const;
export type PhotoFormat = (typeof PHOTO_FORMATS)[number];
export const PHOTO_FORMATS_SET = new Set(PHOTO_FORMATS);

export const SEAL_LOCATIONS = [
  "CAM_SECURE",
  "CAM_ALTERNATIVE",
  "OTHER",
  "LEFT",
] as const;
export type SealLocation = (typeof SEAL_LOCATIONS)[number];
export const SEAL_LOCATIONS_SET = new Set(SEAL_LOCATIONS);

export const SEAL_VALIDATIONS = ["OK", "VIOLATED", "DEFAULT"] as const;
export type SealValidation = (typeof SEAL_VALIDATIONS)[number];
export const SEAL_VALIDATIONS_SET = new Set(SEAL_VALIDATIONS);

export const OWNER_TO_CATEGORY: Map<string, string> = new Map([
  ["TCB", "TERMINAL"],
  ["HUTCHISONPORTS", "TERMINAL"],
  ["SEALED", "TERMINAL"],
  ["TERCAT ", "TERMINAL"],
  ["APMT", "TERMINAL"],
  ["UNKNOWN RED TERMINAL", "TERMINAL"],
  ["AREAPIF", "EQ"],
  ["SANIDAD ANIMAL", "INSPECTION"],
  ["SANIDAD EXTERIOR", "INSPECTION"],
  ["ONE", "NAVIERA"],
  ["MAERSK", "NAVIERA"],
  ["Yang Ming", "NAVIERA"],
  ["W.E.C.", "NAVIERA"],
  ["MSC", "NAVIERA"],
  ["Hapag Lloyd", "NAVIERA"],
  ["CMA-CGM", "NAVIERA"],
  ["HMM", "NAVIERA"],
  ["ZIM", "NAVIERA"],
  ["COSCO", "NAVIERA"],
  ["OOCL", "NAVIERA"],
  ["EVERGREEN", "NAVIERA"],
  ["BOLUDA LINES", "NAVIERA"],
  ["UL", "NAVIERA"],
  ["ZZC", "NAVIERA"],
  ["Douane Marocaine", "ORIGINAL"],
  ["DYW", "ORIGINAL"],
  ["BRASIL S.I.F.", "ORIGINAL"],
  ["SENASA", "ORIGINAL"],
  ["ADUANA PE", "ORIGINAL"],
  ["ADUANA", "ORIGINAL"],
  ["SAG", "ORIGINAL"],
  ["Selecta", "ORIGINAL"],
  ["PR", "ORIGINAL"],
  ["UY MVD", "ORIGINAL"],
  ["NEW TRANSPORT", "ORIGINAL"],
  ["precintia", "ORIGINAL"],
  ["IDIARN", "ORIGINAL"],
  ["i-TEK", "ORIGINAL"],
  ["CTC", "ORIGINAL"],
  ["JF ASOCIADOS", "ORIGINAL"],
  ["OCEAMIC", "ORIGINAL"],
  ["CBP", "ORIGINAL"],
  ["REVENUE SERVICE", "ORIGINAL"],
  ["PROTECTED", "ORIGINAL"],
  ["BODA", "ORIGINAL"],
  ["CSOL", "ORIGINAL"],
  ["NIL", "ORIGINAL"],
  ["CUSTOMS ICD CHAWA", "ORIGINAL"],
  ["YAZOO", "ORIGINAL"],
  ["SEALED", "ORIGINAL"],
  ["intertek", "ORIGINAL"],
  ["FELVENZA", "ORIGINAL"],
  ["EMBARO", "ORIGINAL"],
  ["Sanster", "ORIGINAL"],
  ["BOLUDA LINES", "ORIGINAL"],
  ["88PMA ", "ORIGINAL"],
  ["Sepio SPPL", "ORIGINAL"],
  ["UNISIE ARRIMAGE", "ORIGINAL"],
  ["SENACSA", "ORIGINAL"],
  ["MS/ADUANA PARAGUAY", "ORIGINAL"],
  ["PSF-PY ", "ORIGINAL"],
  ["PIF - C.B.C.S.", "ORIGINAL"],
  ["NZMPI", "ORIGINAL"],
  ["@ferch", "ORIGINAL"],
  ["AS&A", "ORIGINAL"],
  ["JANNAGAR", "ORIGINAL"],
  ["SECURITY SEAL", "ORIGINAL"],
  ["NIC", "ORIGINAL"],
  ["NOVUS", "ORIGINAL"],
  ["TIMAGO DE MORA", "ORIGINAL"],
  ["WARNER", "ORIGINAL"],
  ["HMM", "ORIGINAL"],
  ["BP", "ORIGINAL"],
  ["ANIMAL QUARANTINE", "ORIGINAL"],
  ["ACT", "ORIGINAL"],
  ["APMT", "ORIGINAL"],
  ["RECORD", "ORIGINAL"],
  ["ETIDOR", "ORIGINAL"],
  ["logifrans", "ORIGINAL"],
  ["ZZZ", "ORIGINAL"],
  ["GGP - INA", "ORIGINAL"],
  ["ARKAS", "ORIGINAL"],
  ["CALUP", "ORIGINAL"],
  ["MANMUM", "ORIGINAL"],
  ["Douane Tunisienne", "ORIGINAL"],
  ["KORTIMED", "ORIGINAL"],
  ["PROTEC", "ORIGINAL"],
  ["UL", "ORIGINAL"],
  ["DIAMOND V", "ORIGINAL"],
  ["Australian Government", "ORIGINAL"],
  ["NSF", "ORIGINAL"],
  ["USIONS", "ORIGINAL"],
  ["CFM", "ORIGINAL"],
  ["EST OF N'4115", "ORIGINAL"],
  ["INDIAN CUSTOMS", "ORIGINAL"],
  ["IIDD PPG", "ORIGINAL"],
  ["NICOLAS FELIPE", "ORIGINAL"],
  ["SENACSA", "ORIGINAL"],
  ["OSTCO", "ORIGINAL"],
]);
