import React from 'react';
import { SafeAreaView, View } from 'react-native';
import { Theme } from '../styles';
import NotFound404 from '../../shared/svg/404';
import { HeaderComponent } from '../components';
import i18nDictionary from '../i18n';
import { Sizes } from '../../shared/constants/sizes';
import Text from '../../shared/atoms/Text'

const NotFound = () => {
    return (
        <>
            <HeaderComponent goBack={false} hasLogo={"true"} />
            <SafeAreaView style={{ height: "100%", alignItems: 'center' }}>
                <View style={{ margin: 50, alignItems: 'center' }}>
                    <NotFound404 width={300} height={300} />
                    <Text style={{ fontSize: Sizes.h2, color: Theme.colors.primary, fontWeight: '700' }}>{i18nDictionary.screens.NotFound.message()}</Text>
                </View>
            </SafeAreaView>
        </>
    );
}

export default NotFound;