import React, { useRef } from "react"
import { View, StyleSheet, TextInput } from "react-native"

// Constants
import { ContainerProductGroup, NUM, ContainerSerial, ContainerOwner } from "../../../../../types"

import ErrorPanel, { ErrorId } from "./ErrorPanel"
import { Theme } from "../../../../../styles"
import dict from "../../../../../i18n"
import { FloatingLabel } from "../../../../../../shared/atoms/FloatingLabel"
import FixedCharInput from "../../../../../../shared/molecules/FixedCharInput"
import { Sizes } from "../../../../../../shared/constants/sizes"
import { toAlphaChar, toNumDigit } from "../../../../../helpers/converters"

export interface ContainerIdState {
  owner: ContainerOwner
  productGroup?: ContainerProductGroup
  serial: ContainerSerial
  digit?: NUM
}

interface ContainerIdProps {
  onChangeHandler: (state: ContainerIdState) => void
  containerIdState: ContainerIdState
  errors: Set<ErrorId>
}

const ContainerId = ({ onChangeHandler, containerIdState, errors }: ContainerIdProps) => {
  const ownerRef = useRef<TextInput | null>(null)
  const serialRef = useRef<TextInput | null>(null)
  const digitRef = useRef<TextInput | null>(null)

  const convertToContainerIdPrefix = (values: Array<string | undefined>) => {
    const owner: ContainerOwner = values.slice(0, 3).map((val) => toAlphaChar(val))
    const productGroup: ContainerProductGroup | undefined =
      values[3] === "U" || values[3] === "Z" || values[3] === "J" ? values[3] : undefined
    return { owner, productGroup }
  }
  const convertToContainerIdSerial = (values: Array<string | undefined>) => {
    const serial: ContainerSerial = values.slice(0, 6).map((val) => toNumDigit(val))
    return { serial }
  }
  const convertToContainerIdDigit = (values: Array<string | undefined>) => {
    const digit: NUM | undefined = toNumDigit(values[0])
    return { digit }
  }

  const onChangePrefix = (values: Array<string | undefined>) => {
    const { owner, productGroup } = convertToContainerIdPrefix(values)
    onChangeHandler({ owner, productGroup, serial: containerIdState.serial, digit: containerIdState.digit })
  }
  const onChangeSerial = (values: Array<string | undefined>) => {
    const { serial } = convertToContainerIdSerial(values)
    onChangeHandler({ owner: containerIdState.owner, productGroup: containerIdState.productGroup, serial, digit: containerIdState.digit })
  }
  const onChangeDigit = (values: Array<string | undefined>) => {
    const { digit } = convertToContainerIdDigit(values)
    onChangeHandler({ owner: containerIdState.owner, productGroup: containerIdState.productGroup, serial: containerIdState.serial, digit })
  }

  return (
    <View style={{ marginTop: Sizes.marginL }}>
      <FloatingLabel
        text={dict.screens.Container.labelContainerId()}
        backgroundColor={Theme.colors.title}
        color={Theme.colors.text_black}
      />
      <View style={styles.containerId}>
        <View style={styles.center}>
          <FixedCharInput
            slots={4}
            onChangeHandler={onChangePrefix}
            firstRef={ownerRef}
            focusOnNext={() => serialRef.current?.focus()}
            values={[...containerIdState.owner, containerIdState.productGroup]}
            regex={/[A-Z]/g}
          />
          <FixedCharInput
            slots={6}
            onChangeHandler={onChangeSerial}
            firstRef={serialRef}
            focusOnNext={() => digitRef.current?.focus()}
            values={containerIdState.serial.map((val) => val?.toString())}
            regex={/[0-9]/g}
          />
          <FixedCharInput
            slots={1}
            onChangeHandler={onChangeDigit}
            firstRef={digitRef}
            focusOnNext={() => digitRef.current?.blur()}
            values={[containerIdState.digit?.toString()]}
            regex={/[0-9]/g}
          />
        </View>
        <ErrorPanel errors={errors} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerId: {
    textAlign: "center",
    borderWidth: 1,
    borderRadius: Sizes.borderRadiusS,
    borderColor: Theme.colors.border,
    padding: Sizes.paddingS,
    backgroundColor: "#F5F5F5",
  },
  input: {
    height: 45,
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 5,
    color: Theme.colors.text_dark,
    borderRadius: 5,
    fontSize: Sizes.body4,
    borderColor: "#B5B5B5",
    borderWidth: 1,
  },
  center: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
})

export default ContainerId
