import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Either, isLeft, isRight } from "fp-ts/lib/Either";
import { v4 as uuidv4 } from "uuid";
import {
  ContainerState,
  InspectionState,
  OWNER_SEAL_OPTIONS,
  PhotoFormat,
  SealLocation,
  SealState,
  ValidationState,
} from "../types";
import { ErrorId, ocrContainerId, ocrSealId } from "../services/ocr";
import { RootState } from "./index";
import { DataStore, Auth } from "aws-amplify";
import { getS3PhotoUrl, storageUri } from "../aws/storage";
import Storage from "../aws/storage";

import { right } from "fp-ts/lib/Either";
import configAws from "../aws-exports.js";
import {
  Inspection,
  InspectionSeal,
  LazyInspection,
  SealCategory,
} from "../models";
import { SEAL_OWNERS } from "../helpers/constantsValues";

type PhotoInfoState = {
  type: string;
  imagePath: string;
  format: string;
  timestamp: string;
  height: string;
  width: string;
};

type initialStateType = {
  inspectionData: InspectionState;
  photos: PhotoInfoState[];
  started: boolean;
  error: number;
};

const inspectionData: InspectionState = {
  id: "",
};

const initialState: initialStateType = {
  inspectionData,
  photos: [],
  started: false,
  error: -1,
};

export const createInspectionThunk = createAsyncThunk<
  InspectionState,
  void,
  { state: RootState }
>("inspection/createInspection", async () => {
  const userInfo = await Auth.currentUserInfo();
  const inspection = await DataStore.save(
    new Inspection({
      owner: userInfo?.username || "",
      startedTime: new Date().toISOString().split("T")[0], // YYYY-MM-DD
      seals: [],
    })
  );

  return {
    id: inspection.id,
    startedTime: inspection.startedTime,
    seals: inspection.seals,
  } as InspectionState;
});

export const updateInspectionThunk = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("inspection/updateInspection", async (_, { getState }) => {
  const currentInspection = getState().inspections.inspectionData;
  const inspection = await DataStore.query(Inspection, currentInspection.id);

  if (inspection) {
    await DataStore.save(
      Inspection.copyOf(inspection as LazyInspection, (updated) => {
        updated.container = currentInspection.container;
        updated.seals = currentInspection.seals;
      })
    );
  }
});

export const fetchInspectionsForOwner = createAsyncThunk<
  InspectionState[],
  {
    owner: string;
  },
  { state: RootState }
>("inspection/fetchInspectionsForOwner", async ({ owner }) => {
  const inspections = await DataStore.query(Inspection, (inspection) =>
    inspection.owner.eq(owner)
  );

  const ownerInspections: InspectionState[] = inspections.map((inspection) => ({
    id: inspection.id,
    createdAt: inspection.createdAt ?? undefined,
    seals: inspection.seals?.map((seal) => ({
      imgUrl: seal.imgUrl ?? "",
      s3Path: seal.s3Path ?? "",
      serialId: seal.serialId ?? "",
      timestamp: seal.timestamp ?? "",
      owner: seal.owner ?? "",
      category: seal.category as SealCategory,
      location: seal.location as SealLocation,
      validation: seal.validation as ValidationState,
    })),
  }));

  return ownerInspections;
});

export const executeSealOcr = createAsyncThunk<
  Either<ErrorId, SealState[]>,
  {
    sealPhotoBlob: Blob;
    photoHeight: number;
    photoWidth: number;
    photoFormat: PhotoFormat;
    sealId: string;
  },
  { state: RootState }
>("inspection/executeSealOcr", async (photoData, { getState }) => {
  const { sealPhotoBlob, photoHeight, photoWidth, photoFormat, sealId } =
    photoData;
  const metadata = { photoHeight, photoWidth, photoFormat };
  const inspectionId = getState().inspections.inspectionData.id;

  const ocrForm = "sealOCR";
  const sealData = await ocrSealId(ocrForm, sealPhotoBlob, sealId, metadata);

  const userInfo = await Auth.currentUserInfo();

  // Save new image in Datastore
  // TODO - check if we still need to create new inspections here. Probably
  // we should have it created before we start the seal OCR process.
  const original =
    (await DataStore.query(Inspection, inspectionId)) ||
    (await DataStore.save(
      new Inspection({
        owner: userInfo?.username || "",
        startedTime: new Date().toISOString().split("T")[0], // YYYY-MM-DD
        seals: [],
      })
    ));

  try {
    const isError = isLeft(sealData);
    if (!isError) {
      await DataStore.save(
        Inspection.copyOf(original, (updated) => {
          const originalSeals = updated.seals || [];
          const newSeals = sealData.right.map((seal) => ({
            ...seal,
            owner: seal.owner ?? SEAL_OWNERS[0],
          })) as InspectionSeal[];

          updated.seals = [...originalSeals, ...newSeals];
        })
      );
      return right(sealData.right);
    }
    if (
      sealData.left === ErrorId.NoBoundingBox ||
      sealData.left === ErrorId.ImageSmallerThen100Kb
    ) {
      const s3Path = `s3://${configAws.aws_user_files_s3_bucket}/public/${ocrForm}-id-${sealId}.${photoFormat}`;
      const emptySeal = {
        imgUrl: getS3PhotoUrl(s3Path),
        s3Path,
        serialId: "",
        timestamp: Date.now().toString(),
        owner: OWNER_SEAL_OPTIONS[0].value,
      };
      await DataStore.save(
        Inspection.copyOf(original, (updated) => {
          const originalSeals = original.seals || [];
          updated.seals = [...originalSeals, emptySeal];
        })
      );
      const sealState: SealState[] = [
        {
          category: SealCategory.ORIGINAL,
          location: "CAM_SECURE",
          validation: ValidationState.DEFAULT,
          ...emptySeal,
        },
      ];
      return right(sealState);
    }
  } catch (error) {
    console.log(error);
  }
  return sealData;
});

export const deleteSeal = createAsyncThunk(
  "inspection/deleteSeal",
  async (data: {
    serialId: string;
    s3: string;
    timestamp: string;
    inspectionId: string;
  }) => {
    const original = await DataStore.query(Inspection, data.inspectionId);
    const timestampS3 = data.s3.split("sealOCR-id-")[1].slice(0, -4);
    try {
      await DataStore.save(
        Inspection.copyOf(original as LazyInspection, (updated) => {
          updated.seals = updated.seals?.filter(
            (seal) => seal.s3Path + seal.serialId !== data.s3 + data.serialId
          );
        })
      );

      const lengthSealsTimestamp = original?.seals?.filter((seal) => {
        const timestampS3New = seal.s3Path
          ?.split("sealOCR-id-")[1]
          .slice(0, -4);
        if (timestampS3 === timestampS3New) {
          return true;
        } else {
          return false;
        }
      }).length;

      if (
        lengthSealsTimestamp === null ||
        lengthSealsTimestamp == undefined ||
        lengthSealsTimestamp <= 1
      ) {
        Storage.remove(`sealOCR-id-${timestampS3}.png`).catch((err) =>
          console.log("Deleting image from S3 error: ", err)
        );
      }
    } catch (error) {
      console.log(error);
    }
    return { serialId: data.serialId, timestamp: data.timestamp };
  }
);

export const executeContainerOcr = createAsyncThunk(
  "inspections/executeContainerOcr",
  async (photoData: {
    containerPhotoBlob: Blob;
    photoHeight: number;
    photoWidth: number;
    photoFormat: PhotoFormat;
    containerUuid: string;
  }) => {
    try {
      const {
        containerPhotoBlob,
        photoHeight,
        photoWidth,
        photoFormat,
        containerUuid,
      } = photoData;
      const metadata = { photoHeight, photoWidth, photoFormat };
      const containerData = await ocrContainerId(
        "containerOCR",
        containerPhotoBlob,
        containerUuid,
        metadata
      );

      if (!isLeft(containerData)) {
        const credentials = await Auth.currentCredentials();
        if (credentials.authenticated) {
          const userInfo = await Auth.currentUserInfo();
          const today = new Date(Date.now());

          const datastoreInspection = await DataStore.save(
            new Inspection({
              owner: userInfo.username,
              startedTime: today.toISOString().split("T")[0],
              container: {
                id: containerData.right.id,
                containerSize: containerData.right.containerSize,
                containerType: containerData.right.containerType,
                s3Path: containerData.right.s3Path,
                imgUrl: containerData.right.imgUrl,
              },
            })
          );

          const newInspection: InspectionState = {
            id: datastoreInspection.id,
            container: {
              id: containerData.right.id,
              containerSize: containerData.right.containerSize,
              containerType: containerData.right.containerType,
              s3Path: containerData.right.s3Path,
              imgUrl: containerData.right.imgUrl,
            },
          };

          return newInspection;
        } else {
          const userInfo = uuidv4();
          const today = new Date(Date.now());

          const datastoreInspection = await DataStore.save(
            new Inspection({
              owner: userInfo,
              startedTime: today.toISOString().split("T")[0],
              container: {
                id: containerData.right.id,
                containerSize: containerData.right.containerSize,
                containerType: containerData.right.containerType,
                s3Path: containerData.right.s3Path,
                imgUrl: containerData.right.imgUrl,
              },
            })
          );

          const newInspection: InspectionState = {
            id: datastoreInspection.id,
            container: {
              id: containerData.right.id,
              containerSize: containerData.right.containerSize,
              containerType: containerData.right.containerType,
              s3Path: containerData.right.s3Path,
              imgUrl: containerData.right.imgUrl,
            },
          };

          return newInspection;
        }
      } else {
        const credentials = await Auth.currentCredentials();
        if (credentials.authenticated) {
          const userInfo = await Auth.currentUserInfo();
          const today = new Date(Date.now());

          const datastoreInspection = await DataStore.save(
            new Inspection({
              owner: userInfo.username,
              startedTime: today.toISOString().split("T")[0],
              container: {
                id: "",
                s3Path: getS3PhotoUrl(
                  storageUri("containerOCR-id-" + containerUuid + ".png")
                ),
                imgUrl: getS3PhotoUrl(
                  storageUri("containerOCR-id-" + containerUuid + ".png")
                ),
              },
            })
          );

          const newInspection: InspectionState = {
            id: datastoreInspection.id,
            container: {
              id: "",
              s3Path: getS3PhotoUrl(
                storageUri("containerOCR-id-" + containerUuid + ".png")
              ),
              imgUrl: getS3PhotoUrl(
                storageUri("containerOCR-id-" + containerUuid + ".png")
              ),
            },
          };

          return newInspection;
        } else {
          const userInfo = uuidv4();
          const today = new Date(Date.now());

          const datastoreInspection = await DataStore.save(
            new Inspection({
              owner: userInfo,
              startedTime: today.toISOString().split("T")[0],
              container: {
                id: "",
                s3Path: getS3PhotoUrl(
                  storageUri("containerOCR-id-" + containerUuid + ".png")
                ),
                imgUrl: getS3PhotoUrl(
                  storageUri("containerOCR-id-" + containerUuid + ".png")
                ),
              },
            })
          );

          const newInspection: InspectionState = {
            id: datastoreInspection.id,
            container: {
              id: "",
              s3Path: getS3PhotoUrl(
                storageUri("containerOCR-id-" + containerUuid + ".png")
              ),
              imgUrl: getS3PhotoUrl(
                storageUri("containerOCR-id-" + containerUuid + ".png")
              ),
            },
          };

          return newInspection;
        }
      }
    } catch (e) {}
  }
);

export const executeContainerUpdateOcr = createAsyncThunk(
  "inspections/executeContainerOcr",
  async (photoData: {
    containerPhotoBlob: Blob;
    photoHeight: number;
    photoWidth: number;
    photoFormat: PhotoFormat;
    containerUuid: string;
  }) => {
    try {
      const {
        containerPhotoBlob,
        photoHeight,
        photoWidth,
        photoFormat,
        containerUuid,
      } = photoData;
      const metadata = { photoHeight, photoWidth, photoFormat };
      const containerData = await ocrContainerId(
        "containerOCR",
        containerPhotoBlob,
        containerUuid,
        metadata
      );

      if (!isLeft(containerData)) {
        const credentials = await Auth.currentCredentials();
        if (credentials.authenticated) {
          const userInfo = await Auth.currentUserInfo();
          const today = new Date(Date.now());

          const datastoreInspection = await DataStore.save(
            new Inspection({
              owner: userInfo.username,
              startedTime: today.toISOString().split("T")[0],
              container: {
                id: containerData.right.id,
                containerSize: containerData.right.containerSize,
                containerType: containerData.right.containerType,
                s3Path: containerData.right.s3Path,
                imgUrl: containerData.right.imgUrl,
              },
            })
          );

          const newInspection: InspectionState = {
            id: datastoreInspection.id,
            container: {
              id: containerData.right.id,
              containerSize: containerData.right.containerSize,
              containerType: containerData.right.containerType,
              s3Path: containerData.right.s3Path,
              imgUrl: containerData.right.imgUrl,
            },
          };

          return newInspection;
        } else {
          const userInfo = uuidv4();
          const today = new Date(Date.now());

          const datastoreInspection = await DataStore.save(
            new Inspection({
              owner: userInfo,
              startedTime: today.toISOString().split("T")[0],
              container: {
                id: containerData.right.id,
                containerSize: containerData.right.containerSize,
                containerType: containerData.right.containerType,
                s3Path: containerData.right.s3Path,
                imgUrl: containerData.right.imgUrl,
              },
            })
          );

          const newInspection: InspectionState = {
            id: datastoreInspection.id,
            container: {
              id: containerData.right.id,
              containerSize: containerData.right.containerSize,
              containerType: containerData.right.containerType,
              s3Path: containerData.right.s3Path,
              imgUrl: containerData.right.imgUrl,
            },
          };

          return newInspection;
        }
      }

      return inspectionData;
    } catch (e) {}
  }
);

export const inspectionSlice = createSlice({
  name: "INSPECTION",
  initialState,
  reducers: {
    createInspection: (state, action: PayloadAction<InspectionState>) => {
      const {
        payload: { id, container, seals },
      } = action;
      state.inspectionData.id = id;
      state.inspectionData.container = container;
      state.inspectionData.seals = seals;
    },

    updateInspection: (state, action: PayloadAction<InspectionState>) => {
      const { payload: inspection } = action;
      state.inspectionData = inspection;
    },

    resetState: (state) => {
      state.inspectionData = { id: "" };
      state.error = -1;
    },

    updateContainer: (
      state,
      action: PayloadAction<{ id?: string; container: ContainerState }>
    ) => {
      const {
        payload: { container },
      } = action;
      state.inspectionData = { ...state.inspectionData, container };
    },

    updateSeals: (
      state,
      action: PayloadAction<{ id?: string; seals: SealState[] }>
    ) => {
      const {
        payload: { id, seals },
      } = action;
      state.inspectionData.seals = seals;
    },

    updateSeal: (
      state,
      action: PayloadAction<{ id?: string; sealId: string; seal: SealState }>
    ) => {
      const {
        payload: { id, sealId, seal },
      } = action;
      if (state.inspectionData.seals !== undefined) {
        const sealIndex = state.inspectionData.seals.findIndex(
          (seal) => seal.timestamp === sealId
        );
        sealIndex !== -1
          ? (state.inspectionData.seals[sealIndex] = seal)
          : state.inspectionData.seals.push(seal);
      } else {
        state.inspectionData.seals = [seal];
      }
    },
    addPhoto: (state, action: PayloadAction<PhotoInfoState>) => {
      const {
        payload: { type, imagePath, format, timestamp, height, width },
      } = action;
      state.photos.push({ type, imagePath, format, timestamp, height, width });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createInspectionThunk.fulfilled, (state, action) => {
      state.inspectionData = action.payload;
    }),
      builder.addCase(executeContainerOcr.pending, (state) => {}),
      builder.addCase(executeContainerOcr.fulfilled, (state, action) => {
        if (action.payload) {
          state.inspectionData.container = action.payload.container;
          state.inspectionData.id = action.payload.id;
        }
      }),
      builder.addCase(executeSealOcr.pending, (state) => {}),
      builder.addCase(deleteSeal.pending, (state, action) => {
        // Delete seal from seal list in inspection data
        state.inspectionData.seals = state.inspectionData.seals?.filter(
          (seal) =>
            seal.s3Path + seal.serialId !==
            action.meta.arg.s3 + action.meta.arg.serialId
        );
      }),
      builder.addCase(executeSealOcr.fulfilled, (state, action) => {
        if (isRight(action.payload)) {
          state.inspectionData.seals = [
            ...(state.inspectionData.seals || []),
            ...action.payload.right,
          ];
        }
      });
  },
});

export const {
  createInspection,
  updateInspection,
  updateContainer,
  resetState,
  updateSeal,
  updateSeals,
  addPhoto,
} = inspectionSlice.actions;

// (Convert Data from string to the specific type for each field)
export const getInspection = (id?: string) => (state: RootState) =>
  state.inspections.inspectionData;

//Selector to get container data
export const selectContainer = (id?: string) => (state: RootState) =>
  state.inspections.inspectionData.container;
//Selector to get seals data
export const selectSeals = (id?: string) => (state: RootState) =>
  state.inspections.inspectionData.seals;
//Selector to get seals data count
export const selectSealsCount = (id?: string) => (state: RootState) =>
  state.inspections.inspectionData.seals !== undefined
    ? state.inspections.inspectionData.seals.length
    : 0;
//Selector to get sealData (Using SealId with .find in array)

//Selector to get photo info from container

//Selector to get photo info from seal (Using SealId with .find in array)

export const selectError = (id?: string) => (state: RootState) =>
  state.inspections.error;
export const selectStarted = (id?: string) => (state: RootState) =>
  state.inspections.started;
// export const selectErrorLoading = createSelector([selectError, selectLoading], (error, loading) => { return { error, loading } })

// export default inspectionSlice.reducer
