import React from "react";
import { View, StyleSheet, ViewStyle, ImageStyle } from "react-native";

// Components
import ImagePicker from "../../../../components/ImagePicker";
import dict from "../../../../i18n";
import Dropdown from "../../../../components/Dropdown";
import { Theme } from "../../../../styles";
import Input from "../../../../../shared/molecules/Input";

// Constants
import {
  SealState,
  ValidationState,
  SealOwnerValue,
  OWNER_SEAL_OPTIONS,
  SEAL_OWNERS_SET,
} from "../../../../types";
import { useSelector } from "react-redux";
import { deleteSeal, getInspection } from "../../../../store/inspectionSlice";
import { RootStackParamList } from "../../../../types";
import { StackNavigationProp } from "@react-navigation/stack";
import i18nDictionary from "../../../../i18n";
import { Sizes } from "../../../../../shared/constants/sizes";
import { useAppDispatch } from "../../../../store/hooks";
import InputWarningMessage from "../../../../../shared/molecules/InputWarningMessage";

// Helpers
import { validateSeal, SealValidationErrors } from "../../../../helpers/seal";

export interface SealStyle {
  content: ViewStyle;
  contentBox: ViewStyle;
  doubleInput: ViewStyle;
  location: ViewStyle;
  photo: ViewStyle;
  photoSize: ViewStyle;
  editPhoto: ViewStyle;
  center: ViewStyle;
  logo: ImageStyle;
  validation: ViewStyle;
  input: ViewStyle;
}

interface SealProps {
  seal: SealState;
  onChangeSeal: (seal: SealState) => void;
  sealIndex: number;
  navigation: StackNavigationProp<
    RootStackParamList,
    "CurrentInspection",
    undefined
  >;
}

const SealForm = ({ seal, onChangeSeal, sealIndex, navigation }: SealProps) => {
  const inspectionRedux = useSelector(getInspection());
  const dispatch = useAppDispatch();

  const [errors, setErrors] = React.useState(validateSeal(seal));
  const hasError = errors.length > 0;

  const onOwnerChange = (value: string) => {
    if (SEAL_OWNERS_SET.has(value)) {
      const sealOwner = value as SealOwnerValue;
      onChangeSeal({
        ...seal,
        owner: sealOwner,
        validation: ValidationState.DEFAULT,
      });
    }
  };

  const onSealIdChange = (value: string) => {
    const updatedSeal = { ...seal, serialId: value };
    onChangeSeal(updatedSeal);
  };

  React.useEffect(() => {
    // Re-validate the seal whenever it changes
    const newErrors = validateSeal(seal);
    setErrors(newErrors);
  }, [seal]);

  return (
    <View style={styles.contentBox}>
      <ImagePicker
        onPress={() => {
          dispatch(
            deleteSeal({
              inspectionId: inspectionRedux.id,
              timestamp: seal.timestamp,
              serialId: seal.serialId,
              s3: seal.s3Path,
            })
          );
        }}
        imageUrl={seal.imgUrl}
        type="SEAL"
      />

      <Input
        label={i18nDictionary.screens.Seal.labelSealId()}
        onChange={onSealIdChange}
        value={seal.serialId}
        error={hasError}
      />
      {errors.includes(SealValidationErrors.MISSING_SERIAL_ID) && (
        <InputWarningMessage
          warningMsg={i18nDictionary.screens.Seal.missingSealSerialIdWarning()}
        />
      )}

      <View style={styles.dropdown}>
        <Dropdown
          label={dict.screens.Seal.labelOwner()}
          selectedValue={seal.owner}
          values={OWNER_SEAL_OPTIONS}
          onValueChange={onOwnerChange}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dropdown: {
    width: "100%",
    marginTop: 20,
    marginBottom: 10,
    marginRight: 10,
    borderWidth: 1,
    borderColor: Theme.colors.border,
    borderRadius: Sizes.borderRadiusS,
    height: Sizes.heightM,
  },
  contentBox: {
    backgroundColor: Theme.colors.title,
    padding: Sizes.paddingM,
    borderBottomEndRadius: 15,
    borderBottomLeftRadius: 15,
    borderWidth: 1,
    borderColor: Theme.colors.primary,
  },
});

export default SealForm;
