import { Dictionary } from "../index";

const pt: Dictionary = {
  screens: {
    NotFound: {
      message: "Página não encontrada",
    },
    Main: {
      welcome: "Bem-vindo ao nosso aplicativo de inspeção!",
      newInspection: "Iniciar nova inspeção",
      inspections: "Inspeções",
      checkNewUpdates: "Verificar atualizações",
      pastInspections: "Inspeções passadas",
      version: "Versão",
      ongoing: "Em andamento",
      scheduled: "Agendado",
      noScheduledInspections:
        "Não há inspeções agendadas ou reconhecidas no momento.",
      noOngoingInspections: "Não há inspeções em andamento no momento.",
      createdAt: "Criado em",
    },
    Alerts: {
      checkUpdateTitle: "Nova atualização",
      checkUpdateText:
        "Há uma nova atualização disponível. Clique em recarregar para visualizá-la",
      checkUpdateButton: "Recarregar",
      noUpdatesTitle: "Info",
      noUpdatesText: "Não há novas atualizações disponíveis!",
      noUpdatesButton: "Fechar",
      errorAtReloadTitle: "Error",
      errorAtReloadText:
        "Não foi possível recarregar o aplicativo, tente novamente mais tarde!",
      errorAtReloadButton: "Fechar",
      deleteSealTitle: "Confirmação",
      deleteSealText: "Tens certeza que deseja remover esse precinto?",
      deleteSealButton: "Confirmar",
      Confirm: "Confirmar",
      Cancel: "Cancelar",
    },
    Inspections: {
      hideDetails: "Ocultar detalhes",
      showDetails: "Exibit detalhes",
      scale: "Escala",
      category: "Categoria",
      container: "Foto",
      device: "Dispositivo",
      tare: "Tara",
      delete: "Excluir",
      ongoing: "Em andamento",
      completed: "Finalizada",
      incomplete: "Incompleta",
      submit: "Medir",
      dashboard: "Dashboard",
      newInspection: "Nova medida",
      successMessageInspectionSaved: "Inspeção salva com sucesso!",
      errorMessageSubmit:
        "Erro ao submeter dados das medidas solicitadas, tente novamente!",
      errorMessageUnknownDevice: "Dispositivo desconhecido",
      errorMessageUnknownContainer: "Contêiner desconhecido",
      errorMessageUnboundTimeout: "Tempo esgotado",
      errorMessageUnauthorizedRequest: "Não autorizado",
      errorMessageDeviceNotReady:
        "O servidor não está pronto para iniciar uma nova medição de volume",
      errorMessageVolumeComputation: "Erro de cálculo de volume",
      errorMessageUnexpected: "Houve um problema no processamento do servidor",
      errorMessageUnknownUsecase: "Caso de uso desconhecido",
      errorMessageUnboundDelay: "Atraso no resposta do servidor",
      errorMessageInvalidContentType: "Formato de conteúdo inválido",
      newSeal: "Novo precinto",
      newContainer: "Novo contentor",
      newPhoto: "Nova foto",
      instructions: "Instruções",
      takePictureAgain: "Tirar foto novamente",
      containerIncomplete: "Dados incompletos",
      incompleteSealTitle: "Precinto incompleto",
      incompleteSealText:
        "Confirma que o container não possui nenhum precinto?",
      finishInspectionTitle: "Finalizar inspeção",
      finishInspectionText: "Tem certeza que deseja finalizar a inspeção?",
      deleteInspectionTitle: "Excluir inspeção",
      deleteInspectionText: "Tem certeza que deseja excluir a inspeção?",
      finishedInspectionTitle: "Sucesso",
      finishedInspectionText: "Inspeção realizada com sucesso!",
      cancelInspectionTitle: "Cancelar inspeção",
      cancelInspectionText: "Tem certeza que deseja cancelar a inspeção?",
      incompleteDataTitle: "Dados incompletos",
      incompleteDataText:
        "Para finalizar a inspeção, é necessário que preencha todos os campos obrigatórios!",
      gpsPermissionsTitle: "AwesomeAlert",
      gpsPermissionsMessage:
        "Para dar continuidade à inspeção é necessário confirmar permissão de GPS, caso contrário o processo será cancelada",
      gpsPermissionsCancel: "Não aceitar e voltar",
      gpsPermissionsConfirm: "Permitir",
      gpsNoPermissionAlertTime: "Permissão não concedida",
      gpsNoPermissionAlertMessage:
        "Para dar continuidade à inspeção é necessário confirmar permissão de GPS, caso contrário o processo será cancelada",
      gpsNoPermissionAlertCancel: "Negar",
      gpsNoPermissionAlertRequest: "Solicitar",
      error: "Erro",
      close: "Fechar",
      tryAgain: "Tentar de novo",
      noContainerFoundMessage:
        " Número de série do contentor não encontrado, preencha o campo do container ID manualmente!",
      noSealFoundMessage:
        "Código do selo não encontrado, deseja tentar novamente?",
      loading: "A carregar...",
      loadingContainer: "Estamos a digitalizar o contentor",
      loadingSeal: "Estamos a ler o selo.",
      instructionContainerPhoto: "Tirar foto do container",
      containerTypes: {
        container: "Posicionado",
        opening: "Abertura",
        hallway: "Corredor",
        extracted_merch: "Mercadoria Ext.",
        label_eq: "Etiqueta EQ",
        closing: "Finalização",
        others: "Outros",
      },
    },
    Seal: {
      header: "Precinto",
      labelSealId: "Precinto ID",
      labelCategory: "Categoria",
      labelPosition: "Validação do precinto",
      labelLocation: "Localização",
      labelOwner: "Proprietário",
      labelSealViolationDetails: "Motivo da violação",
      defaultViolatedReason: "Precinto se encontra violado",
      newPhoto: "Adicionar Foto",
      deletePhotoTitle: "Substituir imagem",
      deletePhotoMessage: "Tem certeza que deseja editar a foto tirada?",
      cancel: "Cancelar",
      confirm: "Confirmar",
      sealOnLeftDoor: "Precinto localizado na porta esquerda",
      instructionSealPhoto: "Tirar foto do precinto",
      missingSealSerialIdWarning: "Por favor, preencha o campo do precinto ID",
      owners: {
        other: "Outro",
      },
      categories: {
        empty: "",
        orginal: "Original",
        vessel: "Embarcação",
        terminal: "Terminal",
        inspectionTeam: "EQ",
        inspectionCertificate: "Inspeção",
        other: "Outro",
      },
      sizes: {
        empty: "",
        "22": "22",
        "25": "25",
        "42": "42",
        "45": "45",
        L2: "L2",
        L5: "L5",
        M2: "M2",
        M5: "M5",
      },
    },
    Container: {
      ownerPrefixErrorMessage: "Prefixo informado não é válido",
      checkDigitNoneErrorMessage: "Dígito não existe para os dados preenchidos",
      emptyOwnerPrefixErrorMessage: "Campo de Prefixo obrigatório",
      emptySerialNumberErrorMessage: "Número Serial obrigatório",
      emptyDigitErrorMessage: "Dígito obrigatório",
      incompleteInput: "Dados do container estão incompletos",
      checkDigitErrorMessage: "Dígito incompatível com os dados preenchidos",
      header: "Container",
      labelOwnerPrefix: "Prefixo do proprietário",
      labelSerialNumber: "Número de Série",
      labelDigit: "Dígito",
      labelContainerId: "Id do Container",
      labelContainerSize: "Tamanho",
      labelContainerType: "Tipo",
      newPhoto: "Adicionar Foto",
      deletePhotoTitle: "Substituir imagem",
      deletePhotoMessage: "Tem certeza que deseja editar a foto tirada?",
      deletePhotoCancel: "Cancelar",
      deletePhotoConfirm: "Confirmar",
      instructionContainerPhoto: "Tirar foto do container",
      types: {
        empty: "",
        G1: "G1",
        R1: "R1",
        U1: "U1",
        P1: "P1",
        T1: "T1",
      },
    },
    LastInspections: {
      photo: "Foto",
      container: "Contentor",
      service: "Serviço",
      petitioner: "Peticionário",
      level: "Nível",
      expedient: "Expediente",
      dock: "Doca",
    },
  },
  cognito: {
    SignIn: "Entrar",
    SignUp: "Inscreva-se!",
    SignUpText: "Não tem uma conta?",
    SignUpTitle: "Crie um conta para fazer suas inspeções!",
    SendCode: "Enviar código novamente.",
    SignOut: "Encerrar sessão",
    Username: "E-mail",
    Password: "Senha",
    Email: "E-mail",
    Name: "Nome",
    PhoneNumber: "Telefone",
    Confirm: "Confirmar",
    Cancel: "Cancelar",
    Submit: "Enviar",
    Skip: "Pular",
    Verify: "Verificar",
    Code: "Código",
    Main: "Realizar inspeção",
    Operated: "Operado por: ",
    Anonymous: "Acessar de forma anônima",
    IncorrectLogin: "Nome de usuário ou senha incorreta",
    ChangePassword: "Alterar senha",
    OldPassword: "Senha anterior",
    NewPassword: "Senha nova",
    ConfirmPassword: "Repita a senha",
    ForgotPassword: "Esqueceu a senha?",
    EmailConfirmCode: "Alteração da senha",
    ConfirmationCode: "Código de confirmação",
    FirstAccess: "Primeiro Acesso",
    FirstAccessMessage1: "Primeiro Acesso message 1",
    FirstAccessMessage2: "Primeiro Acesso message 2",
    SuccessMessageChangePassword: "Senha alterada com sucesso!",
    SuccessMessageSubmit: "Medidas enviadas com sucesso!",
    SuccessMessageTara: "Tara enviada com sucesso!",
    SuccessMessageLogin: "Login realizado com sucesso!",
    ErrorMessageDifferentPasswords:
      "A confirmação da senha não corresponde com a nova senha",
    ErrorGeneralMessage: "Houve um erro, tente novamente!",
    ErrorFieldsEmpty: "É necessário preencher os campos obrigatórios!",
    ForcePwdChangeException:
      "É necessário ralizar primeiro acesso! Caso tenha esquecido senha inicial, contactar suporte",
    NotAuthorizedException: "Senha incorreta, tente novamente!",
    UserNotFoundException:
      "Combinação de nome de usuário/id de cliente não encontrada.",
    CodeMismatchException:
      "Código de verificação inválido fornecido. Tente novamente.",
    InvalidPasswordException: "A senha não é longa o suficiente",
    InvalidParameterException: "Campo de usuário e/ou senha vazios",
    PasswordResetRequiredException:
      "Redefinição de senha necessária para o usuário",
    NotAuthorizedExceptionTitle: "Erro: Não autorizada",
    UserNotFoundExceptionTitle: "Erro: Usuário/Id",
    CodeMismatchExceptionTitle: "Erro: Código de verificação",
    InvalidPasswordExceptionTitle: "Erro: Senha",
    InvalidParameterExceptionTitle: "Erro: Campo inválido",
    PasswordResetRequiredExceptionTitle: "Erro: Redefiniçao de senha",
    CodeDeliveryFailureException:
      "O código de verificação não é entregue com sucesso.",
    ForbiddenException:
      "Pedido não permitido, contacte o suporte para obter mais informações.",
    InternalErrorException:
      "Ocorreu um erro interno, contacte o suporte para obter mais informações.",
    InvalidLambdaResponseException:
      "Ocorreu um erro interno, contacte o suporte para obter mais informações.",
    InvalidSmsRoleAccessPolicyException:
      "Ocorreu um erro interno, contacte o suporte para obter mais informações.",
    InvalidSmsRoleTrustRelationshipException:
      "Ocorreu um erro interno, contacte o suporte para obter mais informações.",
    ResourceNotFoundException:
      "O nosso serviço não consegue encontrar o recurso solicitado",
    TooManyRequestsException:
      "Demasiados pedidos, aguarde algum tempo e faça o pedido novamente",
    UnexpectedLambdaException:
      "Ocorreu um erro interno, contacte o suporte para obter mais informações.",
    UserLambdaValidationException:
      "Ocorreu um erro interno, contacte o suporte para obter mais informações.",
    UsernameExistsException: "O email já existe, escolha outro!",
    EmptyFieldEmail: "Digite o seu endereço de email",
    EmptyFieldName: "Digite o seu nome",
    EmptyFieldPassword: "Digite uma senha com pelo menos 8 caracteres",
    EmptyFieldPasswordConfirm: "Digite a senha igual à do campo anterior",
  },
};

export default pt;
