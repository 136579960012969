import React from "react";

import { Button, Dialog, Portal } from "react-native-paper";
import Text from "../atoms/Text";

import { Theme } from "../../src/styles";

interface TwoButtonsAlertDialogProps {
  isVisible: boolean;
  title: string;
  message: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  onDismiss: () => void;
  onPrimaryButtonPress: () => void;
  onSecondaryButtonPress: () => void;
}

function TwoButtonsAlertDialog({
  isVisible,
  title,
  message,
  primaryButtonText,
  secondaryButtonText,
  onDismiss: handleDismiss,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
}: TwoButtonsAlertDialogProps) {
  return (
    <Portal>
      <Dialog
        visible={isVisible}
        onDismiss={handleDismiss}
        style={{
          backgroundColor: Theme.colors.background,
        }}
      >
        <Dialog.Title
          style={{ color: Theme.colors.primary_darker, fontWeight: "bold" }}
        >
          {title}
        </Dialog.Title>
        <Dialog.Content>
          <Text>{message}</Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            onPress={onSecondaryButtonPress}
            mode={"outlined"}
            labelStyle={{
              color: Theme.colors.primary_darker,
            }}
            contentStyle={{
              padding: 8,
              borderRadius: 4,
            }}
            style={{
              marginRight: 10,
            }}
          >
            {secondaryButtonText}
          </Button>
          <Button
            onPress={onPrimaryButtonPress}
            labelStyle={{
              color: Theme.colors.title,
              fontSize: 16,
            }}
            contentStyle={{
              backgroundColor: Theme.colors.primary,
              padding: 8,
              borderRadius: 4,
            }}
          >
            {primaryButtonText}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

export default TwoButtonsAlertDialog;
