import { Dictionary } from "../index";

const es: Dictionary = {
  screens: {
    NotFound: {
      message: "Página no encontrada",
    },
    Main: {
      welcome: "¡Bienvenido a la aplicación de inspección!",
      newInspection: "Comenzar nueva inspección",
      inspections: "Inspecciones",
      checkNewUpdates: "Buscar actualizaciones",
      pastInspections: "Inspecciones anteriores",
      version: "Versión",
      ongoing: "En marcha",
      scheduled: "Programada",
      noScheduledInspections:
        "No hay inspecciones programadas o reconocidas en este momento",
      noOngoingInspections: "No hay inspecciones en curso en este momento",
      createdAt: "Creado",
    },
    Alerts: {
      checkUpdateTitle: "¡Nueva actualización!",
      checkUpdateText:
        "Hay una nueva actualización disponible para nuestra aplicación. ¡Actualiza ahora para verlo!",
      checkUpdateButton: "Actualizar",
      noUpdatesTitle: "Info",
      noUpdatesText: "No hay nuevas actualizaciones",
      noUpdatesButton: "Cerrar",
      errorAtReloadTitle: "Error",
      errorAtReloadText:
        "No se pudo volver a cargar la aplicación, Inténtalo de nuevo más tarde",
      errorAtReloadButton: "Cerrar",
      deleteSealTitle: "Confirmación",
      deleteSealText: "¿Está seguro de que desea eliminar este elemento?",
      deleteSealButton: "Confirmar",
      Confirm: "Confirmar",
      Cancel: "Cancelar",
    },
    Inspections: {
      hideDetails: "Ocultar detalles",
      showDetails: "Mostrar detalles",
      scale: "Escala",
      category: "Categoría",
      container: "Foto",
      device: "Dispositivo",
      tare: "Tara",
      delete: "Eliminar",
      ongoing: "En curso",
      completed: "Completada",
      incomplete: "Incompleta",
      submit: "Enviar",
      dashboard: "Panel de Control",
      newInspection: "Nueva Inspección",
      successMessageInspectionSaved: "Inspección guardada con éxito",
      errorMessageSubmit:
        "Error al enviar los datos de medición solicitados, inténtelo de nuevo",
      errorMessageUnknownDevice: "Dispositivo desconocido",
      errorMessageUnknownContainer: "Contenedor desconocido",
      errorMessageUnboundTimeout: "Tiempo de espera caducado",
      errorMessageUnauthorizedRequest: "No autorizado",
      errorMessageDeviceNotReady:
        "El servidor no está listo para iniciar una nueva medición de volumen",
      errorMessageVolumeComputation: "Error de cálculo de volumen",
      errorMessageUnexpected: "Un problema en el servidor",
      errorMessageUnknownUsecase: "Caso de uso desconocido",
      errorMessageUnboundDelay: "Retraso en la respuesta del servidor",
      errorMessageInvalidContentType: "Formato de contenido no válido",
      newSeal: "Nuevo Precinto",
      newContainer: "Nuevo Contenedor",
      newPhoto: "Nueva foto",
      instructions: "Instrucciones",
      takePictureAgain: "Realizar nuevamente",
      containerIncomplete: "Datos incompletos",
      incompleteSealTitle: "Precinto incompleto",
      incompleteSealText: "¿Confirmas que el contenedor no tiene precintos?",
      finishInspectionTitle: "Terminar la inspección",
      finishInspectionText:
        "¿Estás seguro de que quieres terminar esta inspección?",
      deleteInspectionTitle: "Eliminar inspección",
      deleteInspectionText:
        "¿Estás seguro de que quieres eliminar esta inspección?",
      finishedInspectionTitle: "Éxito",
      finishedInspectionText: "¡Inspección finalizada con éxito!",
      cancelInspectionTitle: "Cancelar inspección",
      cancelInspectionText: "¿Confirmas que quiere cancelar su inspección?",
      incompleteDataTitle: "Datos incompletos",
      incompleteDataText:
        "Para finalizar su inspección, debe completar todos los campos requeridos",
      gpsPermissionsTitle: "Alerta",
      gpsPermissionsMessage:
        "Para continuar el registro es necesario confirmar permiso de GPS, de lo contrario, el proceso será cancelado",
      gpsPermissionsCancel: "No aceptar y regresar",
      gpsPermissionsConfirm: "Permitir",
      gpsNoPermissionAlertTime: "Permiso no concedido",
      gpsNoPermissionAlertMessage:
        "Para continuar el registro es necesario confirmar permiso de GPS, de lo contrario, el proceso será cancelado",
      gpsNoPermissionAlertCancel: "Negar",
      gpsNoPermissionAlertRequest: "Solicitar",
      error: "Error",
      close: "Cerrar",
      tryAgain: "Realizar de nuevo",
      noContainerFoundMessage:
        "No se encontró la identificación del contenedor, complete la identificación del contenedor manualmente",
      noSealFoundMessage:
        "No fue encontrado Id del Precinto, desea intentar otra vez?",
      loading: "Cargando",
      loadingContainer: "Estamos digitalizando el contenedor",
      loadingSeal: "Estamos leyendo el precinto",
      instructionContainerPhoto: "Realiza una foto al contenedor",
      containerTypes: {
        container: "Posicionado",
        opening: "Apertura",
        hallway: "Pasillo",
        extracted_merch: "Mercancía Extraída",
        label_eq: "Etiqueta EQ",
        closing: "Cierre",
        others: "Otros",
      },
    },
    Seal: {
      header: "Precinto",
      labelSealId: "ID de precinto",
      labelCategory: "Categoría",
      labelPosition: "¿El precinto está íntegro?",
      labelLocation: "Localización",
      labelOwner: "Proprietario",
      labelSealViolationDetails: "Observaciones de la violación",
      defaultViolatedReason: "El precinto tiene una violación",
      newPhoto: "Añadir foto",
      deletePhotoTitle: "Sustituir imagen",
      deletePhotoMessage:
        "¿Estás seguro que deseas modificar la fotografía realizada?",
      cancel: "Cancelar",
      confirm: "Confirmar",
      sealOnLeftDoor: "Precinto en la puerta izquierda",
      instructionSealPhoto: "Realiza una foto al precinto",
      missingSealSerialIdWarning: "Por favor, introduzca el ID del precinto",
      owners: {
        other: "Otro",
      },
      categories: {
        empty: "",
        orginal: "Original",
        vessel: "Naviera",
        terminal: "Terminal",
        inspectionTeam: "EQ",
        inspectionCertificate: "Inspección",
        other: "Otro",
      },
      sizes: {
        empty: "",
        "22": "22",
        "25": "25",
        "42": "42",
        "45": "45",
        L2: "L2",
        L5: "L5",
        M2: "M2",
        M5: "M5",
      },
    },
    Container: {
      ownerPrefixErrorMessage: "El prefijo ingresado no es válido",
      checkDigitNoneErrorMessage:
        "El dígito no existe para los datos ingresados",
      emptyOwnerPrefixErrorMessage: "El campo del prefijo es obligatorio",
      emptySerialNumberErrorMessage:
        "El campo del número serial es obligatorio",
      emptyDigitErrorMessage: "El campo del digito es obligatorio",
      incompleteInput: "Los datos del contenedor están incompletos",
      checkDigitErrorMessage: "Dígito incompatible con los datos introducidos",
      header: "Contenedor",
      labelOwnerPrefix: "Prefijo",
      labelSerialNumber: "Matrícula",
      labelDigit: "Dígito",
      labelContainerId: "ID del Contenedor",
      labelContainerSize: "Tamaño",
      labelContainerType: "Tipo",
      newPhoto: "Añadir foto",
      deletePhotoTitle: "Sustituir imagen",
      deletePhotoMessage:
        "¿Estás seguro que deseas modificar la fotografía realizada?",
      deletePhotoCancel: "Cancelar",
      deletePhotoConfirm: "Confirmar",
      instructionContainerPhoto: "Realiza una foto al contenedor",
      types: {
        empty: "",
        G1: "G1",
        R1: "R1",
        U1: "U1",
        P1: "P1",
        T1: "T1",
      },
    },
    LastInspections: {
      photo: "Photo",
      container: "Contentor",
      service: "Servicio",
      petitioner: "Peticionario",
      level: "Nivel",
      dock: "Muelle",
      expedient: "Expediente",
    },
  },
  cognito: {
    SignIn: "Iniciar sesión",
    SignUp: "Inscribase!",
    SignUpText: "¿No tienes una cuenta?",
    SignUpTitle: "Crea una cuenta para hacer tus inspecciones",
    SendCode: "Enviar código de nuevo.",
    SignOut: "Desconectar",
    Username: "Email",
    Password: "Contraseña",
    Email: "Email",
    Name: "Nombre",
    PhoneNumber: "Teléfono",
    Confirm: "Confirmar",
    Cancel: "Cancelar",
    Submit: "Enviar",
    Skip: "Omitir",
    Verify: "Verificar",
    Code: "Código",
    Main: "Realizar Inspección",
    Operated: "Operado por: ",
    Anonymous: "Acceso Anónimo",
    IncorrectLogin: "Nombre de usuario o contraseña incorrecto",
    ChangePassword: "Cambiar contraseña",
    OldPassword: "Contraseña anterior",
    NewPassword: "Nueva contraseña",
    ConfirmPassword: "Repite la contraseña",
    ForgotPassword: "Olvidé la contraseña",
    EmailConfirmCode: "Cambio de contraseña",
    ConfirmationCode: "Código de confirmación",
    FirstAccess: "Primer Acceso",
    FirstAccessMessage1: "Introduzca una nueva contraseña",
    FirstAccessMessage2: "Debe tener un mínimo de 8 caracteres",
    SuccessMessageChangePassword: "Contraseña modificada con éxito",
    SuccessMessageSubmit: "¡Medida enviada con éxito!",
    SuccessMessageTara: "¡Tara enviada con éxito!",
    SuccessMessageLogin: "¡Sesión iniciado con éxito!",
    ErrorMessageDifferentPasswords:
      "La confirmación de la contraseña no coincide",
    ErrorGeneralMessage: "Hubo un error, por favor intente de nuevo",
    ErrorFieldsEmpty: "Debes llenar los campos obligatorios!",
    ForcePwdChangeException:
      "Necesita hacer el primer acceso. Si olvidó la primera contraseña, comuníquese con el Área PIF",
    NotAuthorizedException: "Contraseña incorrecta, inténte de nuevo",
    UserNotFoundException:
      "No se encontró la combinación de nombre de usuario/ID de usuario",
    CodeMismatchException:
      "Se proporcionó un código de verificación no válido, inténtelo de nuevo",
    InvalidPasswordException:
      "La contraseña no cumple con la política: la contraseña no es lo suficientemente larga",
    InvalidParameterException:
      "Campo de nombre de usuario y/o contraseña vacío",
    PasswordResetRequiredException:
      "Restablecimiento de contraseña requerido por el usuario",
    NotAuthorizedExceptionTitle: "Error: No permitido",
    UserNotFoundExceptionTitle: "Error: Usuario/ID no encontrado",
    CodeMismatchExceptionTitle: "Error: Código de verificación",
    InvalidPasswordExceptionTitle: "Error: Contraseña",
    InvalidParameterExceptionTitle: "Error: Campo inválido",
    PasswordResetRequiredExceptionTitle:
      "Error: Restablecimiento de contraseña",
    CodeDeliveryFailureException:
      "No se pudo enviar con éxito el código de verificación.",
    ForbiddenException:
      "Solicitud no permitida, póngase en contacto con el soporte para obtener más información.",
    InternalErrorException:
      "Se produjo un error interno, póngase en contacto con el soporte para obtener más información.",
    InvalidLambdaResponseException:
      "Se produjo un error interno, póngase en contacto con el soporte para obtener más información.",
    InvalidSmsRoleAccessPolicyException:
      "Se produjo un error interno, póngase en contacto con el soporte para obtener más información.",
    InvalidSmsRoleTrustRelationshipException:
      "Se produjo un error interno, póngase en contacto con el soporte para obtener más información.",
    ResourceNotFoundException:
      "Nuestro servicio no puede encontrar el recurso solicitado",
    TooManyRequestsException:
      "Demasiadas solicitudes, espere un tiempo y vuelva a solicitar",
    UnexpectedLambdaException:
      "Se produjo un error interno, póngase en contacto con el soporte para obtener más información.",
    UserLambdaValidationException:
      "Se produjo un error interno, póngase en contacto con el soporte para obtener más información.",
    UsernameExistsException:
      "El correo electrónico ya existe en nuestro grupo de usuarios, elija otro!",
    EmptyFieldEmail: "Ingrese su dirección de correo electrónico",
    EmptyFieldName: "Ingrese su nombre",
    EmptyFieldPassword: "Ingrese una contraseña con al menos 8 caracteres",
    EmptyFieldPasswordConfirm:
      "Ingrese la contraseña igual que en el campo anterior",
  },
};

export default es;
