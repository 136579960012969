import { Dictionary } from "../index";

const en: Dictionary = {
  screens: {
    NotFound: {
      message: "Page not found",
    },
    Main: {
      welcome: "Welcome to our inspection app!",
      newInspection: "Start new inspection",
      inspections: "Inspections",
      checkNewUpdates: "Check for updates",
      pastInspections: "Past inspections",
      version: "Version",
      ongoing: "Ongoing",
      scheduled: "Scheduled",
      noScheduledInspections:
        "There are no scheduled or acknowledged inspections at this time.",
      noOngoingInspections: "There are no ongoing inspections at this time.",
      createdAt: "Created at",
    },
    Alerts: {
      checkUpdateTitle: "New update!",
      checkUpdateText:
        "There's a new update available for our app. Reload now for viewing it!",
      checkUpdateButton: "Reload",
      noUpdatesTitle: "Info",
      noUpdatesText: "There's no new update available!",
      noUpdatesButton: "Close",
      errorAtReloadTitle: "Error",
      errorAtReloadText: "Could not reload the app, try again later!",
      errorAtReloadButton: "Close",
      deleteSealTitle: "Confirmation",
      deleteSealText: "Are you sure you want to delete this item?",
      deleteSealButton: "Confirm",
      Confirm: "Confirm",
      Cancel: "Cancel",
    },
    Inspections: {
      hideDetails: "Hide details",
      showDetails: "Show details",
      scale: "Port-Call",
      category: "Category",
      container: "Photo",
      device: "Device",
      tare: "Tare",
      delete: "Delete",
      ongoing: "Ongoing",
      incomplete: "Incomplete",
      completed: "Completed",
      submit: "Submit",
      dashboard: "Dashboard",
      newInspection: "New Inspection",
      successMessageInspectionSaved: "Inspection saved with success",
      errorMessageSubmit: "Error at submitting required Inspection, try again!",
      errorMessageUnknownDevice: "Unknown device",
      errorMessageUnknownContainer: "Unknown container",
      errorMessageUnboundTimeout: "Unbound timeout",
      errorMessageUnauthorizedRequest: "Not Authorized",
      errorMessageDeviceNotReady:
        "Server isn't ready to start a new volume Inspectionment",
      errorMessageVolumeComputation: "Volume computation error",
      errorMessageUnexpected: "A problem in the server",
      errorMessageUnknownUsecase: "Unknown use case",
      errorMessageUnboundDelay: "Unbound Delay",
      errorMessageInvalidContentType: "Invalid Content Format",
      newSeal: "New Seal",
      newContainer: "New Container",
      newPhoto: "New photo",
      instructions: "Instructions",
      takePictureAgain: "Take picture again",
      containerIncomplete: "Data incomplete",
      incompleteSealTitle: "Seal incomplete",
      incompleteSealText: "Do you confirm that the container has no seals?",
      finishInspectionTitle: "Finish inspection",
      finishInspectionText:
        "Are you sure that you want to finish this inspection?",
      deleteInspectionTitle: "Delete inspection",
      deleteInspectionText:
        "Are you sure that you want to delete this inspection?",
      finishedInspectionTitle: "Success",
      finishedInspectionText: "Inspection finished with success!",
      cancelInspectionTitle: "Cancel inspection",
      cancelInspectionText: "Are you sure you want to cancel your inspection?",
      incompleteDataTitle: "Incomplete data",
      incompleteDataText:
        "To finish your inspection, you need to fulfill all the required fields!",
      gpsPermissionsTitle: "AwesomeAlert",
      gpsPermissionsMessage:
        "To continue the inspection, it is necessary to confirm GPS permission, otherwise the process will be canceled",
      gpsPermissionsCancel: "Do not accept and return",
      gpsPermissionsConfirm: "Allow",
      gpsNoPermissionAlertTime: "Permision not granted",
      gpsNoPermissionAlertMessage:
        "To continue the inspection, it is necessary to grant GPS permission, otherwise the process will be canceled",
      gpsNoPermissionAlertCancel: "Deny",
      gpsNoPermissionAlertRequest: "Request",
      error: "Error",
      close: "Close",
      tryAgain: "Try Again",
      noContainerFoundMessage:
        "No container ID found, fulfill the container id manually",
      noSealFoundMessage: "No seal ID found, do you want to try again?",
      loading: "Loading",
      loadingContainer: "We are digitazing the container",
      loadingSeal: "We are reading the seal",
      instructionContainerPhoto: "Take a picture of the container",
      containerTypes: {
        container: "Container",
        opening: "Opening",
        hallway: "Hallway",
        extracted_merch: "Extracted Merch",
        label_eq: "Label EQ",
        closing: "Closing",
        others: "Others",
      },
    },
    Seal: {
      header: "Seal",
      labelSealId: "Seal ID",
      labelCategory: "Category",
      labelPosition: "Is the seal untampered?",
      labelLocation: "Location",
      labelOwner: "Owner",
      labelSealViolationDetails: "Remarks about seal violation",
      defaultViolatedReason: "Seal has been violated",
      newPhoto: "Add Photo",
      deletePhotoTitle: "Replace image",
      deletePhotoMessage: "Are you sure you want to edit the existing photo?",
      cancel: "Cancel",
      confirm: "Confirm",
      sealOnLeftDoor: "Seal located on left container door",
      instructionSealPhoto: "Take a picture of the seal",
      missingSealSerialIdWarning: "Please enter the seal serial ID",
      owners: {
        other: "Other",
      },
      categories: {
        empty: "",
        orginal: "Original",
        vessel: "Vessel",
        terminal: "Terminal",
        inspectionTeam: "EQ",
        inspectionCertificate: "Inspection",
        other: "Other",
      },
      sizes: {
        empty: "",
        "22": "22",
        "25": "25",
        "42": "42",
        "45": "45",
        L2: "L2",
        L5: "L5",
        M2: "M2",
        M5: "M5",
      },
    },
    Container: {
      ownerPrefixErrorMessage: "Owner prefix typed is not valid",
      checkDigitNoneErrorMessage: "Digit doesnt exists with entered data",
      emptyOwnerPrefixErrorMessage: "Owner prefix field is required",
      emptySerialNumberErrorMessage: "Serial number field is required",
      emptyDigitErrorMessage: "Digit field is required",
      incompleteInput: "Container data is incomplete",
      checkDigitErrorMessage: "Digit inconsistent with entered data",
      header: "Container",
      labelOwnerPrefix: "Prefix",
      labelSerialNumber: "Serial number",
      labelDigit: "Digit",
      labelContainerId: "Container ID",
      labelContainerSize: "Size",
      labelContainerType: "Type",
      newPhoto: "Add Photo",
      deletePhotoTitle: "Replace image",
      deletePhotoMessage: "Are you sure you want to edit the existing photo?",
      deletePhotoCancel: "Cancel",
      deletePhotoConfirm: "Confirm",
      instructionContainerPhoto: "Take a picture of the container",
      types: {
        empty: "",
        G1: "G1",
        R1: "R1",
        U1: "U1",
        P1: "P1",
        T1: "T1",
      },
    },
    LastInspections: {
      photo: "Photo",
      container: "Container",
      service: "Service",
      petitioner: "Petitioner",
      level: "Level",
      expedient: "Expedient",
      dock: "Dock",
    },
  },
  cognito: {
    SignIn: "Sign In",
    SignUp: "Sign Up",
    SignUpText: "Don`t have an account?",
    SignUpTitle: "Create an account to do your inspections!",
    SendCode: "Send code again.",
    SignOut: "Sign Out",
    Username: "Username",
    Password: "Password",
    Email: "Email",
    Name: "Name",
    PhoneNumber: "Phone number",
    Confirm: "Confirm",
    Cancel: "Cancel",
    Submit: "Submit",
    Skip: "Skip",
    Verify: "Verify",
    Code: "Code",
    Main: "Current inspections",
    Operated: "Operated by: ",
    Anonymous: "Access anonymously",
    IncorrectLogin: "Incorrect username or password",
    ChangePassword: "Change password",
    OldPassword: "Last password",
    NewPassword: "New password",
    ConfirmPassword: "Repeat password",
    ForgotPassword: "Forgot the password?",
    EmailConfirmCode: "Change password with code",
    ConfirmationCode: "Confirmation Code",
    FirstAccess: "First Access",
    FirstAccessMessage1: "First Access message 1",
    FirstAccessMessage2: "First Access message 2",
    SuccessMessageChangePassword: "Password changed with success",
    SuccessMessageSubmit: "Inspection submitted with success!",
    SuccessMessageTara: "Tare submitted with success!",
    SuccessMessageLogin: "Logged in successfully!",
    ErrorMessageDifferentPasswords: "Password confirmation doesn't match",
    ErrorGeneralMessage: "There was an error, try again!",
    ErrorFieldsEmpty: "You must fill in the mandatory fields!",
    ForcePwdChangeException:
      "Need to make first access! If you forgot the first password, contact Area PIF for user reset",
    NotAuthorizedException: "Password incorrect, try again!",
    UserNotFoundException: "Username/client id combination not found.",
    CodeMismatchException:
      "Invalid verification code provided, please try again.",
    InvalidPasswordException:
      "Password does not conform to policy: Password not long enough",
    InvalidParameterException: "Empty username and/or password field",
    PasswordResetRequiredException: "Password reset required for the user",
    NotAuthorizedExceptionTitle: "Error: Not authorized",
    UserNotFoundExceptionTitle: "Error: User not found.",
    CodeMismatchExceptionTitle: "Error: Invalid Code",
    InvalidPasswordExceptionTitle: "Error: Password",
    InvalidParameterExceptionTitle: "Error: Invalid input",
    PasswordResetRequiredExceptionTitle: "Error: Password reset",
    CodeDeliveryFailureException:
      "Verification code fails to deliver successfully.",
    ForbiddenException:
      "Request not allowed, contact support for more information.",
    InternalErrorException:
      "Internal error occurred, contact support for more information.",
    InvalidLambdaResponseException:
      "Internal error occurred, contact support for more information.",
    InvalidSmsRoleAccessPolicyException:
      "Internal error occurred, contact support for more information.",
    InvalidSmsRoleTrustRelationshipException:
      "Internal error occurred, contact support for more information.",
    ResourceNotFoundException: "Our service can't find the requested resource",
    TooManyRequestsException:
      "Too many requests, wait some time and request again",
    UnexpectedLambdaException:
      "Internal error occurred, contact support for more information.",
    UserLambdaValidationException:
      "Internal error occurred, contact support for more information.",
    UsernameExistsException:
      "Email already exists in our user pool, choose another one!",
    EmptyFieldEmail: "Enter your email address",
    EmptyFieldName: "Enter your name",
    EmptyFieldPassword: "Enter a password with at least 8 characters",
    EmptyFieldPasswordConfirm: "Enter password same as previous field",
  },
};

export default en;
