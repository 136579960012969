import { SealState } from "../types";
import i18nDictionary from "../i18n";

export const getSealTitle = (seal: SealState) => {
  if (!!seal.serialId && seal.serialId !== "") {
    return `${i18nDictionary.screens.Seal.header()} - ${seal.serialId}`;
  } else {
    return i18nDictionary.screens.Seal.header();
  }
};

/**
 * Seal validation errors
 **/
export enum SealValidationErrors {
  MISSING_SERIAL_ID,
  // ADD MORE ERRORS HERE
}

/**
 * Check if the seal id is valid
 * @param sealId the seal id to check
 * @returns {boolean} true if the seal id is valid
 **/
export function isSealIdValid(sealId: string) {
  return sealId.trim().length > 0;
}

/**
 * Validate the seal form. Returns an array of errors.
 * @param seal the seal to validate
 * @returns {Array<SealValidationErrors>} the errors
 **/
export function validateSeal(seal: SealState): Array<SealValidationErrors> {
  const errors: Array<SealValidationErrors> = [];

  if (!isSealIdValid(seal.serialId)) {
    errors.push(SealValidationErrors.MISSING_SERIAL_ID);
  }
  return errors;
}

/**
 * Check if the seal is valid
 * @param seal the seal to check
 * @returns {boolean} true if the seal is valid
 * @see validateSeal
 **/
export function isSealValid(seal: SealState): boolean {
  return validateSeal(seal).length === 0;
}
