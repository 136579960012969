import React from 'react'
import Svg, { Path } from 'react-native-svg';
const CheckUpdate = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 1024 1024" fill="none" >
      <Path d="M170.939 853.333C147.472 853.333 127.39 844.985 110.693 828.288C93.9681 811.563 85.6055 791.467 85.6055 768V256C85.6055 232.533 93.9681 212.452 110.693 195.755C127.39 179.029 147.472 170.667 170.939 170.667H341.605C353.694 170.667 363.835 174.749 372.027 182.912C380.19 191.104 384.272 201.245 384.272 213.333C384.272 225.422 380.19 235.563 372.027 243.755C363.835 251.918 353.694 256 341.605 256H170.939V768H853.605V256H682.939C670.85 256 660.724 251.918 652.56 243.755C644.368 235.563 640.272 225.422 640.272 213.333C640.272 201.245 644.368 191.104 652.56 182.912C660.724 174.749 670.85 170.667 682.939 170.667H853.605C877.072 170.667 897.168 179.029 913.893 195.755C930.59 212.452 938.939 232.533 938.939 256V768C938.939 791.467 930.59 811.563 913.893 828.288C897.168 844.985 877.072 853.333 853.605 853.333H170.939ZM512.272 638.933C506.583 638.933 501.25 638.037 496.272 636.245C491.294 634.482 486.672 631.467 482.405 627.2L327.739 472.533C319.917 464.711 316.005 455.111 316.005 443.733C316.005 432.356 320.272 422.4 328.805 413.867C336.628 406.045 346.583 402.133 358.672 402.133C370.761 402.133 380.717 406.045 388.539 413.867L469.605 494.933V213.333C469.605 201.245 473.701 191.104 481.893 182.912C490.057 174.749 500.183 170.667 512.272 170.667C524.361 170.667 534.501 174.749 542.693 182.912C550.857 191.104 554.939 201.245 554.939 213.333V494.933L637.072 412.8C644.894 404.978 654.494 401.067 665.872 401.067C677.25 401.067 687.205 405.333 695.739 413.867C703.561 421.689 707.472 431.645 707.472 443.733C707.472 455.822 703.561 465.778 695.739 473.6L542.139 627.2C537.872 631.467 533.25 634.482 528.272 636.245C523.294 638.037 517.961 638.933 512.272 638.933V638.933Z" fill={props.color} />
    </Svg>
  )
}

export default CheckUpdate; 
