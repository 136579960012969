import React, { useState } from "react";
import { View, Animated, TouchableOpacity, StyleSheet } from "react-native";

// Components
import Text from "../../../shared/atoms/Text";

// Assets
import AngleDown from "../../../shared/svg/angle_down";
import ContainerIcon from "../../../shared/svg/container";
import Lock from "../../../shared/svg/lock";

// Styles
import { Theme } from "../../styles";
import { IconAccordionState } from "../../types";
import AngleUp from "../../../shared/svg/angle_up";

interface TitleProps {
  icon: IconAccordionState;
  title: string;
  onPress: () => void;
  color: string;
  open: boolean;
}

const styles = StyleSheet.create({
  containerHead: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 10,
    padddingHorizontal: 15,
    backgroundColor: Theme.colors.primary,
  },
  headerContent: {
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingHorizontal: 15,
  },
});

const AccordionTitle = ({ icon, title, onPress, color, open }: TitleProps) => {
  return (
    <TouchableOpacity activeOpacity={0.9} onPress={onPress}>
      <View style={styles.containerHead}>
        <View style={styles.headerContent}>
          {icon !== undefined &&
            (icon === IconAccordionState.CONTAINER ? (
              <View style={{ marginRight: 10 }}>
                <ContainerIcon
                  width={20}
                  height={20}
                  color={Theme.colors.title}
                />
              </View>
            ) : (
              <View style={{ marginRight: 10 }}>
                <Lock width={20} height={20} color={Theme.colors.title} />
              </View>
            ))}
          <Text style={{ fontSize: 17, color: color ? color : "#FFF" }}>
            {title}
          </Text>
        </View>
        <View
          style={{
            marginRight: 10,
          }}
        >
          {open ? (
            <AngleUp width={15} height={15} color="#fff" />
          ) : (
            <AngleDown width={15} height={15} color="#fff" />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default AccordionTitle;
