import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextProps,
  ActivityIndicator,
} from "react-native";
import Slider from "@react-native-community/slider";

// Components
import PrimaryButton, { Props as ButtonProps } from "../atoms/PrimaryButton";
import Text from "../atoms/Text";
import { Sizes } from "../constants/sizes";

// Constants
import Times from "../svg/times";
import CameraIcon from "../svg/camera";

interface CameraUIProps {
  onPress: () => void;
  cancelPhoto: () => void;
  title: string;
  loading: boolean;
  titleProps?: TextProps;
  instructionsText: string;
  instructionsTextProps?: TextProps;
  buttonProps?: ButtonProps;
  onZoomChange?: (arg0: number) => void;
}

const CameraUI = ({
  onPress,
  cancelPhoto,
  title,
  loading,
  titleProps,
  instructionsText,
  instructionsTextProps,
  buttonProps,
  onZoomChange,
}: CameraUIProps) => {
  return (
    <View
      style={{ flex: 1, position: "absolute", width: "100%", height: "100%" }}
    >
      <View
        style={{
          flex: 2.5,
          backgroundColor: "black",
          opacity: 0.9,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <View style={{ width: "15%" }}></View>

        <View
          style={{
            width: "70%",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: Sizes.marginL,
            padding: Sizes.paddingS,
          }}
        >
          <Text {...titleProps}>{title}</Text>
          <View
            style={{
              width: "100%",
              height: "auto",
              justifyContent: "center",
              alignItems: "center",
              padding: Sizes.paddingS,
              marginBottom: Sizes.marginXL,
              borderRadius: 10,
              borderColor: "#FFF",
              borderBottomWidth: 3,
              borderTopWidth: 3,
            }}
          >
            <Text {...instructionsTextProps}>{instructionsText}</Text>
          </View>
        </View>

        <TouchableOpacity
          onPress={cancelPhoto}
          style={{
            width: "15%",
            height: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            padding: Sizes.paddingS,
            marginTop: Sizes.marginL,
          }}
        >
          <>
            <Times width={25} height={25} color={"#FFF"} />
          </>
        </TouchableOpacity>
      </View>

      <View style={{ flex: 8, flexDirection: "row" }}>
        <View
          style={{
            width: "2.5%",
            height: "100%",
            backgroundColor: "black",
            opacity: 0.9,
          }}
        ></View>
        <View style={{ width: "95%" }}></View>
        <View
          style={{
            width: "2.5%",
            height: "100%",
            backgroundColor: "black",
            opacity: 0.9,
          }}
        ></View>
      </View>

      <View
        style={{
          flex: 2.5,
          backgroundColor: "black",
          opacity: 0.9,
          justifyContent: "flex-start",
          alignItems: "center",
          paddingBottom: Sizes.marginL,
        }}
      >
        {onZoomChange && (
          <Slider
            style={{ width: 200 }}
            minimumValue={0}
            maximumValue={1}
            minimumTrackTintColor="#FFFFFF"
            maximumTrackTintColor="#FFFFFF"
            thumbTintColor="#FFFFFF"
            onValueChange={onZoomChange}
            step={0.05}
          />
        )}
        <PrimaryButton onPress={onPress} {...buttonProps}>
          {!loading ? (
            <CameraIcon height={25} width={25} color={"#FFF"} />
          ) : (
            <ActivityIndicator animating={true} color={"#FFF"} size={25} />
          )}
        </PrimaryButton>
      </View>
    </View>
  );
};

export default CameraUI;
