import React from "react";
import { View, StyleProp, ViewStyle } from "react-native";

// Libraries
import { Picker } from "@react-native-picker/picker";

// Components
import { FloatingLabel } from "../../shared/atoms/FloatingLabel";
import { Theme } from "../styles";
import { Sizes } from "../../shared/constants/sizes";
import { CategoryState, Option } from "../types";

// Constants

interface DropdownProps {
  selectedValue: string;
  values: CategoryState<string>;
  onValueChange: (itemValue: string, itemIndex: number) => void;
  label: string;
  style?: StyleProp<ViewStyle>;
}

const Dropdown = ({
  selectedValue,
  values,
  onValueChange,
  label,
  style,
}: DropdownProps) => {
  return (
    <View style={{ margin: 0, padding: 0, flexGrow: 1 }}>
      <FloatingLabel
        text={label}
        backgroundColor={Theme.colors.title}
        color={Theme.colors.text_black}
      />
      <Picker
        selectedValue={selectedValue}
        style={{
          fontSize: Sizes.body3,
          color: Theme.colors.text_dark,
          fontWeight: "400",
          flexGrow: 1,
        }}
        itemStyle={{
          fontSize: Sizes.body3,
          color: Theme.colors.text_dark,
          fontWeight: "400",
        }}
        onValueChange={onValueChange}
      >
        {values.map((item: Option<string>, index: number) => (
          <Picker.Item label={item.label} value={item.value} key={index} />
        ))}
      </Picker>
    </View>
  );
};

export default Dropdown;
