import { Storage } from 'aws-amplify'
import configAws from '../aws-exports.js'

Storage.configure({
  region: configAws.aws_user_files_s3_bucket_region,
  bucket: configAws.aws_user_files_s3_bucket,
  identityPoolId: configAws.aws_user_pools_id,
  level: "public",
})

export const storageUri = (key: string) => `s3://${configAws.aws_user_files_s3_bucket}/public/${key}`

export const getS3PhotoUrl = (s3Path: string) =>  `https://${configAws.aws_user_files_s3_bucket}.s3.${configAws.aws_user_files_s3_bucket_region}.amazonaws.com${s3Path.split(configAws.aws_user_files_s3_bucket)[1]}`

export default Storage