import { Platform } from 'react-native'
import * as Updates from 'expo-updates'

const SENTRY_DSN_IOS = '...'
const SENTRY_DSN_ANDROID = 'https://64d0ed78641a4a0caed095ceb6a2dbfa@o4504324069720064.ingest.sentry.io/4504680710864896'

export const sentryConfig = {
  dsn: Platform.OS === 'ios' ? SENTRY_DSN_IOS : SENTRY_DSN_ANDROID,
  release: `com.goclever.pif@${Updates.runtimeVersion}`,
  dist: Updates.updateId as string,
  enableInExpoDevelopment: true,
  enableAutoSessionTracking: true,
  debug: __DEV__ ? true : false,
  environment: __DEV__ ? 'development' : 'production',
}