import React, { useRef } from "react"
import { View, StyleSheet, TextInput } from "react-native"
import { Sizes } from "../constants/sizes"

const PLACEHOLDER = ["A", "B", "C", "U"] as const
const PLACEHOLDER_NUMBER = ["1", "2", "3", "4", "5", "6", "0"] as const

type PositiveNumber = number

type KeyboardChar = string & {}

export const char = (c: string): KeyboardChar => {
  if (typeof c !== "string" || c.length !== 1) {
    throw new Error("invalid input")
  }
  return c
}

type ValuesKeyboardChars = Array<KeyboardChar | undefined>

interface FixedCharInputProps {
  slots: PositiveNumber
  onChangeHandler: (values: ValuesKeyboardChars) => void
  values: ValuesKeyboardChars
  firstRef: React.MutableRefObject<TextInput | null>
  focusOnNext: () => void
  editable?: boolean
  regex?: RegExp
}

const FixedCharInput = ({ onChangeHandler, slots, values, firstRef, focusOnNext, editable, regex }: FixedCharInputProps) => {
  const inputRefs = useRef<(TextInput | null)[]>([])

  const setInputRef = (ref: TextInput | null, index: number) => {
    inputRefs.current[index] = ref
  }

  const inputs = values.map((value, i) => {
    const onChange = (rawVal: KeyboardChar) => {
      const rawValue = rawVal.replace(/[^a-zA-Z0-9]/g, "")

      if (!regex?.test(rawVal)|| rawValue === "") {
        i === 0 ? firstRef.current?.setNativeProps({ text: " " }) : inputRefs.current[i]?.setNativeProps({ text: " " })
        setTimeout(() => {
          i === 0 ? firstRef.current?.setNativeProps({ text: "" }) : inputRefs.current[i]?.setNativeProps({ text: "" })
        }, 3)
        return
      }
      const newValue = [...values]
      newValue[i] = rawValue
      onChangeHandler(newValue)
      if (i === slots - 1) {
        focusOnNext()
      } else if (inputRefs.current.length > i && inputRefs.current[i + 1]) {
        inputRefs.current[i + 1]?.focus()
      }
    }

    return (
      <TextInput
        style={{
          width: slots === 4 ? "24%" : slots === 6 ? "16.5%" : "100%",
          fontWeight: "700",
          fontSize: Sizes.body3,
          textAlign: "center",
        }}
        placeholderTextColor={"#B5B5B5"}
        value={value}
        ref={i === 0 ? firstRef : (ref) => setInputRef(ref, i)}
        selectTextOnFocus={true}
        keyboardType={slots === 4 ? "ascii-capable" : "number-pad"}
        onChangeText={onChange}
        placeholder={slots === 4 ? PLACEHOLDER[i] : PLACEHOLDER_NUMBER[i]}
        blurOnSubmit={false}
        multiline={false}
        maxLength={1}
        key={i}
        editable={editable === undefined ? true : editable}
      />
    )
  })

  return (
    <View style={{ width: slots === 4 ? "35%" : slots === 6 ? "50%" : "15%" }}>
      <View style={styles.input}>{inputs}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    height: 45,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 5,
    color: "#2B2B2B",
    borderRadius: 5,
    fontSize: Sizes.body4,
    borderColor: "#B5B5B5",
    borderWidth: 1,
    flexDirection: "row",
  },
})

export default FixedCharInput
