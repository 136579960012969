import React from "react"
import { View } from "react-native"
import Svg, { Circle, G, Line, Path } from "react-native-svg"
const PhotoTakePerson = (props: { width: number; height?: number; }) => {
  const st0: any = {
    fill: "#FCC5DD",
  }
  const st1: any = {
    stroke: "#000000",
    strokeWidth: 3.3705,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  }

  const st2: any = {
    fill: "#192334",
  }

  const st3: any = {
    fill: "none",
    stroke: "#000000",
    strokeWidth: 3.3705,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  }

  const st4 = {
    fill: "#06f",
  }
  return (
    <Svg id="man" width={props.width} height={props.height} x="0px" y="0px" viewBox="0 0 2000 2000">
      <G id="shoes_1">
        <Path
          fill={st1.stroke}
          d="M1002.1,1629.6c0,0,3,18.3-4.4,22.4c-7.5,4.1-111.6,38.2-113.1,55.4c-1.5,17.2,173.7,23.2,181.2,10.9
		c7.5-12.4-13.6-65.5-10.7-88.7H1002.1z"
        />
        <Path
          fill={st0.fill}
          d="M999.2,1650.8c0,0,38.3,17.1,58.5,6.9l0,0c-2.1-10.8-3.6-20.9-2.7-28.1h-53
		C1002.1,1629.6,1004.6,1645.1,999.2,1650.8z"
        />
      </G>
      <G id="shoes_2">
        <Path
          fill={st1.stroke}
          d="M1142.3,1622.5c0,0,1.7,22.4-6.9,28.2c-8.6,5.8-123.5,37.1-123.6,50.4c0,14.6,181.2,37.1,193.2,18.3
		c17.5-27.3-11.8-85.4-11.8-96.3C1193.2,1623.1,1160.3,1624,1142.3,1622.5z"
        />
        <Path
          fill={st0.fill}
          d="M1201.1,1649.9c-4.1-12.6-7.9-22.8-7.9-26.7c0,0-32.9,0.9-50.9-0.6c0,0,1.2,15.6-3.4,24.2
		C1148.6,1652.4,1170.4,1661.2,1201.1,1649.9z"
        />
      </G>
      <Path
        id="phone"
        fill={st1.stroke}
        stroke={st1.stroke}
        strokeWidth={st1.strokeWidth}
        strokeLinecap={st1.strokeLinecap}
        strokeLinejoin={st1.strokeLinejoin}
        strokeMiterlimit={st1.strokeMiterlimit}
        d="M742.1,399.3c0,0,17.7-2.1,19-10.2c1.3-8.1-11.6-110.1-19-113.5s-56.7,10.2-57.6,16.9
	c-0.9,6.7,20.7,117.9,20.7,117.9L742.1,399.3z"
      />
      <G id="head">
        <Circle fill={st0.fill} cx="1049.1" cy="430.6" r="55.6" />
        <Path
          fill={st0.fill}
          d="M1040.9,483.5c0,0,3.6,40.7-8.8,53.1c0,0,46.4,19.1,94.7-15.7c0,0-15.1-18.2-15.1-37.3
		C1111.7,483.5,1056.7,467.7,1040.9,483.5z"
        />
      </G>
      <G id="pants">
        <Path
          fill={st2.fill}
          d="M949.5,1003.3c0,0,15.6,570.1,24.5,621.8c0,0,67.4,14.6,94.4,0l29.2-515.6c0,0,22.5,488.6,22.5,511.1
		c0,0,71.9,7.9,91-1.1c0,0,56-542.3,47.1-604.2L949.5,1003.3z"
        />
        <Line
          fill={st3.fill}
          stroke={st3.stroke}
          strokeWidth={st3.strokeWidth}
          strokeLinecap={st3.strokeLinecap}
          strokeLinejoin={st3.strokeLinejoin}
          strokeMiterlimit={st3.strokeMiterlimit}
          x1="1086"
          y1="1111.8"
          x2="1111.8"
          y2="1108.5"
        />
      </G>
      <G id="shirt">
        <Path
          fill={st4.fill}
          d="M1032.1,536.6c0,0-36.3,26.4-69.3,29.4c-32.9,3-96.6-23.4-108.2-31.2c-11.6-7.9-116.1-140-116.1-140
		s-37.5,9.1-42.7,34.1c0,0,88.4,160.3,95.9,167.7c7.5,7.5,140.8,79.4,144.5,91.4s-6.7,287.6-6.7,308.5c0,0,230.6,86.9,338.5,12.7
		l-3-33.9c0,0,73.6-154.5,61.8-198.3c-11.8-43.8-72.5-208.4-122.4-229.7c-50-21.3-69.6-21.9-77.5-26.4
		C1126.8,520.9,1087.5,553.5,1032.1,536.6z"
        />
        <Path
          fill={st3.fill}
          stroke={st3.stroke}
          strokeWidth={st3.strokeWidth}
          strokeLinecap={st3.strokeLinecap}
          strokeLinejoin={st3.strokeLinejoin}
          strokeMiterlimit={st3.strokeMiterlimit}
          d="M1243.2,687.1c0,0,17.1,286.6,24.6,321"
        />
      </G>
      <Path
        id="hand"
        fill={st0.fill}
        d="M738.5,394.7c0,0-11.2-10.9-11.2-16.8s9.1-50.5,5.6-53.9c-3.5-3.5-27.9,43.4-32.4,41.2
	c-4.5-2.2-13.5-42.5-13.5-42.5s-17.4,31.1-14.8,46.4s23.6,59.7,23.6,59.7S705.2,403.9,738.5,394.7z"
      />
      <Path
        id="hair"
        fill={st1.stroke}
        stroke={st1.stroke}
        strokeWidth={st1.strokeWidth}
        strokeLinecap={st1.strokeLinecap}
        strokeLinejoin={st1.strokeLinejoin}
        strokeMiterlimit={st1.strokeMiterlimit}
        d="M1061.6,329.5c0,0,27.2-15.7,57.7,4.2c22.7,14.8,31.8,33.8,33.3,47.6c1.7,15.7-10.2,23.7-12.4,34.5
	c-1.9,9.2,4.4,19.1,0.8,27.6c-4.3,10.3-14.2,11.6-16.5,18.7s-4.1,22.1-24.7,25.8s-36.1-5.8-42.7-12.4s-11.1-1.3-14.4-5.9
	c-5.4-7.6-3.7-13-6.9-14.1c-3.2-1.1-10.3,7.7-13.9-17.4c-1.9-13.3-1.3-19.2-5.5-24.8c-5.8-7.7-8.6-2.7-11.8-6.7
	c-6.2-8-0.7-31.9-0.7-31.9s-17.7,8.9-20.8,3.7c-3.9-6.6,18.8-52.1,47.8-57.3C1054.7,317,1061.6,329.5,1061.6,329.5z"
      />
    </Svg>
  )
}

export default PhotoTakePerson
