import { useRef } from "react"
import { Platform, Dimensions } from "react-native"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PhotoFormat } from "../types"
import { RootState } from "./index"
import { Camera } from "expo-camera"
//axios api

type initialStateType = {
  ratio: string
  imagePadding: number
  updated: boolean
}

const initialState: initialStateType = {
  ratio: "4:3",
  imagePadding: 0,
  updated: false,
}

const { height, width } = Dimensions.get("window")
const screenRatio = height / width

export const fetchCameraRatio = createAsyncThunk("camera/fetchCameraRatio", async () => {
  try {
    let desiredRatio: string | null = "4:3"
    if (Platform.OS === "android") {
      const ratios = ["4:3", "16:9"]

      let distances = {}
      let realRatios = {}
      let minDistance = null
      for (const ratio of ratios) {
        const parts = ratio.split(":")
        const realRatio = parseInt(parts[0]) / parseInt(parts[1])
        //@ts-ignore
        realRatios[ratio] = realRatio
        const distance = screenRatio - realRatio
        //@ts-ignore
        distances[ratio] = realRatio
        if (minDistance == null) {
          minDistance = ratio
        } else {
          //@ts-ignore
          if (distance >= 0 && distance < distances[minDistance]) {
            minDistance = ratio
          }
        }
      }

      desiredRatio = minDistance
      const remainder = Math.floor(
        //@ts-ignore
        (height - realRatios[desiredRatio] * width) / 2
      )

      return { ratio: desiredRatio, imagePadding: remainder }
    }
  } catch (e) {
    console.log(e)
  }
})

export const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    updateCameraRatio: (state, action: PayloadAction<{ ratio: string; imagePadding: number }>) => {
      state.ratio = action.payload.ratio
      state.imagePadding = action.payload.imagePadding
      state.updated = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCameraRatio.fulfilled, (state, action) => {
      if (action.payload) {
        state.ratio = action.payload.ratio === null ? "4:3" : action.payload.ratio
        state.imagePadding = action.payload.imagePadding
        state.updated = true
      }
    })
  },
})
export const { updateCameraRatio } = cameraSlice.actions

export const selectCameraRatio = (id?: string) => (state: RootState) => state.camera
