import React from "react";
import {
  Keyboard,
  View,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";

// Librarys

// Components
import Text from "../../../shared/atoms/Text";

// Internalization
import i18nDictionary from "../../i18n";

import { Theme } from "../../styles";
import Input from "../../../shared/molecules/Input";
import Cargo from "../../../shared/svg/cargo";
import { Sizes } from "../../../shared/constants/sizes";
import { Button } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { ScrollView } from "react-native-gesture-handler";

interface LoginProps {
  loadingButton: boolean;
  signIn: (email: string, password: string) => void;
  signUp: () => void;
  forgotPwd: () => void;
  errorMsg: string;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (pwd: string) => void;
  anonymousAccess: () => void;
}

const { width } = Dimensions.get("window");

const Login = ({
  loadingButton,
  signIn,
  signUp,
  forgotPwd,
  errorMsg,
  email,
  setEmail,
  password,
  setPassword,
  anonymousAccess,
}: LoginProps) => {
  const keyboardVerticalOffset = Platform.OS === "ios" ? 40 : 0;
  return (
    <SafeAreaView
      style={{
        flex: 1,
        width: "80%",
        justifyContent: "center",
      }}
    >
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          width: "100%",
          justifyContent: "center",
        }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flex: 1,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            maxHeight:
              Platform.OS === "web" && innerHeight >= 769 ? 481 : "100%",
          }}
        >
          <KeyboardAvoidingView
            style={styles.container}
            behavior="position"
            keyboardVerticalOffset={keyboardVerticalOffset}
          >
            <View style={styles.logo}>
              <Cargo width={200} height={200} />
            </View>
            <View
              style={{
                width: Platform.OS === "web" && width >= 1150 ? "48%" : "100%",
                alignItems: "center",
              }}
            >
              <View style={{ width: "100%" }}>
                <Input
                  value={email}
                  onChange={(text) => setEmail(text)}
                  label={i18nDictionary.cognito.Username()}
                  keyboardType="email-address"
                  error={errorMsg !== ""}
                />

                <Input
                  value={password}
                  onChange={(text) => setPassword(text)}
                  label={i18nDictionary.cognito.Password()}
                  error={errorMsg !== ""}
                  secureTextEntry={true}
                  onSubmitEditing={() => {
                    Keyboard.dismiss;
                    signIn(email, password);
                  }}
                />

                <TouchableOpacity
                  style={{ flexDirection: "row" }}
                  onPress={() => forgotPwd()}
                >
                  <Text
                    style={{
                      fontSize: Sizes.body3,
                      fontWeight: "700",
                      color: Theme.colors.title,
                      marginTop: 5,
                      textDecorationLine: "underline",
                    }}
                  >
                    {i18nDictionary.cognito.ForgotPassword()}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </KeyboardAvoidingView>

          <View
            style={{
              marginVertical: 10,
              width: "100%",
              maxWidth: 400,
              justifyContent: "flex-end",
            }}
          >
            <Button
              icon="login"
              mode="contained"
              loading={loadingButton}
              onPress={() => signIn(email, password)}
              style={styles.loginButton}
              uppercase={false}
              labelStyle={{
                fontSize: 20, // Size for icon
                fontWeight: "700",
                color: Theme.colors.primary,
              }}
            >
              <Text style={{ fontSize: Sizes.h4 }}>
                {i18nDictionary.cognito.SignIn()}
              </Text>
            </Button>

            <Button
              icon="incognito"
              mode="contained"
              loading={loadingButton}
              onPress={anonymousAccess}
              style={styles.anonymous}
              uppercase={false}
              labelStyle={{
                fontSize: 20, // Size for icon
                fontWeight: "700",
                color: Theme.colors.title,
              }}
            >
              <Text style={{ fontSize: Sizes.h5 }}>
                {i18nDictionary.cognito.Anonymous()}
              </Text>
            </Button>

            <TouchableOpacity
              onPress={() => signUp()}
              style={styles.signUpButton}
            >
              <Text
                style={{
                  fontSize: Sizes.h4,
                  fontWeight: "700",
                  color: Theme.colors.title,
                  marginRight: 5,
                }}
              >
                {i18nDictionary.cognito.SignUpText()}
              </Text>
              <Text
                style={{
                  fontSize: Sizes.h4,
                  fontWeight: "700",
                  color: Theme.colors.primary_dark,
                  textDecorationLine: "underline",
                }}
              >
                {i18nDictionary.cognito.SignUp()}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "70%",
    width: "100%",
    maxWidth: 500,
    flexDirection: Platform.OS === "web" && width >= 1150 ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    borderColor: "gray",
    backgroundColor: "#FFF",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  loginButton: {
    borderRadius: Sizes.borderRadiusM,
    justifyContent: "center",
    backgroundColor: Theme.colors.title,
    height: Sizes.heightS,
    padding: Sizes.paddingS,
    marginVertical: 20,
  },
  signUpButton: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: Sizes.heightS,
    padding: Sizes.paddingS,
    marginVertical: 10,
  },
  anonymous: {
    borderRadius: Sizes.borderRadiusM,
    justifyContent: "center",
    backgroundColor: Theme.colors.primary_dark,
    height: Sizes.heightS,
    marginHorizontal: 20,
  },
  logo: {
    borderRightWidth: Platform.OS === "web" && width >= 1150 ? 2 : 0,
    justifyContent: "center",
    alignItems: "center",
    marginRight: Platform.OS === "web" && width >= 1150 ? 50 : 0,
    paddingRight: Platform.OS === "web" && width >= 1150 ? 50 : 0,
    borderColor: Theme.colors.title,
  },
});

export default Login;
