// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SealCategory = {
  "ORIGINAL": "ORIGINAL",
  "NAVIERA": "NAVIERA",
  "TERMINAL": "TERMINAL",
  "EQ": "EQ",
  "INSPECTION": "INSPECTION",
  "OUTRO": "OUTRO"
};

const SealLocation = {
  "CAM_SECURE": "CAM_SECURE",
  "SAM_ALTERNATIVE": "SAM_ALTERNATIVE",
  "OTHER": "OTHER",
  "LEFT": "LEFT"
};

const SealValidation = {
  "OK": "OK",
  "VIOLATED": "VIOLATED",
  "DEFAULT": "DEFAULT"
};

const { Inspection, ContainerData, InspectionSeal } = initSchema(schema);

export {
  Inspection,
  SealCategory,
  SealLocation,
  SealValidation,
  ContainerData,
  InspectionSeal
};