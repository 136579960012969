import React from 'react'
import Svg, { Path } from 'react-native-svg';
const AngleDown = (props: { width: number, height: number, color?: string}) => {

    return (
        <Svg width={props.width} height={props.height} viewBox="0 0 611 130" fill="none">
            <Path
                d="M57.3458 0.386296C42.6458 1.7863 31.6458 7.3863 19.3458 19.7863C12.7458 26.2863 9.44583 30.5863 7.14583 35.2863C0.345827 49.5863 -1.55417 62.3863 1.24583 76.2863C9.74583 118.086 55.2458 141.186 92.3458 122.586C95.1458 121.186 97.4458 119.886 97.6458 119.786C97.9458 119.586 88.5458 103.886 87.2458 102.486C87.1458 102.286 84.1458 103.186 80.6458 104.586C71.7458 108.086 63.9458 108.786 55.1458 106.786C33.1458 101.786 17.7458 79.1863 21.7458 57.5863C24.3458 43.6863 33.5458 31.4863 46.4458 25.1863C58.2458 19.3863 75.7458 20.0863 86.0458 26.8863L89.7458 29.2863L94.8458 20.6863C97.6458 15.8863 99.7458 11.4863 99.5458 10.8863C98.8458 9.0863 87.1458 3.5863 80.3458 1.9863C72.4458 -0.0137043 65.9458 -0.413704 57.3458 0.386296Z"
                fill={props.color === undefined ? 'white' : props.color} />
            <Path
                d="M387.346 0.386314C372.846 1.88631 361.646 7.48631 349.746 19.2863C342.946 26.0863 340.346 29.4863 337.046 36.2863C331.646 47.1863 329.446 57.1863 330.146 67.7863C331.346 84.0863 338.146 98.7863 349.946 110.286C361.046 121.086 371.646 126.286 387.046 128.586C416.646 132.886 447.646 111.886 456.846 81.2863C458.246 76.5863 458.846 75.6863 461.346 75.2863C463.746 74.8863 464.746 73.5863 468.446 65.4863L472.746 56.2863H436.246H399.746L395.446 65.4863L391.246 74.7863L413.546 75.0863C438.946 75.3863 437.846 74.8863 433.246 84.0863C427.446 95.6863 413.746 105.386 400.146 107.486C372.546 111.786 346.646 85.0863 351.746 57.5863C354.246 43.8863 363.846 31.3863 376.446 25.1863C382.846 21.9863 383.946 21.7863 393.346 21.7863C403.146 21.7863 403.646 21.8863 411.346 25.7863C415.746 27.9863 419.546 29.8863 419.846 29.9863C420.146 30.1863 422.546 26.4863 425.246 21.7863C429.046 14.9863 429.846 12.9863 428.946 11.8863C428.246 11.0863 424.046 8.58631 419.546 6.38631C408.246 0.786314 399.346 -0.813686 387.346 0.386314Z"
                fill={props.color === undefined ? 'white' : props.color} />
            <Path
                d="M539.846 0.386311C524.346 1.78631 513.746 7.08631 501.246 19.6863C490.946 29.9863 485.846 38.7863 483.346 50.7863C476.446 83.6863 496.446 117.186 528.746 126.686C567.446 138.086 608.446 108.586 610.546 67.7863C611.546 48.3863 605.946 34.3863 591.346 19.6863C575.946 4.28631 560.546 -1.51369 539.846 0.386311ZM558.646 22.7863C571.546 26.6863 583.946 39.1863 587.946 52.4863C592.646 67.8863 588.546 82.6863 576.246 95.0863C559.246 112.086 535.046 112.486 517.046 95.8863C500.646 80.6863 498.246 57.2863 511.146 39.5863C517.546 30.9863 524.746 25.6863 534.346 22.6863C539.946 20.8863 552.446 20.9863 558.646 22.7863Z"
                fill={props.color === undefined ? 'white' : props.color} />
            <Path
                d="M155.146 2.78631C137.446 6.68631 122.846 21.6863 118.346 40.4863C117.546 43.9863 116.846 48.4863 116.846 50.4863V54.2863H108.846H100.746L96.3458 63.8863C93.8458 69.1863 91.8458 73.6863 91.8458 73.8863C91.8458 74.0863 97.1458 74.2863 103.746 74.2863C115.246 74.2863 115.646 74.3863 116.246 76.4863C116.546 77.7863 116.846 89.6863 116.846 102.986V127.286H126.346H135.846V100.786V74.2863H165.346H194.846V100.786V127.286H204.846H214.846V91.8863C214.846 71.9863 214.346 52.9863 213.746 48.5863C210.446 24.2863 196.946 7.58631 176.746 2.78631C169.046 0.986314 163.446 0.986314 155.146 2.78631ZM176.346 24.7863C184.646 28.8863 191.846 39.1863 193.346 49.4863L194.046 54.2863H165.346H136.546L137.046 49.2863C138.146 38.3863 146.346 27.0863 155.646 23.5863C160.646 21.7863 171.346 22.3863 176.346 24.7863Z"
                fill={props.color === undefined ? 'white' : props.color} />
            <Path
                d="M231.846 64.2863V127.286H241.846H251.846V106.786V86.2863H261.146H270.346L290.846 106.786L311.346 127.286H324.546C331.846 127.286 337.846 126.986 337.846 126.586C337.846 126.186 328.046 115.986 316.146 104.086L294.346 82.2863L299.646 77.6863C310.946 67.7863 315.246 57.4863 314.646 41.7863C314.346 33.0863 313.846 30.8863 311.146 25.0863C305.246 12.4863 295.346 4.88631 281.846 2.28631C278.946 1.68631 266.546 1.28631 254.246 1.28631H231.846V64.2863ZM282.346 25.1863C287.446 27.5863 290.446 30.7863 292.346 36.1863C295.146 44.1863 293.746 51.9863 288.546 57.9863C283.746 63.4863 280.746 64.2863 265.346 64.2863H251.846V43.7863V23.2863H265.146C275.846 23.2863 279.146 23.6863 282.346 25.1863Z"
                fill={props.color === undefined ? 'white' : props.color} />
        </Svg>
    )
}

export default AngleDown; 