import React from 'react'
import Svg, { Path } from 'react-native-svg';
const AngleUp = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 998 582" fill="none" >
      <Path d="M998 499C998 507.667 994.667 515.333 988 522L938 572C931.333 578.667 923.667 582 915 582C906.333 582 898.667 578.667 892 572L499 179L106 572C99.3333 578.667 91.6667 582 83 582C74.3333 582 66.6667 578.667 60 572L10 522C3.33333 515.333 0 507.667 0 499C0 490.333 3.33333 482.667 10 476L476 10C482.667 3.33333 490.333 0 499 0C507.667 0 515.333 3.33333 522 10L988 476C994.667 482.667 998 490.333 998 499Z" fill={props.color} />
    </Svg>
  )
}

export default AngleUp; 