import React, { useState, createRef } from "react";
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  StyleSheet,
  ActivityIndicator,
} from "react-native";

// Components
import { Camera, CameraType, PermissionResponse } from "expo-camera";
import { Theme } from "../../styles";
import CameraIcon from "../../../shared/svg/camera";
import Text from "../../../shared/atoms/Text";

// Internalization
import dict from "../../i18n";
import Times from "../../../shared/svg/times";

// Libs
import { Storage } from "aws-amplify";

// Utils
import configAws from "../../aws-exports";
import { Sizes } from "../../../shared/constants/sizes";

Storage.configure({
  region: configAws.aws_cognito_region,
  bucket: configAws.aws_user_files_s3_bucket,
  identityPoolId: configAws.aws_cognito_identity_pool_id,
  level: "public",
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    margin: 20,
    width: "100%",
    backgroundColor: "black",
    opacity: 0.5,
  },
  button: {
    flex: 0.1,
    alignSelf: "flex-end",
    alignItems: "center",
  },
  text: {
    fontSize: 18,
    color: "white",
  },
});

const GoCamera = (props: {
  cameraIsOpen: boolean;
  openCamera: () => void;
  capturedPhoto: string;
  setCapturedPhoto: (picture: string) => void;
  nextStep: () => void;
  instruction: string;
}) => {
  const [confirmedPhoto, setConfirmedPhoto] = useState(false);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [visibleError, setVisibleError] = useState(false);

  const camRef = createRef<Camera>();

  const takePicture = async () => {
    if (camRef) {
      try {
        const picture = await camRef.current?.takePictureAsync({
          exif: true,
          skipProcessing: true,
        });
        if (picture !== undefined) {
          props.setCapturedPhoto(picture.uri);
          setConfirmedPhoto(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePicture = async () => {
    setLoadingPicture(true);

    props.nextStep();
    setConfirmedPhoto(false);
    setLoadingPicture(false);
  };

  const toggleDialogError = () => {
    setVisibleError(!visibleError);
  };

  return (
    <>
      <Modal
        animationType="slide"
        transparent={false}
        visible={props.cameraIsOpen}
      >
        <>
          <Camera ref={camRef} style={styles.camera} type={CameraType.back} />
          <View
            style={{
              flex: 1,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <View
              style={{
                flex: 2,
                backgroundColor: "black",
                opacity: 0.5,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View style={{ width: "15%" }}></View>

              <View
                style={{
                  width: "70%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: Sizes.paddingS,
                }}
              >
                <Text
                  style={{
                    fontSize: Sizes.h4,
                    color: Theme.colors.title,
                    marginBottom: 5,
                  }}
                >
                  {dict.screens.Inspections.instructions()}
                </Text>
                <View
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: Sizes.paddingS,
                    borderColor: Theme.colors.title,
                    borderRadius: Sizes.borderRadiusM,
                    borderWidth: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: Sizes.body4,
                      color: Theme.colors.title,
                      fontWeight: "700",
                    }}
                  >
                    {dict.screens.Inspections.instructions()}
                  </Text>
                </View>
              </View>

              <TouchableOpacity
                onPress={() => props.openCamera()}
                style={{
                  width: "15%",
                  height: "100%",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  padding: Sizes.paddingS,
                  marginTop: 20,
                }}
              >
                <>
                  <Times width={30} height={30} color={Theme.colors.title} />
                </>
              </TouchableOpacity>
            </View>

            <View style={{ flex: 8, opacity: 0 }}></View>

            <View
              style={{
                flex: 1,
                backgroundColor: "none",
                opacity: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                testID="takePicture"
                onPress={() => {
                  takePicture();
                }}
                style={{
                  backgroundColor: Theme.colors.primary,
                  width: "50%",
                  opacity: 1,
                  padding: Sizes.paddingS,
                  borderRadius: Sizes.borderRadiusM,
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                <CameraIcon height={25} width={25} color={Theme.colors.title} />
              </TouchableOpacity>
            </View>
          </View>
        </>
      </Modal>

      <Modal animationType="fade" transparent={false} visible={confirmedPhoto}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            marginVertical: 20,
          }}
        >
          <Image
            style={{
              width: "100%",
              height: 400,
              borderRadius: Sizes.borderRadiusM,
            }}
            source={{ uri: props.capturedPhoto }}
          />
        </View>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginVertical: 10,
          }}
        >
          {loadingPicture ? (
            <View
              style={{
                borderRadius: Sizes.borderRadiusM,
                width: "80%",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Theme.colors.success,
                height: Sizes.heightS,
                flexDirection: "row",
                padding: Sizes.paddingS,
              }}
            >
              <ActivityIndicator size="small" color={Theme.colors.title} />
            </View>
          ) : (
            <TouchableOpacity
              testID="confirm"
              onPress={() => handlePicture()}
              style={{
                borderRadius: Sizes.borderRadiusM,
                width: "80%",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Theme.colors.success,
                height: Sizes.heightS,
                flexDirection: "row",
                padding: Sizes.paddingS,
              }}
            >
              <Text
                style={{ fontSize: Sizes.body3, color: Theme.colors.title }}
              >
                {dict.screens.Seal.confirm()}
              </Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => {
              props.setCapturedPhoto("");
              setConfirmedPhoto(false);
            }}
            style={{
              borderRadius: Sizes.borderRadiusM,
              width: "80%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: Theme.colors.error,
              height: Sizes.heightS,
              marginTop: 10,
              flexDirection: "row",
              padding: Sizes.paddingS,
            }}
          >
            <Text style={{ fontSize: Sizes.body3, color: Theme.colors.title }}>
              {dict.screens.Inspections.takePictureAgain()}
            </Text>
          </TouchableOpacity>
        </View>
      </Modal>

      {/* <AwesomeAlert
                show={visibleError}
                showProgress={false}
                title={dict.screens.Inspections.error()}
                message={dict.screens.Main.modelStoppingMessage()}
                closeOnTouchOutside={true}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText={dict.screens.Inspections.close()}
                confirmButtonColor={Theme.colors.primary}
                onConfirmPressed={() => {
                    toggleDialogError()
                }}
            /> */}
    </>
  );
};

export default GoCamera;
