import React from "react";
import { View, Animated } from "react-native";


interface ContentProps {
    children: any
}

const AccordionContent = ({
    children
}: ContentProps) => {

    return (
        <Animated.View style={{ overflow: 'hidden' }}>
            <View>
                {children}
            </View>
        </Animated.View>
    );
}

export default AccordionContent;
