import React, { useState, useEffect, useRef } from "react";
import {
  SafeAreaView,
  View,
  AppState,
  StyleSheet,
  FlatList,
  Image,
} from "react-native";

// Librarys
import "react-native-get-random-values";

// Components
import Text from "../../shared/atoms/Text";

// Internalization
import dict from "../i18n";

// Amplify
import { Auth, DataStore, SortDirection, Storage } from "aws-amplify";
import configAws from "../aws-exports.js";

// Assets
import Container from "../../shared/svg/container_draw";
import PersonSvg from "../../shared/svg/photo_take_person";

// Theme
import { Theme } from "../styles";
import {
  InspectionState,
  RootStackScreenProps,
  SealCategory,
  SealLocation,
  ValidationState,
  ownerValueToLabel,
} from "../types";
import {
  DrawerActions,
  useFocusEffect,
  useNavigation,
} from "@react-navigation/native";
import { Sizes } from "../../shared/constants/sizes";
import {
  Appbar,
  FAB,
  IconButton,
  List,
  Menu,
  Portal,
} from "react-native-paper";
import { checkUpdates } from "../services/updates";
import { Platform } from "react-native";
import { useAppDispatch } from "../store/hooks";
import {
  createInspectionThunk,
  updateInspection,
} from "../store/inspectionSlice";
import { Inspection } from "../models";
import { MORE_ICON, SEAL_OWNERS } from "../helpers/constantsValues";
import TwoButtonsAlertDialog from "../../shared/molecules/TwoButtonsAlertDialog";
import { isSealValid } from "../helpers/seal";
import Cargo from "../../shared/svg/cargo";

Storage.configure({
  region: configAws.aws_user_files_s3_bucket_region,
  bucket: configAws.aws_user_files_s3_bucket,
  identityPoolId: configAws.aws_user_pools_id,
  level: "public",
});

const Main = ({ navigation }: RootStackScreenProps<"Main">) => {
  const dispatch = useAppDispatch();
  const appState = useRef(AppState.currentState);

  const [menuDialVisible, setMenuDialVisible] = useState(true);
  const [inspections, setInspections] = useState<InspectionState[]>([]);

  const doWithCameraPermission = async (callback: () => void) => {
    // Permission should be asked here, but the camera won't open if the user
    // conceds it here.
    // const newPermission = await requestPermission();

    // // console.log(`granted: ${JSON.stringify(granted)}`);
    // if (granted) {
    callback();
    // }
  };

  const addNewSeal = async () =>
    doWithCameraPermission(async () => {
      setMenuDialVisible(false);
      await dispatch(createInspectionThunk());
      navigation.push("CameraSeal", {
        sealId: Date.now().toString(),
      });
    });

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        Platform.OS !== "web" && checkUpdates();
      }

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      // Show Menu Dial after returning from Camera
      setMenuDialVisible(true);
    }, [])
  );

  useEffect(() => {
    const fetchInspections = async () => {
      const userInfo = await Auth.currentUserInfo();
      const owner = userInfo?.username;

      const subscription = DataStore.observeQuery(
        Inspection,
        (inspection) =>
          inspection.and((inspection) => [inspection.owner.eq(owner)]),
        {
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      ).subscribe((snapshot) => {
        const { items: inspections, isSynced } = snapshot;

        const ownerInspections: InspectionState[] = inspections
          .filter(
            (inspection) => !!inspection.seals && inspection.seals.length > 0
          )
          .map((inspection) => ({
            id: inspection.id,
            createdAt: inspection.createdAt ?? undefined,
            seals: inspection.seals?.map((seal) => ({
              imgUrl: seal.imgUrl ?? "",
              s3Path: seal.s3Path ?? "",
              serialId: seal.serialId ?? "",
              timestamp: seal.timestamp ?? "",
              owner: seal.owner ?? SEAL_OWNERS[0],
              category: seal.category as SealCategory,
              location: seal.location as SealLocation,
              validation: seal.validation as ValidationState,
            })),
          }));

        setInspections(ownerInspections);

        return () => {
          subscription.unsubscribe();
        };
      });
    };

    fetchInspections();
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Theme.colors.title }}>
      <Appbar.Header
        mode="center-aligned"
        style={{
          height: 65,
          backgroundColor: Theme.colors.primary,
        }}
      >
        <Appbar.Action
          icon="menu"
          onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
          color={Theme.colors.title}
        />
        <Appbar.Content title={<Cargo width={100} height={100} />} />
      </Appbar.Header>

      <InspectionsList inspections={inspections} />

      {/* TODO: move FAB into shared components  */}
      <Portal>
        <FAB
          icon="plus"
          visible={menuDialVisible}
          style={{
            width: 68,
            height: 68,
            borderRadius: 34,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            margin: 16,
            right: 0,
            bottom: 0,
            backgroundColor: Theme.colors.primary_dark,
          }}
          onPress={addNewSeal}
        />
      </Portal>
    </SafeAreaView>
  );
};

const InspectionsList = ({
  inspections,
}: {
  inspections: InspectionState[];
}) => {
  return (
    <View style={{ flex: 1, backgroundColor: Theme.colors.background }}>
      <FlatList
        data={inspections}
        renderItem={({ item }) => <InspectionItem inspection={item} />}
        keyExtractor={(item) => item.id}
        ListHeaderComponent={
          inspections.length > 0 ? InspectionListHeader : null
        }
        ListHeaderComponentStyle={{
          marginBottom: 20,
        }}
        ListEmptyComponent={EmptyInspectionsList}
        contentContainerStyle={{
          margin: 20,
        }}
        ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

const InspectionListHeader = () => (
  <Text
    style={{
      alignSelf: "flex-start",
      fontSize: Sizes.h1,
      color: Theme.colors.text_dark,
      fontWeight: "700",
    }}
  >
    {dict.screens.Main.inspections()}
  </Text>
);

function getInspectionStatus(inspection: InspectionState) {
  const areSealsValid = inspection.seals?.every((seal) => isSealValid(seal));
  return areSealsValid ? "completed" : "incomplete";
}

const InspectionItem = ({ inspection }: { inspection: InspectionState }) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const [isOptionsMenuVisible, setIsOptionsMenuVisible] = useState(false);
  const [isDeleteInspectionDialogVisible, setIsDeleteInspectionDialogVisible] =
    useState(false);

  const {
    owner: firstOwner,
    serialId: firstSerialId,
    imgUrl,
  } = inspection.seals![0];
  const titleText = `${ownerValueToLabel(firstOwner)} ${
    firstSerialId && "-"
  } ${firstSerialId}`;
  const datetime = new Date(inspection.createdAt!);
  const description = datetime.toLocaleString();
  const inspectionStatus = getInspectionStatus(inspection);

  const onDeleteInspection = async () => {
    setIsDeleteInspectionDialogVisible(false);
    const toDelete = await DataStore.query(Inspection, inspection.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  };

  return (
    <>
      <List.Item
        title={titleText}
        titleStyle={{ color: Theme.colors.text_dark, fontSize: Sizes.h3 }}
        description={() =>
          InspectionItemDescription(description, inspectionStatus)
        }
        descriptionStyle={{ color: Theme.colors.secondary }}
        left={() => (
          <Image
            source={{ uri: imgUrl }}
            style={{ width: 64, height: 64, borderRadius: 32 }}
          />
        )}
        onPress={async () => {
          await dispatch(updateInspection(inspection));
          navigation.navigate("CurrentInspection", { type: "seal" });
        }}
        right={() => (
          <Menu
            onDismiss={() => setIsOptionsMenuVisible(false)}
            visible={isOptionsMenuVisible}
            anchorPosition="bottom"
            anchor={
              <IconButton
                icon={MORE_ICON}
                onPress={() => setIsOptionsMenuVisible(true)}
                style={{ flex: 1, justifyContent: "center" }}
              />
            }
            style={{
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Menu.Item
              leadingIcon="delete"
              title={dict.screens.Inspections.delete()}
              onPress={() => {
                setIsOptionsMenuVisible(false);
                setIsDeleteInspectionDialogVisible(true);
              }}
            />
          </Menu>
        )}
        style={{
          flex: 1,
          flexGrow: 1,
          width: "100%",
          backgroundColor: Theme.colors.backgroundLight,
          paddingRight: 0,
          alignItems: "center",
        }}
      />
      <TwoButtonsAlertDialog
        isVisible={isDeleteInspectionDialogVisible}
        title={dict.screens.Inspections.deleteInspectionTitle()}
        message={dict.screens.Inspections.deleteInspectionText()}
        primaryButtonText={dict.screens.Alerts.Confirm()}
        secondaryButtonText={dict.screens.Alerts.Cancel()}
        onDismiss={() => setIsDeleteInspectionDialogVisible(false)}
        onPrimaryButtonPress={onDeleteInspection}
        onSecondaryButtonPress={() => setIsDeleteInspectionDialogVisible(false)}
      />
    </>
  );
};

const InspectionItemDescription = (
  datetime: string,
  status: "ongoing" | "incomplete" | "completed"
) => {
  const backgroundColor =
    status === "completed" ? Theme.colors.success : Theme.colors.error_light;
  const statusText = dict.screens.Inspections[status]();

  return (
    <View style={{ flex: 1 }}>
      <Text style={{ color: Theme.colors.secondary, fontSize: Sizes.body4 }}>
        {datetime}
      </Text>
      <View
        style={{
          ...styles.inspectionBadge,
          backgroundColor,
        }}
      >
        <Text style={{ color: Theme.colors.title, fontSize: Sizes.body6 }}>
          {statusText}
        </Text>
      </View>
    </View>
  );
};

const EmptyInspectionsList = () => {
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        padding: Sizes.paddingL,
      }}
    >
      <Text
        style={{
          fontSize: Sizes.h2,
          marginTop: 20,
          color: Theme.colors.text_dark,
          fontWeight: "700",
        }}
      >
        {dict.screens.Main.welcome()}
      </Text>
      <View
        style={{
          margin: 10,
          width: 250,
          marginLeft: -25,
          height: 300,
          flexDirection: "row",
        }}
      >
        <View style={{ position: "absolute", zIndex: 0, elevation: 0 }}>
          <Container width={300} height={300} />
        </View>
        <View
          style={{
            position: "absolute",
            marginLeft: 100,
            marginTop: 85,
            zIndex: 1,
            elevation: 1,
          }}
        >
          <PersonSvg width={200} height={200} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  inspectionBadge: {
    borderRadius: Sizes.borderRadiusS,
    padding: 2,
    alignSelf: "flex-start",
  },
  card: {
    marginVertical: 10,
    marginHorizontal: 10,
    backgroundColor: Theme.colors.backgroundLight,
  },
  cover: {
    height: 100,
    resizeMode: "contain",
  },
  badgeContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  hasSeals: {
    backgroundColor: "green",
  },
  noSeals: {
    backgroundColor: "red",
  },
});

export default Main;
