import React, { useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Modal, Portal } from "react-native-paper";
import { Theme } from "../../styles";
import { RootStackScreenProps } from "../../types";
import { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import i18nDictionary from "../../i18n";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import SendUserData from "./SendUserData";
import Input from "../../../shared/molecules/Input";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import Text from "../../../shared/atoms/Text";
import { Sizes } from "../../../shared/constants/sizes";

Auth.configure(awsconfig);

const SignUp = ({ navigation }: RootStackScreenProps<"SignUp">) => {
  const [confirmCodeSent, setConfirmCodeSent] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const cognitoPool = {
    UserPoolId: awsconfig.aws_user_pools_id,
    ClientId: awsconfig.aws_user_pools_web_client_id,
  };
  const up = new CognitoUserPool(cognitoPool);

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: up,
  });

  const resetFields = () => {
    setEmail("");
    setCode("");
    setNewPassword("");
    setNewPasswordConfirm("");
  };

  const sendSignUp = async () => {
    setLoadingButton(true);
    if (
      email === "" ||
      name === "" ||
      newPassword === "" ||
      newPasswordConfirm === ""
    ) {
      const emptyErrors: string[] = [];
      if (email === "") {
        emptyErrors.push("email");
      }
      if (name === "") {
        emptyErrors.push("name");
      }
      if (newPassword === "") {
        emptyErrors.push("password");
      }
      if (newPasswordConfirm === "") {
        emptyErrors.push("passwordConfirm");
      }
      setErrors([...errors, ...emptyErrors]);
      setLoadingButton(false);
    } else if (newPassword !== newPasswordConfirm) {
      setErrors([...errors, "passwordNonEqual"]);
      setLoadingButton(false);
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrors([...errors, "emailInvalid"]);
      setLoadingButton(false);
    } else {
      try {
        await Auth.signUp({
          username: email,
          password: newPassword,
          attributes: {
            email,
            name,
          },
          autoSignIn: {
            enabled: true,
          },
        });
        showModal();
        setLoadingButton(false);
      } catch (error: any) {
        if (error.name === "UsernameExistsException") {
          setErrors([...errors, "emailAlreadyExists"]);
        }
        Toast.show({
          type: "error",
          text1: "Error",
          text2:
            // @ts-ignore
            i18nDictionary.cognito[error.name] !== undefined
              ? // @ts-ignore
                i18nDictionary.cognito[error.name]()
              : i18nDictionary.cognito.ErrorGeneralMessage(),
        });
        setLoadingButton(false);
      }
    }
  };

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(email);
    } catch (error) {
      Toast.show({
        type: "error",
        text1: "Error",
        text2:
          // @ts-ignore
          i18nDictionary.cognito[error.name] !== undefined
            ? // @ts-ignore
              i18nDictionary.cognito[error.name]()
            : i18nDictionary.cognito.ErrorGeneralMessage(),
      });
    }
  }

  const confirmSignUp = async () => {
    setConfirmCodeSent(true);
    try {
      await Auth.confirmSignUp(email, code);
      navigation.navigate("Home");
      hideModal();
      setConfirmCodeSent(false);
    } catch (error: any) {
      Toast.show({
        type: "error",
        text1: "Error",
        text2:
          // @ts-ignore
          i18nDictionary.cognito[error.name] !== undefined
            ? // @ts-ignore
              i18nDictionary.cognito[error.name]()
            : i18nDictionary.cognito.ErrorGeneralMessage(),
      });
      setConfirmCodeSent(false);
    }
  };

  const cancelFlow = () => {
    resetFields();
    navigation.pop();
  };

  return (
    <View style={styles.safeArea}>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={styles.modal}
        >
          <Text
            style={{
              fontSize: Sizes.h3,
              fontWeight: "700",
              color: Theme.colors.primary_dark,
            }}
          >
            {i18nDictionary.cognito.ConfirmationCode()}
          </Text>
          <Input
            onChange={setCode}
            value={code}
            error={false}
            label={i18nDictionary.cognito.Code()}
          />

          <TouchableOpacity onPress={() => resendConfirmationCode()}>
            <Text
              style={{
                fontSize: Sizes.body4,
                color: Theme.colors.text_dark,
                textDecorationLine: "underline",
              }}
            >
              {i18nDictionary.cognito.SendCode()}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => confirmSignUp()}
            style={styles.sendCodeButton}
          >
            {confirmCodeSent ? (
              <ActivityIndicator color={Theme.colors.title} size={25} />
            ) : (
              <Text
                style={{
                  fontSize: Sizes.body3,
                  fontWeight: "700",
                  color: Theme.colors.title,
                }}
              >
                {i18nDictionary.cognito.Submit()}
              </Text>
            )}
          </TouchableOpacity>
        </Modal>
      </Portal>

      <SendUserData
        name={name}
        setName={(value) => {
          setErrors([]);
          setName(value);
        }}
        code={code}
        setCode={(value) => {
          setErrors([]);
          setCode(value);
        }}
        email={email}
        setEmail={(value) => {
          setErrors([]);
          setEmail(value);
        }}
        sendSignUp={sendSignUp}
        newPassword={newPassword}
        setNewPassword={(value) => {
          setErrors([]);
          setNewPassword(value);
        }}
        newPasswordConfirm={newPasswordConfirm}
        setNewPasswordConfirm={(value) => {
          setErrors([]);
          setNewPasswordConfirm(value);
        }}
        cancelFlow={cancelFlow}
        loadingButton={loadingButton}
        errors={errors}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    height: "100%",
    alignItems: "center",
    backgroundColor: Theme.colors.primary,
    flex: 1,
  },
  modal: {
    backgroundColor: "white",
    padding: 20,
    marginHorizontal: 15,
    borderRadius: 5,
  },
  sendCodeButton: {
    borderRadius: Sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.primary_light,
    height: Sizes.heightS,
    padding: Sizes.paddingS,
    margin: 10,
  },
});

export default SignUp;
