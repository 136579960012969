import React from 'react';
import { View } from 'react-native';
import LottieView from "lottie-react-native";
import Text from '../../shared/atoms/Text';
import i18nDictionary from '../i18n';
import { Sizes } from '../../shared/constants/sizes';
import { Theme } from '../styles';

const Loading = ({text}: {text: string}) => {
    return (
        <View style={{ alignItems: 'center', justifyContent: 'center', padding: Sizes.paddingL }}>
            <LottieView
                source={require("../assets/lottie/container-ship.json")}
                autoPlay={true}
                style={{
                    width: 250,
                    height: 250,
                }}
                loop={true}
            />
            <Text style={{ fontSize: Sizes.h3, fontWeight: '700', color: Theme.colors.primary }}>{text}</Text>
        </View>
    );
}

export default Loading;