import React from "react";
import { StyleSheet, Keyboard, View, ScrollView } from "react-native";
import Input from "../../../shared/molecules/Input";
import Text from "../../../shared/atoms/Text";
import i18nDictionary from "../../i18n";
import { Theme } from "../../styles";
import Cargo from "../../../shared/svg/cargo";
import { Sizes } from "../../../shared/constants/sizes";
import { Button } from "react-native-paper";

interface SendUserDataProps {
  sendSignUp: () => void;
  cancelFlow: () => void;
  email: string;
  setEmail: (value: string) => void;
  code: string;
  setCode: (value: string) => void;
  name: string;
  setName: (value: string) => void;
  newPassword: string;
  setNewPassword: (value: string) => void;
  newPasswordConfirm: string;
  setNewPasswordConfirm: (value: string) => void;
  loadingButton: boolean;
  errors: string[];
}
const SendUserData = ({
  sendSignUp,
  cancelFlow,
  name,
  setName,
  email,
  setEmail,
  newPassword,
  setNewPassword,
  newPasswordConfirm,
  setNewPasswordConfirm,
  loadingButton,
  errors,
}: SendUserDataProps) => {
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <Cargo width={200} height={150} />

      <View style={{ height: "50%" }}>
        <Text
          style={{
            fontSize: Sizes.h2,
            fontWeight: "700",
            color: Theme.colors.title,
            marginBottom: 5,
          }}
        >
          {i18nDictionary.cognito.SignUpTitle()}
        </Text>

        <View style={{ width: "100%" }}>
          <Input
            onChange={setName}
            value={name}
            label={i18nDictionary.cognito.Name()}
            error={errors.includes("name")}
          />
          {errors.includes("name") && (
            <Text
              style={{ fontSize: Sizes.body5, color: Theme.colors.error_light }}
            >
              {i18nDictionary.cognito.EmptyFieldName()}
            </Text>
          )}

          <Input
            onChange={setEmail}
            keyboardType="email-address"
            value={email}
            label={i18nDictionary.cognito.Email()}
            error={
              errors.includes("email") ||
              errors.includes("emailInvalid") ||
              errors.includes("emailAlreadyExists")
            }
          />
          {errors.includes("email") && (
            <Text
              style={{ fontSize: Sizes.body5, color: Theme.colors.error_light }}
            >
              {i18nDictionary.cognito.EmptyFieldEmail()}
            </Text>
          )}
          {errors.includes("emailInvalid") && (
            <Text
              style={{ fontSize: Sizes.body5, color: Theme.colors.error_light }}
            >
              {i18nDictionary.screens.Inspections.errorMessageInvalidContentType()}
            </Text>
          )}

          <Input
            onChange={setNewPassword}
            value={newPassword}
            label={i18nDictionary.cognito.Password()}
            error={errors.includes("password")}
            secureTextEntry={true}
          />
          {errors.includes("password") && (
            <Text
              style={{ fontSize: Sizes.body5, color: Theme.colors.error_light }}
            >
              {i18nDictionary.cognito.EmptyFieldPassword()}
            </Text>
          )}

          <Input
            onChange={setNewPasswordConfirm}
            value={newPasswordConfirm}
            label={i18nDictionary.cognito.ConfirmPassword()}
            error={
              errors.includes("passwordConfirm") ||
              errors.includes("passwordNonEqual")
            }
            secureTextEntry={true}
            onSubmitEditing={() => {
              Keyboard.dismiss();
              sendSignUp();
            }}
          />
          {(errors.includes("passwordConfirm") ||
            errors.includes("passwordNonEqual")) && (
            <Text
              style={{ fontSize: Sizes.body5, color: Theme.colors.error_light }}
            >
              {i18nDictionary.cognito.EmptyFieldPasswordConfirm()}
            </Text>
          )}
        </View>
      </View>

      <View
        style={{
          justifyContent: "flex-end",
          width: "100%",
          marginVertical: 20,
        }}
      >
        <Button
          onPress={sendSignUp}
          mode="contained"
          loading={loadingButton}
          style={styles.loginButton}
          labelStyle={{
            fontSize: Sizes.body3,
            fontWeight: "700",
            color: Theme.colors.primary,
          }}
        >
          {i18nDictionary.cognito.Submit()}
        </Button>
        <Button
          onPress={cancelFlow}
          mode="text"
          loading={loadingButton}
          style={styles.cancelButton}
          labelStyle={{
            fontSize: Sizes.body3,
            fontWeight: "700",
            color: Theme.colors.title,
          }}
        >
          {i18nDictionary.cognito.Cancel()}
        </Button>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: Sizes.paddingM,
  },
  modal: { backgroundColor: "white", padding: 20 },
  input: {
    borderColor: "gray",
    backgroundColor: "#FFF",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  loginButton: {
    width: "100%",
    borderRadius: Sizes.borderRadiusM,
    justifyContent: "center",
    backgroundColor: Theme.colors.title,
    height: Sizes.heightS,
    marginBottom: 10,
  },
  cancelButton: {
    width: "100%",
    height: Sizes.heightS,
    borderRadius: Sizes.borderRadiusM,
    backgroundColor: Theme.colors.secondary_light,
    justifyContent: "center",
  },
  sendCodeButton: {
    borderRadius: Sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.primary_light,
    height: Sizes.heightS,
    padding: Sizes.paddingS,
    margin: 10,
  },
});

export default SendUserData;
