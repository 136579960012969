import React from "react";
import {
  TouchableOpacity,
  TextStyle,
  TouchableOpacityProps,
  ActivityIndicator,
} from "react-native";
import styled from "styled-components";
import Text from "./Text";

export type Props = {} & TouchableOpacityProps;

const StyledButton = styled(TouchableOpacity)({
  borderRadius: 12,
  height: 60,
  justifyContent: "center",
  backgroundColor: "#000",
  flexDirection: "row",
  alignItems: "center",
});

const PrimaryButton: React.FC<Props> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);

export default PrimaryButton;
