import React from 'react'
import Svg, { Path } from 'react-native-svg';
const Container = (props: {width: number, height: number, color?: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" >
      <Path fillRule="evenodd" clipRule="evenodd" d="M10.41 0.239994L15.121 3.01399C15.3882 3.16929 15.6099 3.39192 15.7642 3.65969C15.9184 3.92745 15.9997 4.23098 16 4.53999V9.54999C16 9.85972 15.9187 10.164 15.7643 10.4325C15.6099 10.701 15.3877 10.9243 15.12 11.08L7.367 15.601L7.365 15.602C7.09555 15.7584 6.78955 15.8408 6.478 15.8408C6.16646 15.8408 5.86045 15.7584 5.591 15.602H5.59L0.873001 12.85C0.607062 12.6946 0.386509 12.4722 0.233331 12.205C0.0801524 11.9377 -0.000298028 11.635 8.29616e-07 11.327V6.29199C8.29616e-07 5.98799 0.0780008 5.69399 0.220001 5.43699L0.224001 5.43199L0.234001 5.41299C0.384001 5.15099 0.603001 4.92699 0.874001 4.76999L8.641 0.238994C8.90881 0.0825991 9.21337 0.000183105 9.5235 0.000183105C9.83363 0.000183105 10.1382 0.0825991 10.406 0.238994L10.408 0.239994H10.41ZM9.397 1.53399C9.43524 1.51168 9.47872 1.49992 9.523 1.49992C9.56728 1.49992 9.61076 1.51168 9.649 1.53399L13.764 3.95599L6.612 8.10399C6.57117 8.1278 6.52476 8.14034 6.4775 8.14034C6.43024 8.14034 6.38383 8.1278 6.343 8.10399L2.227 5.71599L9.397 1.53399V1.53399ZM7.365 9.40199L8.73 8.60999V13.07L7.23 13.945V9.47299C7.27634 9.4513 7.32172 9.42761 7.366 9.40199H7.365ZM10.229 12.196V7.74099L11.75 6.85899V11.309L10.229 12.196V12.196ZM13.25 10.434L14.365 9.78399H14.367C14.4075 9.76035 14.4412 9.72648 14.4645 9.68577C14.4878 9.64505 14.5001 9.59892 14.5 9.55199V5.26399L13.25 5.98899V10.434V10.434ZM1.629 11.554L5.729 13.947V9.47399C5.68197 9.45203 5.63592 9.42801 5.591 9.40199L1.5 7.02899V11.327C1.5 11.422 1.55 11.508 1.629 11.554V11.554Z" fill={props.color} />
    </Svg>
  )
}

export default Container; 