import React from "react";
import { View, Image, StyleSheet } from "react-native";
import { Sizes } from "../../shared/constants/sizes";

// Components

// Constants
import i18nDictionary from "../i18n";
import { Theme } from "../styles";
import TwoButtonsAlertDialog from "../../shared/molecules/TwoButtonsAlertDialog";
import { FAB } from "react-native-paper";

interface ImagePickerProps {
  onPress: () => void;
  imageUrl: string;
  type: "SEAL" | "CONTAINER";
}

const ImagePicker = ({ onPress, imageUrl, type }: ImagePickerProps) => {
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] =
    React.useState(false);

  const onConfirmDelete = () => {
    setIsDeleteDialogVisible(false);
    onPress();
  };

  return (
    <View style={styles.photo}>
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Image
          style={{
            width: 250,
            height: type === "CONTAINER" ? 200 : 150,
            backgroundColor: "white",
          }}
          resizeMode={type === "CONTAINER" ? "cover" : "contain"}
          source={{ uri: imageUrl }}
        />
        {type === "CONTAINER" ? null : (
          <FAB
            icon="delete"
            style={{
              width: 48,
              height: 48,
              borderRadius: 24,
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: 0,
              bottom: 0,
              backgroundColor: Theme.colors.error_light,
            }}
            onPress={() => setIsDeleteDialogVisible(true)}
          />
        )}
      </View>

      <TwoButtonsAlertDialog
        isVisible={isDeleteDialogVisible}
        title={i18nDictionary.screens.Alerts.deleteSealTitle()}
        message={i18nDictionary.screens.Alerts.deleteSealText()}
        primaryButtonText={i18nDictionary.screens.Alerts.Confirm()}
        secondaryButtonText={i18nDictionary.screens.Alerts.Cancel()}
        onDismiss={() => setIsDeleteDialogVisible(false)}
        onPrimaryButtonPress={onConfirmDelete}
        onSecondaryButtonPress={() => setIsDeleteDialogVisible(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  photo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  editPhoto: {
    padding: Sizes.paddingS,
    bottom: -10,
    right: -10,
    borderRadius: Sizes.borderRadiusXL,
    zIndex: 1,
    elevation: 1,
    position: "absolute",
    justifyContent: "flex-end",
  },
  photoSize: {
    flex: 1,
    resizeMode: "contain",
    borderRadius: Sizes.borderRadiusM,
    borderWidth: 1,
    borderColor: Theme.colors.text,
  },
});

export default ImagePicker;
